import React, { useEffect, useRef, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import ReactGA from 'react-ga4';
import jwtDecode from 'jwt-decode';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { Badge } from 'primereact/badge';
import classNames from 'classnames';
import { getTaskOptions } from '../../reduxStore/taskOptions/actions';
import { getProfileInfo } from '../../reduxStore/profileInfo/actions';
import { setSubscription } from '../../reduxStore/subscription/actions';
import { urls, useRequest } from '../Common/ApiServices';
import { setPermissions } from '../../reduxStore/permissions/actions';
import { setIsFirstLogin } from '../../reduxStore/isFirstLogin/actions';
import { setIsTrialTracker } from '../../reduxStore/isTrialTracker/actions';
import { setPopUp } from '../../reduxStore/popUp/actions';
import { setIsActiveSubscription } from '../../reduxStore/isActiveSubscription/actions';
import avatarPlaceholder from '../../assets/img/avatar_placeholder.png';
import NavigationItem from './NavigationItem/NavigationItem';
import NavDialog from './NavDialog/NavDialog';
import ErrorDialog from './ErrorDialog/ErrorDialog';
import Toaster from './Toaster/Toaster';
import { customLogout, isAuth } from '../../auth/auth-service';
import {
  errorCodes,
  links,
  popUp,
  subscriptionStatus,
  userRoles,
  expiredLinkMessages,
  freshdesk,
} from '../Common/globalConstants';
import styles from './Navigation.module.scss';

const Navigation = ({ location }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [tokenIsAvailable, setTokenIsAvailable] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const permissions = useSelector((state) => state.permissions.permissions);
  const subscription = useSelector((state) => state.subscription);
  const profileData = useSelector((state) => state?.profileInfo?.employee);
  const companyData = useSelector((state) => state?.profileInfo?.company);
  const locationsCount = useSelector(
    (state) => state?.profileInfo?.company?.locationsCount
  );
  const isActiveSubscription = useSelector(
    (state) => state.isActiveSubscription.isActive
  );
  const limitExceeded = useSelector((state) => state.limitExceeded);

  const { loginWithRedirect, getAccessTokenSilently, logout } = useAuth0();

  const menu = useRef(null);
  const supportMenu = useRef(null);
  const burgerMenu = useRef(null);

  const history = useHistory();

  const dispatch = useDispatch();

  const { error, sendRequest } = useRequest({ checkTokenExp: false });

  const toCompanyInfoPage = async () => {
    history.push(links.COMPANY_INFO);
  };

  const burgerMenuItems = [
    {
      label: 'Dashboard',
      command: () => {
        history.push(links.DASHBOARD);
      },
    },
    {
      label: 'Locations',
      command: () => {
        history.push(links.LOCATIONS);
      },
    },
    {
      label: 'Plants',
      command: () => {
        history.push(links.PLANTS);
      },
    },
    {
      label: 'Products',
      command: () => {
        history.push(links.PRODUCTS);
      },
    },
    {
      label: 'Trials',
      command: () => {
        history.push(links.TRIALS);
      },
    },
    {
      label: 'Tasks',
      command: () => {
        history.push(links.TASKS);
      },
    },
  ];

  const initialItems = !isActiveSubscription
    ? [
        {
          label: 'Logout',
          icon: 'pi pi-power-off',
          command: () => {
            customLogout(logout);
          },
        },
      ]
    : [
        {
          label: 'Change Password',
          icon: 'pi pi-unlock',
          command: () => {
            changePassword();
          },
        },
        {
          label: 'Logout',
          icon: 'pi pi-power-off',
          command: () => {
            customLogout(logout);
          },
        },
      ];

  const manageTeamItem = {
    label: 'Manage Team',
    icon: 'pi pi-users',
    command: () => {
      history.push(links.EMPLOYEES);
    },
  };

  const billingItem = {
    label: 'Billing & Subscriptions',
    icon: 'pi pi-credit-card',
    command: async () => {
      await toCompanyInfoPage();
    },
  };

  const supportMenuItems = [
    {
      label: 'Contact Support',
      command: () => {
        FreshworksWidget('open', 'ticketForm');
      },
    },
    {
      label: 'Help Center',
      icon: 'pi pi-external-link',
      url: 'https://plantpartnersolutions.freshdesk.com/support/home',
      target: '_blank',
    },
  ];

  const getUserPermissions = async () => {
    if (isAuth()) {
      const token = await getAccessTokenSilently();
      localStorage.setItem('token', token);
      return token ? jwtDecode(token).permissions : [];
    }
    return [];
  };

  const checkFirstLogin = (firstLogin, locationsCount) => {
    dispatch(setIsFirstLogin(firstLogin));
    if (
      firstLogin &&
      locationsCount === 0 &&
      permissions.includes(userRoles.COMPANY_MANAGE)
    ) {
      history.push(links.LOCATIONS);
    }
  };

  const changePassword = async () => {
    const requestData = {
      url: urls.GENERATE_RESET_PASS_LINK,
      method: 'POST',
      data: { employeeId: profileData?.id },
    };

    const response = await sendRequest(requestData);
    if (response) {
      customLogout(logout);
      window.location.assign(response.data.url);
    }

    return response;
  };

  let menuItems = initialItems;
  if (permissions?.includes(userRoles.COMPANY_MANAGE) && isActiveSubscription) {
    menuItems = [manageTeamItem, billingItem, ...initialItems];
  } else if (
    permissions?.includes(userRoles.PPT_MANAGE_COMPANY) &&
    isActiveSubscription
  ) {
    menuItems = [manageTeamItem, ...initialItems];
  } else if (
    permissions?.includes(userRoles.COMPANY_MANAGE) &&
    !isActiveSubscription
  ) {
    menuItems = [billingItem, ...initialItems];
  }

  let centerNavItems;
  let adminNavItems = isAuth() && isAdmin && (
    <>
      <NavigationItem link={links.LOCATIONS} clickable={isActiveSubscription}>
        Locations
      </NavigationItem>
    </>
  );
  let rightNavItems = isAuth() ? (
    <div className={styles.rightSideItems}>
      <Menu
        model={supportMenuItems}
        popup
        ref={supportMenu}
        id="support_menu"
      />
      <div className={styles.mobileOnly}>
        <button
          type="button"
          className={classNames('p-link', styles.question)}
          onClick={(event) => supportMenu.current.toggle(event)}
        >
          <i className={classNames('pi pi-question-circle')} />
        </button>
      </div>
      <NavigationItem link="#" clickable={false} className={styles.mobileOnly}>
        <i className={classNames('pi pi-bell p-overlay-badge', styles.bell)}>
          <Badge value="8" severity="info" />
        </i>
      </NavigationItem>
      <NavigationItem
        className={styles.mobileOnly}
        link={!isAdmin ? links.LABELS : links.REPLICATION}
        clickable={isActiveSubscription}
      >
        <i className={classNames('pi pi-cog', styles.cog)} />
      </NavigationItem>
      <Menu model={menuItems} popup ref={menu} id="profile_popup_menu" />
      <div className={styles.profileItem}>
        <button
          type="button"
          className="p-link"
          onClick={(event) => menu.current.toggle(event)}
        >
          <img
            src={profileData?.picture?.thumbnail || avatarPlaceholder}
            className={styles.profileImage}
          />
          <span className={styles.profileName}>
            {profileData?.firstName} {profileData?.lastName}
          </span>
        </button>
      </div>
    </div>
  ) : null;

  if (!isAuth()) {
    rightNavItems = (
      <div className={styles.rightSideItems}>
        <Button
          className={classNames(styles.demo, styles.onlyDesktop)}
          label="Request a Demo"
          onClick={() => history.push(links.CONTACT_US)}
        />
        <Button
          className={styles.onlyDesktop}
          onClick={() => history.push(links.SIGNUP)}
        >
          Start Free Trial
        </Button>
        <Button className={styles.auth} onClick={() => loginWithRedirect({})}>
          Log In
        </Button>
      </div>
    );
  } else {
    centerNavItems = (
      <>
        <div className={styles.burgerMenu}>
          <Menu
            model={burgerMenuItems}
            popup
            ref={burgerMenu}
            id="burger_menu"
          />
          <div>
            <button
              type="button"
              className="p-link"
              onClick={(event) => burgerMenu.current.toggle(event)}
            >
              <i className={classNames('pi pi-align-justify', styles.burger)} />
            </button>
          </div>
        </div>
        <div className={styles.centerItems}>
          <NavigationItem
            link={links.DASHBOARD}
            clickable={isActiveSubscription}
          >
            Dashboard
          </NavigationItem>
          {adminNavItems}
          <NavigationItem link={links.PLANTS} clickable={isActiveSubscription}>
            Plants
          </NavigationItem>
          <NavigationItem
            link={links.PRODUCTS}
            clickable={isActiveSubscription}
          >
            Products
          </NavigationItem>
          <NavigationItem link={links.TRIALS} clickable={isActiveSubscription}>
            Trials
          </NavigationItem>
          <NavigationItem link={links.TASKS} clickable={isActiveSubscription}>
            Tasks
          </NavigationItem>
          <Menu
            model={supportMenuItems}
            popup
            ref={supportMenu}
            id="support_menu"
          />
          <div>
            <button
              type="button"
              className={classNames('p-link', styles.question)}
              onClick={(event) => supportMenu.current.toggle(event)}
            >
              <i className={classNames('pi pi-question-circle')} />
            </button>
          </div>
          <NavigationItem
            link="#"
            clickable={false}
            noPadding={styles.noPadding}
          >
            <i
              className={classNames('pi pi-bell p-overlay-badge', styles.bell)}
            >
              <Badge value="8" severity="info" />
            </i>
          </NavigationItem>
          <NavigationItem
            link={!isAdmin ? links.LABELS : links.REPLICATION}
            clickable={isActiveSubscription}
            noPadding={styles.noPadding}
          >
            <i className={classNames('pi pi-cog', styles.cog)} />
          </NavigationItem>
        </div>
      </>
    );
  }

  const TermsAndConditionsPage = [
    links.USER_AGREEMENT,
    links.PRIVACY_POLICY,
  ].includes(location.pathname);

  const userName = profileData
    ? `${profileData?.firstName} ${profileData?.lastName}`
    : '';

  const userEmail = profileData ? profileData.email : '';

  const getSubscription = async () => {
    const requestData = {
      url: urls.COMPANY_PRODUCT_SUBSCRIPTION,
      method: 'POST',
      data: {
        query: {
          product: 'PPT',
        },
      },
    };
    const response = await sendRequest(requestData);
    dispatch(setSubscription(response?.data));
    return response;
  };

  const actionData = {
    logout: logout,
    dispatch: dispatch,
    isAuthenticated: isAuth(),
    getAccessTokenSilently: getAccessTokenSilently,
  };

  const checkParams = (params) => {
    if (Object.keys(expiredLinkMessages).includes(params)) {
      dispatch(
        setPopUp({
          severity: expiredLinkMessages[params][2],
          summary: expiredLinkMessages[params][1],
          detail: expiredLinkMessages[params][0],
          life: 5000,
        })
      );
    }
  };

  useEffect(async () => {
    const permissions = await getUserPermissions(
      logout,
      getAccessTokenSilently
    );
    setIsAdmin(permissions?.includes(userRoles.PPT_MANAGE_COMPANY));
    if (isAuth()) {
      dispatch(setPermissions(permissions));
      dispatch(
        setIsTrialTracker(!permissions.includes(userRoles.PPT_MANAGE_TRIAL))
      );
    }
    if (!profileData && isAuth()) {
      setTokenIsAvailable(true);
    } else if (!isAuth()) {
      FreshworksWidget(freshdesk.destroy);
    }
  }, [isAuth()]);

  useEffect(async () => {
    if (tokenIsAvailable && isAuth()) {
      dispatch(getProfileInfo(actionData));
      getSubscription();
      FreshworksWidget(freshdesk.boot);
    }
  }, [tokenIsAvailable]);

  useEffect(() => {
    if (profileData && locationsCount) {
      checkFirstLogin(profileData?.firstLogin, locationsCount);
    }
  }, [profileData, locationsCount]);

  useEffect(() => {
    const show = !isActiveSubscription || limitExceeded;
    setShowDialog(show);
  }, [isActiveSubscription, limitExceeded]);

  useEffect(() => {
    location.pathname === links.PRICING && setShowDialog(false);
  }, [location.pathname]);

  useEffect(() => {
    if (
      subscription === subscriptionStatus.ACTIVE ||
      subscription === subscriptionStatus.TRIALING
    ) {
      dispatch(setIsActiveSubscription(true));
    }
  }, [subscription]);

  useEffect(() => {
    {
      permissions && dispatch(getTaskOptions(actionData));
    }
  }, [permissions]);

  useEffect(() => {
    if (error) {
      dispatch(
        setPopUp({
          severity: popUp.severities.ERROR,
          summary: popUp.summary.ERROR,
          detail: errorCodes.DEFAULT_MESSAGE.text,
          life: 5000,
        })
      );
    }
  }, [error]);

  useEffect(async () => {
    if (profileData == null || companyData == null) {
      return;
    }

    ReactGA.gtag('set', 'user_properties', {
      companyId: companyData.id,
      externalUserId: profileData.id,
    });
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
    });
    FreshworksWidget(freshdesk.identify, freshdesk.ticketForm, {
      name: userName,
      email: userEmail,
    });
  });

  useEffect(() => {
    location && checkParams(location.search);
  }, [location]);

  return (
    <div className={classNames('top-nav', styles.navigation)}>
      <Toaster />
      {location.pathname !== links.COMPANY_INFO && (
        <NavDialog
          userName={userName}
          visible={showDialog}
          isCompanyAdmin={permissions.includes(userRoles.COMPANY_MANAGE)}
          setShowDialog={setShowDialog}
          toCompanyInfoPage={toCompanyInfoPage}
          history={history}
        />
      )}
      <ErrorDialog userName={userName} logout={logout} />
      <ul className={styles.navigationItems}>
        <div className={styles.navbarBrandImage}>
          <NavigationItem link={links.HOME} clickable={true} />
        </div>
        {!TermsAndConditionsPage ? centerNavItems : null}
        {!TermsAndConditionsPage ? rightNavItems : null}
      </ul>
    </div>
  );
};

export default withRouter(Navigation);
