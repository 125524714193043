import React from 'react';
import styles from './DropdownFilter.module.scss';
import { Dropdown } from 'primereact/dropdown';

const DropdownFilter = (props) => {
  return (
    <div className={styles.dropdownFilter}>
      <div className={styles.labelText}>{props.label}</div>
      <Dropdown
        id="location"
        onChange={(e) => props.setSelectedItem(e.value)}
        optionLabel={props.optionLabel}
        value={props.selectedItem}
        className={`p-dropdown ${styles.dropdownInput}`}
        options={props.items ? props.items : []}
        placeholder={props.filterName}
      />
    </div>
  );
};

export default DropdownFilter;
