import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { useFormik } from 'formik';
import classNames from 'classnames';
import PromptIfDirty from '../../Common/PromptIfDirty';
import {
  initialApplicationFormSchema,
  applicationFormSchema,
} from './ApplicationFormValidation';
import {
  getProductParams,
  initialApplicationInfo,
  applicationsErrorFields,
  applicationsErrorMessages,
} from '../constants';
import { urls, useRequest } from '../../Common/ApiServices';
import styles from './ApplicationForm.module.scss';

const ApplicationForm = ({
  displayApplicationModal,
  setDisplayApplicationModal,
  setApplicationIsCreated,
  responseFailed,
}) => {
  const [productInfo, setProductInfo] = useState({});

  const { error, sendRequest } = useRequest({});

  const toast = useRef(null);

  const createApplication = async (data) => {
    const requestData = {
      url: urls.CREATE_PRODUCT_APPLICATION,
      method: 'POST',
      data: data,
    };

    const response = await sendRequest(requestData);

    setDisplayApplicationModal(false);
    setApplicationIsCreated(true);
    formik.resetForm();

    return response;
  };

  const getProductInfo = async () => {
    const productId = localStorage.getItem('productId');

    const requestData = {
      url: urls.EXTRACT_PRODUCT,
      method: 'POST',
      data: getProductParams(productId),
    };

    const response = await sendRequest(requestData);

    const res = response.data.product;
    const productInfo = {
      applicationMethod: res.applicationMethod,
      category: res.category,
      classification: res.classification,
      productCode: res.code,
      epaRegistrationNumber: res.epaRegistrationNumber,
      equipment: res.equipment,
      id: res.id,
      labelUrl: res.labelUrl,
      manufacturer: res.manufacturer,
      measureUnit: res.measureUnit,
      mixerMeasureUnit: res.mixerMeasureUnit,
      mixer: res.mixer,
      productName: res.name,
      note: res.note,
      phone: res.phone,
      sdsNumber: res.sdsNumber,
      sdsUrl: res.sdsUrl,
      tradeName: res.tradeName,
      versionInfo: res.versionInfo,
    };
    setProductInfo(productInfo);

    return response;
  };

  const accept = () => {
    onHide();
  };

  const getErrorMessage = (code, fieldName) => {
    if (code === 'NOT_UNIQUE') {
      return applicationsErrorMessages[fieldName];
    }
  };

  const setFormikError = () => {
    error.response.data.errors.map((err) => {
      return formik.setFieldError(
        applicationsErrorFields[err.fieldName],
        getErrorMessage(err.code, err.fieldName)
      );
    });
  };

  const confirm = (dirty) => {
    if (dirty) {
      confirmDialog({
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        message: 'Are you sure you want to discard the changes?',
        accept,
      });
    } else {
      accept();
    }
  };

  const onHide = () => {
    setDisplayApplicationModal(false);
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: initialApplicationFormSchema(initialApplicationInfo),
    enableReinitialize: true,
    validationSchema: applicationFormSchema,
    onSubmit: (values) => {
      const newProductParams = {
        product: {
          id: {
            is: localStorage.getItem('productId'),
          },
        },
        application: {
          code: values.code,
          quantity: values.quantity,
          mixerQuantity: values.mixerQuantity,
          note: values.note,
        },
      };
      createApplication(newProductParams);
    },
  });

  useEffect(() => {
    getProductInfo();
  }, []);

  useEffect(() => {
    if (error) {
      error.response.data.errors ? setFormikError() : responseFailed();
    }
  }, [error]);

  return (
    <>
      <Dialog
        className={styles.applicationFormDialog}
        header="Application"
        visible={displayApplicationModal}
        onHide={() => confirm(formik.dirty)}
      >
        <Toast ref={toast} />
        <PromptIfDirty dirty={formik.dirty} />
        <Divider />
        <form onSubmit={formik.handleSubmit} autoComplete={'off'}>
          <div className="p-fluid">
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="code" className="p-col-12 p-md-3 p-text-bold">
                Code*
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  id="code"
                  type="text"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  aria-describedby="code-invalid"
                  value={formik.values.code}
                  className={
                    formik.errors.code && formik.touched.code && 'p-invalid'
                  }
                />
                {formik.errors.code && formik.touched.code ? (
                  <small id="code-invalid" className="p-error p-d-block">
                    {formik.errors.code}
                  </small>
                ) : (
                  <small>Application code name is required.</small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="quantity" className="p-col-12 p-md-3 p-text-bold">
                Quantity*
              </label>
              <div className="p-col-6 p-md-4">
                <InputText
                  className={
                    formik.errors.quantity &&
                    formik.touched.quantity &&
                    'p-invalid'
                  }
                  id="quantity"
                  type="number"
                  min="1"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  aria-describedby="quantity-invalid"
                  value={formik.values.quantity}
                />
                {formik.errors.quantity && formik.touched.quantity ? (
                  <small id="tradeName-invalid" className="p-error p-d-block">
                    {formik.errors.quantity}
                  </small>
                ) : (
                  <small>Quantity is required.</small>
                )}
              </div>
              <div className={classNames('p-col-3 p-md-2', styles.describer)}>
                {productInfo?.measureUnit?.description}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="mixerQuantity"
                className="p-col-12 p-md-3 p-text-bold"
              >
                Mixer Quantity*
              </label>
              <div className="p-col-6 p-md-4">
                <InputText
                  className={
                    formik.errors.mixerQuantity &&
                    formik.touched.mixerQuantity &&
                    'p-invalid'
                  }
                  id="mixerQuantity"
                  type="number"
                  min="1"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  aria-describedby="mixerQuantity-invalid"
                  value={formik.values.mixerQuantity}
                />
                {formik.errors.mixerQuantity && formik.touched.mixerQuantity ? (
                  <small
                    id="mixerQuantity-invalid"
                    className="p-error p-d-block"
                  >
                    {formik.errors.mixerQuantity}
                  </small>
                ) : (
                  <small>Mixer quantity is required.</small>
                )}
              </div>
              <div className={classNames('p-col-3 p-md-2', styles.describer)}>
                {productInfo?.mixerMeasureUnit?.description}
              </div>
              <div className={classNames('p-col-3 p-md-2', styles.describer)}>
                {productInfo?.mixer?.name}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="note" className="p-col-12 p-md-3">
                Note
              </label>
              <div className="p-col-12 p-md-9">
                <InputTextarea
                  className={formik.errors.note ? 'p-invalid' : null}
                  id="note"
                  type="text"
                  rows="4"
                  onChange={formik.handleChange}
                  aria-describedby="note-invalid"
                  value={formik.values.note}
                />
                {formik.errors.note ? (
                  <small id="note-invalid" className="p-error p-d-block">
                    {formik.errors.note}
                  </small>
                ) : null}
              </div>
            </div>
          </div>
          <div className="p-grid p-col-12 p-justify-end pad-r-0 margin-l-0">
            <Button
              className={styles.button}
              label="Save"
              type="submit"
              icon="pi pi-check"
              disabled={!formik.dirty || !formik.isValid}
              autoFocus
            />
            <Button
              className={`p-button-secondary ${styles.button}`}
              label="Cancel"
              type="button"
              icon="pi pi-times"
              onClick={() => confirm(formik.dirty)}
            />
          </div>
        </form>
      </Dialog>
    </>
  );
};

export default ApplicationForm;
