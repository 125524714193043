const exportCropsParams = (trialId, crops) => {
  return {
    trial: {
      id: {
        is: trialId,
      },
    },
    crop: {
      id: {
        in: crops,
      },
    },
  };
};

const initialTrialParams = {
  query: {},
  navigation: {
    sort: [
      {
        key: 'name',
        order: 'ASC',
      },
    ],
    page: {
      from: 0,
      size: 20,
    },
  },
};

const initialTrialInfo = {
  name: '',
  location: '',
  trialManager: '',
  startDate: null,
  closeDate: null,
  description: '',
  contract: false,
  logFrequency: {},
  cropTemplate: {},
  note: '',
};

const trialStatuses = {
  IN_PROGRESS: 'green',
  PENDING: 'cornflowerblue',
  COMPLETED: 'red',
  INACTIVE: 'grey',
};

const errorCodes = {
  REFERENCES_EXIST: {
    code: 'REFERENCES_EXIST',
    text: 'Location field cannot be edited because log records exist.',
  },
  CONFLICT: {
    code: 'CONFLICT',
    text: 'This trial has been updated by another user. Please refresh the page and then save your updates.',
  },
  NOT_PROVIDED: {
    code: 'NOT_PROVIDED',
    text: 'This field should be provided.',
  },
  NOT_UNIQUE: {
    code: 'NOT_UNIQUE',
    text: 'Trial Name should be unique.',
  },
  INVALID_RANGE: {
    code: 'INVALID_RANGE',
    text: 'Close Date should be after Start Date.',
  },
  MAX_LENGTH_EXCEEDED: {
    code: 'MAX_LENGTH_EXCEEDED',
    text: 'Maximum length exceeded.',
  },
  NO_TEMPLATE: {
    code: 'NO_TEMPLATE',
    text: 'No template',
  },
  IMPORT_EXCEL: {
    code: 'IMPORT_EXCEL',
    text: 'Can not import excel',
  },
  IMPORT_EXCEL_CANNOT_FIND_VALUE: {
    code: 'IMPORT_EXCEL_CANNOT_FIND_VALUE',
    text: 'Can not find the value.',
  },
  IMPORT_EXCEL_INVALID_FIELD_TYPE: {
    code: 'IMPORT_EXCEL_INVALID_FIELD_TYPE',
    text: 'Invalid field type.',
  },
  IMPORT_EXCEL_MANDATORY_HEADER_IS_MISSING: {
    code: 'IMPORT_EXCEL_MANDATORY_HEADER_IS_MISSING',
    text: 'Mandatory header is missing.',
  },
  IMPORT_EXCEL_MANDATORY_VALUE_IS_MISSING: {
    code: 'IMPORT_EXCEL_MANDATORY_VALUE_IS_MISSING',
    text: 'Mandatory value is missing.',
  },
  PLANT_IMPORT_EXCEL_ENTITY_ALREADY_EXIST: {
    code: 'PLANT_IMPORT_EXCEL_ENTITY_ALREADY_EXIST',
    text: 'Plant already exists.',
  },
  IMPORT_EXCEL_UNIQUE_FIELD_DUPLICATE: {
    code: 'IMPORT_EXCEL_UNIQUE_FIELD_DUPLICATE',
    text: 'Value in unique field is duplicated in other row(s).',
  },
  DEFAULT_MESSAGE: {
    code: 'DEFAULT_MESSAGE',
    text: 'Something went wrong.',
  },
};

const loadingStatus = {
  IN_PROGRESS: 'IN_PROGRESS',
  GENERATED: 'GENERATED',
  READY: 'READY',
  FAILED: 'FAILED',
};

const whileTimers = {
  maxAttempt: 10,
  loopDelay: 3000,
};

export {
  exportCropsParams,
  initialTrialParams,
  trialStatuses,
  initialTrialInfo,
  loadingStatus,
  whileTimers,
  errorCodes,
};
