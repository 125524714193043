import React, { useState, useRef, useEffect, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useAuth0 } from '@auth0/auth0-react';
import { useFormik } from 'formik';
import { initialCropFormSchema, cropFormSchema } from './cropFormValidation';
import DatePicker, { registerLocale } from 'datepicker-special-week-numbers';
import enGB from 'date-fns/locale/en-GB';
import enUS from 'date-fns/locale/en-US';
import {
  getAuthConfig,
  checkBlocked,
  isAuth,
} from '../../../../auth/auth-service';
import { getCropOptions } from '../../../../reduxStore/crop/actions';
import { checkSubscription, getWeekNumber } from '../../../Common/utils';
import BreadCrumb from '../../../BreadCrumb/BreadCrumb';
import PromptIfDirty from '../../../Common/PromptIfDirty';
import ReferenceTable from '../../../Common/ReferenceTable/ReferenceTable';
import urls from '../../../Common/ApiServices/urls';
import { locales } from '../../../Common/globalConstants';
import styles from './CropForm.module.scss';

registerLocale('en-GB', enGB);
registerLocale('en-US', enUS);

const CropForm = () => {
  const cropId = localStorage.getItem('cropId');
  const trialId = localStorage.getItem('trialId');
  const cropParams = {
    query: {
      crop: {
        id: {
          is: cropId,
        },
      },
    },
  };

  const initialCropInfo = {
    number: '',
    plant: '',
    plantDate: null,
    section: '',
    quantity: '',
    startDate: null,
    startForm: '',
    potSize: '',
    transplantDate: null,
    spaceDate1: null,
    spaceDate2: null,
    lotNumber: '',
    plantsPerPot: '',
    note: '',
  };

  const [cropInfo, setCropInfo] = useState(initialCropInfo);
  const [isUpdated, setIsUpdated] = useState(false);
  const [displayRefTable, setDisplayRefTable] = useState(false);
  const [currentRefTableName, setCurrentRefTableName] = useState('');

  const toast = useRef(null);
  const isMountedRef = useRef(null);

  const { logout, getAccessTokenSilently } = useAuth0();

  const history = useHistory();

  const dispatch = useDispatch();

  const potSizes = useSelector((state) => state.crop.potSizes);
  const startForms = useSelector((state) => state.crop.startForms);
  const cropPlants = useSelector((state) => state.crop.plants);
  const cropSections = useSelector((state) => state.crop.sections);
  const isTrialTracker = useSelector((state) => state.isTrialTracker);
  const error = useSelector((state) => state.crop.error);
  const firstDayOfWeek = useSelector(
    (state) => state.crop?.location?.firstDayOfWeek?.day
  );
  const dateFormatting = useSelector(
    (state) => state.crop?.location?.dateFormat?.datePattern
  );

  const showElements = isTrialTracker ? 'none' : 'inline-flex';

  const actionData = {
    id: trialId,
    logout: logout,
    dispatch: dispatch,
    isAuthenticated: isAuth(),
    getAccessTokenSilently: getAccessTokenSilently,
  };

  const getCropInfo = async () => {
    try {
      const config = await getAuthConfig(true, logout, getAccessTokenSilently);
      const response = await axios.post(
        `${config.apiUrl}/extract-crop`,
        cropParams,
        config
      );
      let dataCrop = response.data.crop;
      dataCrop.section = response.data.section;
      dataCrop.plant = {
        name: response.data.plant.name,
        id: response.data.plant.id,
        description: response.data.plant.description,
      };
      if (isMountedRef.current) {
        setIsUpdated(false);
        setCropInfo(dataCrop);
      }
    } catch (error) {
      checkBlocked(error, logout);
      checkSubscription(error, dispatch);
      console.log('error -> ', error);
    }
  };

  const plants = cropPlants
    ? cropPlants.map(({ plant }) => ({
        name: plant.name,
        id: plant.id,
        description: plant.description || null,
      }))
    : [];

  const sections = cropSections
    ? cropSections.map(({ section }) => ({
        name: section.name,
        id: section.id,
        description: section.description || null,
      }))
    : [];

  const errorCodes = {
    NOT_PROVIDED: 'This field should be provided.',
    VALUE_SHOULD_BE_POSITIVE: 'Quantity should be positive.',
    INVALID_RANGE: 'Close Date should be after Start Date.',
    MAX_LENGTH_EXCEEDED: 'Maximum length exceeded.',
  };

  const accept = () => {
    formik.handleReset();
  };

  const confirm = (dirty) => {
    if (dirty) {
      confirmDialog({
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        message: 'Are you sure you want to discard the changes?',
        accept,
      });
    } else {
      accept();
    }
  };

  const formik = useFormik({
    initialValues: initialCropFormSchema(cropInfo),
    enableReinitialize: true,
    validationSchema: cropFormSchema,
    onSubmit: (values) => {
      const newCropParams = {
        id: cropId,
        plantId: values.plant.id,
        sectionId: values.section.id,
        lotNumber: values.lotNumber || null,
        plantsPerPot: values.plantsPerPot || null,
        startFormId: values.startForm && values.startForm.id,
        potSizeId: values.potSize && values.potSize.id,
        startDate: values.startDate
          ? moment(values.startDate).format('YYYY-MM-DD')
          : null,
        plantDate: values.plantDate
          ? moment(values.plantDate).format('YYYY-MM-DD')
          : null,
        transplantDate: values.transplantDate
          ? moment(values.transplantDate).format('YYYY-MM-DD')
          : null,
        spaceDate1: values.spaceDate1
          ? moment(values.spaceDate1).format('YYYY-MM-DD')
          : null,
        spaceDate2: values.spaceDate2
          ? moment(values.spaceDate2).format('YYYY-MM-DD')
          : null,
        quantity: values.quantity || null,
        transferCropNumber: values.transferCropNumber || null,
        note: values.note || null,
        versionNumber: cropInfo.version?.number || 0,
      };
      getAuthConfig(true, logout, getAccessTokenSilently).then((res) => {
        axios
          .post(`${res.apiUrl}/update-crop`, newCropParams, res)
          .then((response) => onCropUpdate())
          .catch((error) => {
            checkBlocked(error, logout);
            checkSubscription(error, dispatch);
            if (error.response.data.errors) {
              error.response.data.errors.map((err) =>
                formik.setFieldError(err.fieldName, errorCodes[err.code])
              );
            } else {
              let errorDetail = 'Something went wrong.';
              if (error.response.data.error?.code === 'REFERENCES_EXIST') {
                errorDetail =
                  'Plant Name and Section fields cannot be edited because log records exist.';
              } else if (error.response.data.error?.code === 'CONFLICT') {
                errorDetail =
                  'This crop has been updated by another user. Please refresh the page and then save your updates.';
              }
              toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: errorDetail,
                life: 5000,
              });
            }
            console.log('error -> ', error);
          });
      });
    },
  });

  const onCropUpdate = () => {
    setIsUpdated(true);
    toast.current.show({
      severity: 'success',
      summary: 'Successful',
      detail: 'Crop was updated.',
      life: 5000,
    });
  };

  const refTableHeader = () => {
    if (currentRefTableName === 'startforms') {
      return 'Start Forms';
    } else if (currentRefTableName === 'pot-sizes') {
      return 'Pot Sizes';
    }
    return (
      currentRefTableName[0]?.toUpperCase() + currentRefTableName?.slice(1)
    );
  };

  const refTableValues = {
    [urls.START_FORMS]: startForms,
    [urls.POT_SIZES]: potSizes,
  };

  const onOpenRefTable = (value) => {
    setCurrentRefTableName(value);
    setDisplayRefTable(true);
  };

  const CustomCalendarInput = forwardRef(({ value, onClick }, ref) => (
    <Button
      label={value}
      className={`${styles.calendarButton} p-button-text p-button-plain`}
      icon="pi pi-calendar"
      iconPos="right"
      onClick={onClick}
      ref={ref}
      disabled={isTrialTracker}
      type="button"
    />
  ));

  const breadCrumbItems = [
    {
      label: 'Trials',
      command: () => {
        history.push('/trials');
      },
    },
    {
      label: localStorage.getItem('trialName'),
      command: () => {
        history.push(`/trial/${trialId}`);
      },
    },
    {
      label: 'Crops',
      command: () => {
        history.push(`/trial/${trialId}/crops`);
      },
    },
    { label: cropInfo.plant.name },
  ];

  useEffect(() => {
    isMountedRef.current = true;
    getCropInfo();
    dispatch(getCropOptions(actionData));
    return () => (isMountedRef.current = false);
  }, [isUpdated]);

  return (
    <div className={styles.cropInfo}>
      <BreadCrumb items={breadCrumbItems} />
      <PromptIfDirty dirty={formik.dirty} />
      <div className={styles.cropForm}>
        <h3>Crop Information</h3>
        <Divider />
        <Toast ref={toast} />
        <form onSubmit={formik.handleSubmit}>
          <div className="p-fluid">
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="plant"
                className="p-col-12 p-md-3 p-xl-2 p-text-bold"
              >
                Crop Number
              </label>
              <label className="p-col-12 p-md-8 p-text-bold">
                {formik.values.number}
              </label>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="plant"
                className="p-col-12 p-md-3 p-xl-2 p-text-bold"
              >
                Plant Name*
              </label>
              <div className="p-col-12 p-md-9 p-lg-7 p-xl-5">
                <Dropdown
                  id="plant"
                  onChange={formik.handleChange}
                  onBlur={() => {
                    formik.handleBlur({ target: { name: 'plant' } });
                  }}
                  value={formik?.values?.plant}
                  aria-describedby="plant-invalid"
                  className={`${
                    (error || (formik.errors.plant && formik.touched.plant)) &&
                    'p-invalid'
                  }`}
                  options={plants}
                  optionLabel="name"
                  filter
                  disabled={isTrialTracker}
                />
                {formik.touched.plant && formik.errors.plant && (
                  <small id="plant-invalid" className="p-error p-d-block">
                    {formik.errors.plant}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="plantDate"
                className="p-col-12 p-md-3 p-xl-2 p-text-bold"
              >
                Plant Date*
              </label>
              <div className="p-col-fixed" style={{ width: '300px' }}>
                <DatePicker
                  id="plantDate"
                  onChange={(val) => {
                    formik.setFieldValue('plantDate', val);
                  }}
                  selected={formik.values.plantDate}
                  dateFormat={dateFormatting}
                  weekLabel={'Wk'}
                  showWeekNumbers
                  locale={locales[firstDayOfWeek]}
                  customInput={<CustomCalendarInput />}
                  disabled={isTrialTracker}
                />
                {formik.touched.plantDate && formik.errors.plantDate && (
                  <small
                    id="plantDate-invalid"
                    className="p-col-7 p-md-3 p-xl-2 p-error p-d-block"
                  >
                    {formik.errors.plantDate}
                  </small>
                )}
              </div>
              <label
                className={`${styles.weekLabel} p-col-fixed`}
                style={{ width: '75px' }}
              >
                Week
              </label>
              <div className="p-col-fixed" style={{ width: '70px' }}>
                <InputText
                  value={getWeekNumber(formik.values.plantDate, firstDayOfWeek)}
                  disabled
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="section"
                className="p-col-12 p-md-3 p-xl-2 p-text-bold"
              >
                Section*
              </label>
              <div className="p-col-12 p-md-9 p-lg-7 p-xl-5">
                <Dropdown
                  id="section"
                  onChange={formik.handleChange}
                  onBlur={() => {
                    formik.handleBlur({ target: { name: 'section' } });
                  }}
                  value={formik.values.section}
                  aria-describedby="section-invalid"
                  className={`${
                    (error ||
                      (formik.errors.section && formik.touched.section)) &&
                    'p-invalid'
                  }`}
                  options={sections}
                  optionLabel="name"
                  filter
                  disabled={isTrialTracker}
                />
                {formik.touched.section && formik.errors.section && (
                  <small id="section-invalid" className="p-error p-d-block">
                    {formik.errors.section}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="quantity"
                className="p-col-12 p-md-3 p-xl-2 p-text-bold"
              >
                Quantity*
              </label>
              <div className="p-col-12 p-md-9 p-lg-7 p-xl-5">
                <InputText
                  id="quantity"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.quantity}
                  disabled={isTrialTracker}
                  aria-describedby="quantity-invalid"
                  className={`${
                    (error ||
                      (formik.errors.quantity && formik.touched.quantity)) &&
                    'p-invalid'
                  }`}
                />
                {formik.touched.quantity && formik.errors.quantity && (
                  <small id="quantity-invalid" className="p-error p-d-block">
                    {formik.errors.quantity}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="startForm" className="p-col-12 p-md-3 p-xl-2">
                Start Form
              </label>
              <div className="p-col-7 p-md-4">
                <Dropdown
                  id="startForm"
                  onChange={formik.handleChange}
                  value={formik.values.startForm}
                  options={startForms}
                  optionLabel="name"
                  showClear
                  disabled={isTrialTracker}
                />
              </div>
              <div className="p-col-3 p-md-2">
                <Button
                  className="p-button-rounded p-button-info p-button-outlined"
                  type="button"
                  icon="pi pi-pencil"
                  style={{ display: showElements }}
                  onClick={() => onOpenRefTable('startforms')}
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="startDate" className="p-col-12 p-md-3 p-xl-2">
                Start Date
              </label>
              <div className="p-col-fixed" style={{ width: '300px' }}>
                <DatePicker
                  id="startDate"
                  onChange={(val) => {
                    formik.setFieldValue('startDate', val);
                  }}
                  selected={formik.values.startDate}
                  dateFormat={dateFormatting}
                  weekLabel={'Wk'}
                  showWeekNumbers
                  locale={locales[firstDayOfWeek]}
                  isClearable={!isTrialTracker}
                  customInput={<CustomCalendarInput />}
                  disabled={isTrialTracker}
                />
              </div>
              <label
                className={`${styles.weekLabel} p-col-fixed`}
                style={{ width: '75px' }}
              >
                Week
              </label>
              <div className="p-col-fixed" style={{ width: '70px' }}>
                <InputText
                  value={getWeekNumber(formik.values.startDate, firstDayOfWeek)}
                  disabled
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="transplantDate"
                className="p-col-12 p-md-3 p-xl-2"
              >
                Transplant Date
              </label>
              <div className="p-col-fixed" style={{ width: '300px' }}>
                <DatePicker
                  id="transplantDate"
                  onChange={(val) => {
                    formik.setFieldValue('transplantDate', val);
                  }}
                  selected={formik.values.transplantDate}
                  dateFormat={dateFormatting}
                  weekLabel={'Wk'}
                  showWeekNumbers
                  locale={locales[firstDayOfWeek]}
                  isClearable={!isTrialTracker}
                  customInput={<CustomCalendarInput />}
                  disabled={isTrialTracker}
                />
              </div>
              <label
                className={`${styles.weekLabel} p-col-fixed`}
                style={{ width: '75px' }}
              >
                Week
              </label>
              <div className="p-col-fixed" style={{ width: '70px' }}>
                <InputText
                  value={getWeekNumber(
                    formik.values.transplantDate,
                    firstDayOfWeek
                  )}
                  disabled
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="spaceDate1" className="p-col-12 p-md-3 p-xl-2">
                Space Date 1
              </label>
              <div className="p-col-fixed" style={{ width: '300px' }}>
                <DatePicker
                  id="spaceDate1"
                  onChange={(val) => {
                    formik.setFieldValue('spaceDate1', val);
                  }}
                  selected={formik.values.spaceDate1}
                  dateFormat={dateFormatting}
                  weekLabel={'Wk'}
                  showWeekNumbers
                  locale={locales[firstDayOfWeek]}
                  isClearable={!isTrialTracker}
                  customInput={<CustomCalendarInput />}
                  disabled={isTrialTracker}
                />
              </div>
              <label
                className={`${styles.weekLabel} p-col-fixed`}
                style={{ width: '75px' }}
              >
                Week
              </label>
              <div className="p-col-fixed" style={{ width: '70px' }}>
                <InputText
                  value={getWeekNumber(
                    formik.values.spaceDate1,
                    firstDayOfWeek
                  )}
                  disabled
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="spaceDate2" className="p-col-12 p-md-3 p-xl-2">
                Space Date 2
              </label>
              <div className="p-col-fixed" style={{ width: '300px' }}>
                <DatePicker
                  id="spaceDate2"
                  onChange={(val) => {
                    formik.setFieldValue('spaceDate2', val);
                  }}
                  selected={formik.values.spaceDate2}
                  dateFormat={dateFormatting}
                  weekLabel={'Wk'}
                  showWeekNumbers
                  locale={locales[firstDayOfWeek]}
                  isClearable={!isTrialTracker}
                  customInput={<CustomCalendarInput />}
                  disabled={isTrialTracker}
                />
              </div>
              <label
                className={`${styles.weekLabel} p-col-fixed`}
                style={{ width: '75px' }}
              >
                Week
              </label>
              <div className="p-col-fixed" style={{ width: '70px' }}>
                <InputText
                  value={getWeekNumber(
                    formik.values.spaceDate2,
                    firstDayOfWeek
                  )}
                  disabled
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="lotNumber" className="p-col-12 p-md-3 p-xl-2">
                Lot Number
              </label>
              <div className="p-col-12 p-md-9 p-lg-7 p-xl-5">
                <InputText
                  className={formik.errors.lotNumber ? 'p-invalid' : null}
                  id="lotNumber"
                  type="text"
                  value={formik.values.lotNumber}
                  onChange={formik.handleChange}
                  aria-describedby="lotNumber-invalid"
                  disabled={isTrialTracker}
                />
                {formik.errors.lotNumber ? (
                  <small id="lotNumber-invalid" className="p-error p-d-block">
                    {formik.errors.lotNumber}
                  </small>
                ) : null}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="potSize" className="p-col-12 p-md-3 p-xl-2">
                Pot Size
              </label>
              <div className="p-col-7 p-md-4">
                <Dropdown
                  id="potSize"
                  onChange={formik.handleChange}
                  value={formik.values.potSize}
                  options={potSizes}
                  optionLabel="name"
                  showClear
                  disabled={isTrialTracker}
                />
              </div>
              <div className="p-col-3 p-md-2">
                <Button
                  className="p-button-rounded p-button-info p-button-outlined"
                  type="button"
                  icon="pi pi-pencil"
                  style={{ display: showElements }}
                  onClick={() => onOpenRefTable('pot-sizes')}
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="plantsPerPot" className="p-col-12 p-md-3 p-xl-2">
                Plants per Pot
              </label>
              <div className="p-col-12 p-md-9 p-lg-7 p-xl-5">
                <InputText
                  className={formik.errors.lotNumber ? 'p-invalid' : null}
                  id="plantsPerPot"
                  type="text"
                  value={formik.values.plantsPerPot}
                  onChange={formik.handleChange}
                  aria-describedby="plantsPerPot-invalid"
                  disabled={isTrialTracker}
                />
                {formik.errors.plantsPerPot ? (
                  <small
                    id="plantsPerPot-invalid"
                    className="p-error p-d-block"
                  >
                    {formik.errors.plantsPerPot}
                  </small>
                ) : null}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="note" className="p-col-12 p-md-3 p-xl-2">
                Crop Note
              </label>
              <div className="p-col-12 p-md-9 p-lg-7 p-xl-5">
                <InputTextarea
                  className={formik.errors.note ? 'p-invalid' : null}
                  id="note"
                  type="text"
                  rows="4"
                  onChange={formik.handleChange}
                  aria-describedby="note-invalid"
                  value={formik.values.note}
                  disabled={isTrialTracker}
                />
                {formik.errors.note ? (
                  <small id="note-invalid" className="p-error p-d-block">
                    {formik.errors.note}
                  </small>
                ) : null}
              </div>
            </div>
          </div>
          <div
            className="p-grid p-col-12 p-lg-10 p-xl-7 p-justify-end pad-r-0"
            style={{ display: showElements }}
          >
            <Button
              className={styles.button}
              label="Save"
              type="submit"
              disabled={Boolean(
                !formik.dirty ||
                  formik.errors.plant ||
                  formik.errors.section ||
                  formik.errors.plantDate ||
                  formik.errors.quantity ||
                  formik.errors.lotNumber ||
                  formik.errors.plantsPerPot ||
                  formik.errors.plantsPerPot ||
                  formik.errors.note ||
                  (formik.values.plant?.name === cropInfo.plant?.name &&
                    formik.values.section.name === cropInfo.section.name &&
                    formik.values.quantity.toString() ===
                      cropInfo.quantity.toString() &&
                    formik.values?.startForm?.name ===
                      cropInfo.startForm?.name &&
                    formik.values?.potSize?.name === cropInfo.potSize?.name &&
                    formik.values.lotNumber ===
                      (cropInfo.lotNumber ? cropInfo.lotNumber : '') &&
                    formik.values.plantsPerPot ===
                      (cropInfo.plantsPerPot ? cropInfo.plantsPerPot : '') &&
                    formik.values.note ===
                      (cropInfo.note ? cropInfo.note : '') &&
                    formik.values.plantDate.toDateString() ===
                      new Date(cropInfo.plantDate).toDateString() &&
                    formik.values?.startDate?.toDateString() ===
                      (cropInfo.startDate
                        ? new Date(cropInfo.startDate)?.toDateString()
                        : undefined) &&
                    formik.values?.transplantDate?.toDateString() ===
                      (cropInfo.transplantDate
                        ? new Date(cropInfo.transplantDate)?.toDateString()
                        : undefined) &&
                    formik.values?.spaceDate1?.toDateString() ===
                      (cropInfo.spaceDate1
                        ? new Date(cropInfo.spaceDate1)?.toDateString()
                        : undefined) &&
                    formik.values?.spaceDate2?.toDateString() ===
                      (cropInfo.spaceDate2
                        ? new Date(cropInfo.spaceDate2)?.toDateString()
                        : undefined))
              )}
              icon="pi pi-check"
              autoFocus
            />
            <Button
              className={`p-button-secondary ${styles.button}`}
              disabled={Boolean(
                !formik.dirty ||
                  formik.errors.plant ||
                  formik.errors.section ||
                  formik.errors.plantDate ||
                  formik.errors.quantity ||
                  formik.errors.lotNumber ||
                  formik.errors.plantsPerPot ||
                  formik.errors.note ||
                  (formik.values.plant?.name === cropInfo.plant?.name &&
                    formik.values.section.name === cropInfo.section.name &&
                    formik.values.quantity.toString() ===
                      cropInfo.quantity.toString() &&
                    formik.values?.startForm?.name ===
                      cropInfo.startForm?.name &&
                    formik.values?.potSize?.name === cropInfo.potSize?.name &&
                    formik.values.lotNumber ===
                      (cropInfo.lotNumber ? cropInfo.lotNumber : '') &&
                    formik.values.plantsPerPot ===
                      (cropInfo.plantsPerPot ? cropInfo.plantsPerPot : '') &&
                    formik.values.note ===
                      (cropInfo.note ? cropInfo.note : '') &&
                    formik.values.plantDate.toDateString() ===
                      new Date(cropInfo.plantDate).toDateString() &&
                    formik.values?.startDate?.toDateString() ===
                      (cropInfo.startDate
                        ? new Date(cropInfo.startDate)?.toDateString()
                        : undefined) &&
                    formik.values?.transplantDate?.toDateString() ===
                      (cropInfo.transplantDate
                        ? new Date(cropInfo.transplantDate)?.toDateString()
                        : undefined) &&
                    formik.values?.spaceDate1?.toDateString() ===
                      (cropInfo.spaceDate1
                        ? new Date(cropInfo.spaceDate1)?.toDateString()
                        : undefined) &&
                    formik.values?.spaceDate2?.toDateString() ===
                      (cropInfo.spaceDate2
                        ? new Date(cropInfo.spaceDate2)?.toDateString()
                        : undefined))
              )}
              label="Cancel"
              type="button"
              icon="pi pi-times"
              onClick={() => confirm(formik.dirty)}
            />
          </div>
        </form>
      </div>
      <Dialog
        className={'confirmDialog'}
        header={refTableHeader()}
        visible={displayRefTable}
        onHide={() => setDisplayRefTable(false)}
      >
        <ReferenceTable
          referenceBook={true}
          values={refTableValues[currentRefTableName]}
          margin="20px"
          referenceName={currentRefTableName}
          editable={true}
          trialId={trialId}
        />
      </Dialog>
    </div>
  );
};

export default CropForm;
