import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { Galleria } from 'primereact/galleria';
import { ProgressBar } from 'primereact/progressbar';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { checkBlocked, getAuthConfig } from '../../../../auth/auth-service';
import { checkSubscription } from '../../../Common/utils';
import styles from './LogImages.module.scss';

const LogImages = ({
  images,
  header,
  isEditing,
  logId,
  fieldId,
  setEditedImageId,
  setImageIsUpdated,
  setImageIsCreated,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [displayEditImageModal, setDisplayEditImageModal] = useState(false);
  const [initialImages, setInitialImages] = useState(images);
  const [newImages, setNewImages] = useState([]);
  const [imagesToUpdate, setImagesToUpdate] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [isImagesEdited, setIsImagesEdited] = useState(false);
  const [validationFail, setValidationFail] = useState(false);
  const [isError, setIsError] = useState(false);

  const galleria = useRef(null);
  const fileUploadRef = useRef(null);
  const fileUpdateRef = useRef(null);
  const toast = useRef(null);

  const dispatch = useDispatch();

  const { logout, getAccessTokenSilently } = useAuth0();

  const responsiveOptions = [
    {
      breakpoint: '1024px',
      numVisible: 5,
    },
    {
      breakpoint: '768px',
      numVisible: 3,
    },
    {
      breakpoint: '560px',
      numVisible: 1,
    },
  ];
  const accept = () => {
    onHide();
  };

  const confirm = () => {
    if (images !== initialImages || newImages.length > 0) {
      confirmDialog({
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        message: 'Are you sure you want to discard the changes?',
        accept,
      });
    } else {
      accept();
    }
  };

  const onHide = () => {
    setDisplayEditImageModal(false);
    setNewImages([]);
    setIsError(false);
    setIsImagesEdited(false);
    fileUploadRef?.current?.clear();
    setImagesToUpdate([]);
  };

  const onItemChange = (event) => {
    setActiveIndex(event.index);
  };

  const itemTemplate = () => {
    return (
      <img
        src={images[activeIndex].img}
        style={{ width: '100%', display: 'block' }}
      />
    );
  };

  const thumbnailTemplate = (image) => {
    return <img src={image.imgTmbn} style={{ display: 'block' }} />;
  };

  const showImage = (index) => {
    setActiveIndex(index);
    galleria.current.show();
  };

  const editImages = async () => {
    if (newImages.length > 0) {
      for (const image of newImages) {
        await createImage(image);
      }
    } else {
      await deleteImages();
    }
  };

  const createImage = async (image) => {
    try {
      setUploading(true);
      const config = await getAuthConfig(true, logout, getAccessTokenSilently);
      config.headers['Content-Type'] = 'multipart/form-data';
      const bodyFormData = new FormData();
      bodyFormData.append('logId', logId);
      bodyFormData.append('fieldId', fieldId);
      bodyFormData.append('file', image);
      await axios.post(
        `${config.apiUrl}/create-log-image`,
        bodyFormData,
        config
      );
      setImageIsCreated(true);
      setEditedImageId(fieldId);
      setIsImagesEdited(true);
      setUploading(false);
    } catch (error) {
      checkBlocked(error, logout);
      checkSubscription(error, dispatch);
      setImageIsCreated(false);
      setUploading(false);
      setIsError(true);
      let errorMessage = 'Something went wrong.';
      if (
        error.response.data.error?.code === 'ALREADY_PUBLISHED' ||
        error.response.data.errors[0]?.code === 'IMAGE_COUNT_EXCEED'
      ) {
        errorMessage = 'Maximum image amount exceeded.';
      } else if (
        error.response.data?.errors[0]?.code === 'NOT_VALID_IMAGE_TYPE'
      ) {
        errorMessage =
          'Invalid image type. Supported image types are PNG, JPG, JPEG';
        onTemplateRemove();
      }
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: errorMessage,
        life: 5000,
      });
      console.log('error -> ', error);
    }
  };

  const onImageUpdate = async () => {
    const images = [...imagesToUpdate];
    setUploading(true);
    try {
      const config = await getAuthConfig(true, logout, getAccessTokenSilently);
      config.headers['Content-Type'] = 'multipart/form-data';
      const bodyFormData = new FormData();
      for (let i = 0; i < images.length; i++) {
        bodyFormData.append('file', images[i].file);
        bodyFormData.append('imageId', images[i].id);
      }
      await axios.post(
        `${config.apiUrl}/update-log-image`,
        bodyFormData,
        config
      );
      setImageIsUpdated(true);
      setEditedImageId(fieldId);
      setIsImagesEdited(true);
      fileUpdateRef.current.clear();
      setUploading(false);
      setImagesToUpdate([]);
      setDisplayEditImageModal(false);
    } catch (error) {
      setUploading(false);
      fileUpdateRef.current.clear();
      setImagesToUpdate([]);
      setImageIsUpdated(false);
      checkBlocked(error, logout);
      checkSubscription(error, dispatch);
      setUploading(false);
      let errorDetail = 'Image was not uploaded.';
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: errorDetail,
        life: 5000,
      });
      console.log('error -> ', error.response?.data);
    }
  };

  const deleteImages = async () => {
    const deletedImages = images
      .filter((image) => !initialImages.includes(image))
      .map((image) => image.imgId);
    try {
      const config = await getAuthConfig(true, logout, getAccessTokenSilently);
      await axios.post(
        `${config.apiUrl}/delete-log-images`,
        { logId: logId, imageId: { in: deletedImages } },
        config
      );
      setEditedImageId(fieldId);
      setIsImagesEdited(true);
    } catch (error) {
      setIsError(true);
      checkBlocked(error, logout);
      checkSubscription(error, dispatch);
      error.response.data.error.code &&
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'This field is required.',
          life: 5000,
        });
      console.log('error -> ', error.response.data.error.code);
    }
  };

  const onTemplateSelect = (e) => {
    setNewImages((prevState) => [...prevState, e.files[0]]);
  };

  const onTemplateRemove = (file, callback) => {
    setNewImages((prevState) =>
      prevState.filter((file_) => file_.name !== file.name)
    );
    callback();
  };

  const onImageRemove = (image) => {
    setInitialImages((prevState) =>
      prevState.filter((file) => file.img !== image.img)
    );
  };

  const onValidationFail = () => {
    setValidationFail(true);
  };

  const onSelectHandler = (event, imgId) => {
    setImagesToUpdate((prevState) => [
      ...prevState,
      { file: event.files[0], id: imgId },
    ]);
  };

  const headerTemplate = (options) => {
    const { className, chooseButton } = options;
    const progressBar = uploading && (
      <div
        className="p-d-flex p-jc-end"
        style={{ position: 'absolute', right: '35px' }}
      >
        <h4>Uploading image(s)...</h4>
        <ProgressBar
          className="p-ml-5 p-as-center"
          mode="indeterminate"
          style={{ width: '250px', height: '7px' }}
        />
      </div>
    );
    return (
      <div
        className={`${className} p-jc-between`}
        style={{
          backgroundColor: 'transparent',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {chooseButton}
        {progressBar}
      </div>
    );
  };

  const imageTemplate = (file, props) => {
    return (
      <div
        className="p-d-flex p-ai-center p-flex-wrap"
        style={{ position: 'relative' }}
      >
        <div className="p-d-flex p-ai-center" style={{ width: '75%' }}>
          <img
            alt={file.name}
            role="presentation"
            src={file.objectURL}
            width={180}
          />
          <span className="p-d-flex p-dir-col p-text-left p-ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag
          value={props.formatSize}
          severity="warning"
          className="p-px-3 p-py-2"
        />
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded p-button-danger p-ml-auto"
          onClick={() => onTemplateRemove(file, props.onRemove)}
        />
      </div>
    );
  };

  const emptyTemplate = () => {
    if (initialImages) {
      return initialImages.map((image, index) => {
        return (
          <div
            className="p-d-flex p-ai-center p-flex-wrap"
            style={{ justifyContent: 'space-between' }}
            key={index}
          >
            <div
              className="p-d-flex p-ai-center"
              style={{
                width: '55%',
                marginBottom: '20px',
                position: 'relative',
              }}
            >
              <img role="presentation" src={image.img} width={250} />
            </div>
            <div className={styles.updateImage}>
              <FileUpload
                className={styles.updateImage}
                mode="basic"
                ref={fileUpdateRef}
                chooseOptions={chooseUpdateOptions}
                disabled={imagesToUpdate.length}
                accept=".jpeg,.jpg,.png"
                maxFileSize={1000000}
                onSelect={(e) => onSelectHandler(e, image.imgId)}
                customUpload={true}
                onValidationFail={onValidationFail}
              />
              <Button
                type="button"
                icon="pi pi-times"
                className="p-button-outlined p-button-rounded p-button-danger p-ml-auto"
                disabled={imagesToUpdate.length}
                onClick={() => onImageRemove(image)}
              />
            </div>
          </div>
        );
      });
    } else {
      return (
        <div className="p-d-flex p-ai-center p-dir-col">
          <i
            className="pi pi-image p-mt-1 p-p-3"
            style={{
              fontSize: '3em',
              borderRadius: '50%',
              backgroundColor: 'var(--surface-b)',
              color: 'var(--surface-d)',
            }}
          />
          <span
            style={{ fontSize: '1em', color: 'var(--text-color-secondary)' }}
            className="p-my-2"
          >
            Drag and Drop Image Here
          </span>
        </div>
      );
    }
  };
  const activeButton = imagesToUpdate.length ? styles.activeUpdateImage : '';
  const chooseUpdateOptions = {
    icon: 'pi pi-fw pi-images',
    className: `p-button-outlined p-button-rounded ${activeButton}`,
    style: { activeButton },
    label: 'Update',
  };

  const chooseOptions =
    newImages.length === 0
      ? {
          icon: 'pi pi-fw pi-plus',
          iconOnly: true,
          className:
            'p-button-rounded p-button-success p-button-outlined hidden',
          style: {
            visibility: initialImages !== images ? 'hidden' : 'visible',
          },
        }
      : {
          icon: 'pi pi-fw pi-images',
          iconOnly: true,
          className: 'custom-choose-btn p-button-rounded p-button-outlined',
        };

  useEffect(() => {
    if (!isError && isImagesEdited) {
      onHide();
    }
  }, [isImagesEdited]);

  useEffect(() => {
    setInitialImages(images);
  }, [images]);

  useEffect(() => {
    displayEditImageModal && setInitialImages(images);
  }, [displayEditImageModal]);

  useEffect(() => {
    if (validationFail) {
      setNewImages([]);
    }
  }, [validationFail]);

  return (
    <div className={styles.logImages}>
      <Toast ref={toast} />
      <Galleria
        className="galleria"
        ref={galleria}
        value={images}
        responsiveOptions={responsiveOptions}
        numVisible={5}
        activeIndex={activeIndex}
        onItemChange={onItemChange}
        circular
        fullScreen={true}
        showItemNavigators
        item={itemTemplate}
        thumbnail={thumbnailTemplate}
      />
      <div className={styles.logImages}>
        {images &&
          images.map((image, index) => {
            let imgEl = (
              <img
                className={styles.logImage}
                src={image.imgTmbn}
                alt={image.alt}
                style={{ cursor: 'pointer' }}
                onClick={() => showImage(index)}
              />
            );
            return <div key={index}>{imgEl}</div>;
          })}
        {isEditing && (
          <Button
            icon="pi pi-pencil"
            className={`p-button-rounded p-button-info ${styles.pencilButton}`}
            onClick={() => {
              setDisplayEditImageModal(true);
            }}
          />
        )}
      </div>
      <Dialog
        className={styles.logImageDialog}
        header={header}
        visible={displayEditImageModal}
        onHide={confirm}
      >
        <FileUpload
          ref={fileUploadRef}
          name="plantImage"
          accept=".jpeg,.jpg,.png"
          maxFileSize={1000000}
          onSelect={onTemplateSelect}
          onValidationFail={onValidationFail}
          headerTemplate={headerTemplate}
          itemTemplate={imageTemplate}
          emptyTemplate={emptyTemplate}
          chooseOptions={chooseOptions}
        />
        <div className="p-grid p-col-12 p-justify-end pad-r-0 margin-l-0">
          {imagesToUpdate.length ? (
            <>
              <Button
                className={styles.button}
                label="Save"
                type="submit"
                onClick={() => onImageUpdate()}
                icon="pi pi-check"
                autoFocus
              />
              <Button
                className={`p-button-secondary ${styles.button}`}
                label="Cancel"
                type="button"
                icon="pi pi-times"
                disabled={uploading}
                onClick={() => confirm()}
              />
            </>
          ) : (
            <>
              <Button
                className={styles.button}
                label="Save"
                type="submit"
                disabled={
                  (initialImages === images && newImages.length < 1) ||
                  uploading
                }
                onClick={() => editImages()}
                icon="pi pi-check"
                autoFocus
              />
              <Button
                className={`p-button-secondary ${styles.button}`}
                label="Cancel"
                type="button"
                icon="pi pi-times"
                disabled={uploading}
                onClick={() => confirm()}
              />
            </>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default LogImages;
