import * as yup from 'yup';

export const initialFieldFormSchema = (fieldInfo) => {
  return {
    name: fieldInfo.name,
    label: fieldInfo.label,
    type: fieldInfo.type,
    multiselect: fieldInfo?.multiselect,
    values: fieldInfo?.values || [],
    minValue: fieldInfo.minValue,
    maxValue: fieldInfo.maxValue,
    group: fieldInfo.group || null,
  };
};

const lengthError = 'Maximum length exceeded.';

export const fieldFormSchema = yup.object().shape({
  name: yup
    .string()
    .required('Field Name should be provided.')
    .max(50, lengthError),
  label: yup
    .string()
    .required('Display Name should be provided.')
    .max(50, lengthError),
  type: yup.object().required('Data Type should be provided.'),
});
