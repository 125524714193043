import React, { useState, useRef, useEffect, Fragment, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useFormik } from 'formik';
import { getFilteredCountries } from '../../Common/utils';
import PromptIfDirty from '../../Common/PromptIfDirty';
import { isAuth } from '../../../auth/auth-service';
import {
  initialLocationFormSchema,
  locationFormSchema,
} from './newLocationFormValidation';
import countries from 'iso3166-2-db/i18n/dispute/UN/en';
import { useRequest, urls } from '../../Common/ApiServices';
import { locationTypes, firstDaysOfWeek, errorCodes } from '../constants';
import { getTaskOptions } from '../../../reduxStore/taskOptions/actions';
import styles from './NewLocationForm.module.scss';

const NewLocationForm = (props) => {
  const initialCountry = useMemo(
    () => ({ name: 'United States', code: 'US' }),
    []
  );
  const initialState = useMemo(() => [{ name: 'Alabama', code: 'AL' }], []);

  const [selectedCountry, setSelectedCountry] = useState(initialCountry);
  const [states, setStates] = useState(initialState);
  const [isError, setIsError] = useState(false);
  const [filteredCountries, setFilteredCountries] = useState(countries);

  const toast = useRef(null);

  const { logout, getAccessTokenSilently } = useAuth0();

  const dispatch = useDispatch();

  const actionData = {
    logout: logout,
    dispatch: dispatch,
    isAuthenticated: isAuth(),
    getAccessTokenSilently: getAccessTokenSilently,
  };

  const { error, sendRequest } = useRequest({});

  const countryList = Object.entries(filteredCountries)
    .map((country) => ({ code: country[0], name: country[1].name }))
    .sort((a, b) => (a.name < b.name ? -1 : 1));

  const responseFailed = (message = 'Something went wrong.') => {
    error?.response?.data?.error?.code !== 'ENTITY_LIMIT_EXCEEDED' &&
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: message,
        life: 5000,
      });
  };

  const setFormikError = () => {
    error.response.data.errors.map((err) =>
      formik.setFieldError(err.fieldName, errorCodes[err.code])
    );
  };

  const accept = () => {
    onHide();
  };

  const confirm = (dirty) => {
    if (dirty) {
      confirmDialog({
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        message: 'Do you want to close the form?\nAll changes will be lost.',
        accept,
      });
    } else {
      accept();
    }
  };

  const formik = useFormik({
    initialValues: initialLocationFormSchema(selectedCountry, states),
    validationSchema: locationFormSchema,
    onSubmit: async (values) => {
      const newLocationParams = {
        name: values.name,
        type: values.type.name,
        address: values.address,
        address2: values.address2 || '',
        city: values.city,
        countryId: values.country.code,
        stateId: values.state.code,
        postalCode: values.postalCode,
        primaryPhone: values.primaryPhone || null,
        firstDayOfWeek: values.firstDay.day,
        description: values.description || '',
      };

      const requestData = {
        url: urls.CREATE_LOCATION,
        method: 'POST',
        data: newLocationParams,
      };

      const response = await sendRequest(requestData);
      if (response) {
        setIsError(false);
        props.setLocationIsCreated(true);
        dispatch(getTaskOptions(actionData));
      }
      return response;
    },
  });

  const onCountryChange = (event) => {
    formik.setFieldValue('country', event.value);
    setSelectedCountry(event.value);
  };

  const onHide = () => {
    props.setDisplayLocationModal(false);
    setSelectedCountry(initialCountry);
    setStates(initialState);
    formik.resetForm();
  };

  useEffect(() => {
    if (selectedCountry) {
      const countryStates = filteredCountries[selectedCountry.code].regions
        .map((region) => ({ name: region.name, code: region.iso }))
        .sort((a, b) => (a.name < b.name ? -1 : 1));
      setStates(countryStates);
      formik.values.state = countryStates[0];
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (!isError && props.locationIsCreated) {
      onHide();
    }
  }, [isError, props.locationIsCreated]);

  useEffect(() => {
    if (error) {
      setIsError(true);
      props.setLocationIsCreated(false);
      if (error.response.data.errors) {
        setFormikError();
      } else {
        responseFailed();
      }
    }
  }, [error]);

  useEffect(() => {
    setFilteredCountries(getFilteredCountries(countries));
  }, []);

  return (
    <Fragment>
      <Dialog
        className={styles.locationFormDialog}
        header="Location Information"
        visible={props.displayLocationModal}
        onHide={() => confirm(formik.dirty)}
      >
        <Divider />
        <PromptIfDirty dirty={formik.dirty} />
        <Toast ref={toast} />
        <form onSubmit={formik.handleSubmit}>
          <div className="p-fluid">
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="name" className="p-col-12 p-md-3 p-text-bold">
                Name*
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  id="name"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  aria-describedby="name-invalid"
                  className={formik.errors.name ? 'p-invalid' : null}
                />
                {formik.touched.name && formik.errors.name && (
                  <small id="name-invalid" className="p-error p-d-block">
                    {formik.errors.name}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="address" className="p-col-12 p-md-3 p-text-bold">
                Address Line 1*
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  id="address"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address}
                  aria-describedby="address-invalid"
                  className={formik.errors.address ? 'p-invalid' : null}
                />
                {formik.touched.address && formik.errors.address && (
                  <small id="address-invalid" className="p-error p-d-block">
                    {formik.errors.address}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="address2" className="p-col-12 p-md-3">
                Address Line 2
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  id="address2"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address2}
                  aria-describedby="address2-invalid"
                  className={formik.errors.address2 ? 'p-invalid' : null}
                />
                {formik.touched.address2 && formik.errors.address2 && (
                  <small id="address2-invalid" className="p-error p-d-block">
                    {formik.errors.address2}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="country" className="p-col-12 p-md-3 p-text-bold">
                Country*
              </label>
              <div className="p-col-12 p-md-9">
                <Dropdown
                  id="country"
                  onChange={onCountryChange}
                  value={formik.values.country}
                  options={countryList}
                  optionLabel="name"
                  filter
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="state" className="p-col-12 p-md-3 p-text-bold">
                State/Province*
              </label>
              <div className="p-col-12 p-md-9">
                <Dropdown
                  id="state"
                  onChange={formik.handleChange}
                  value={formik.values.state}
                  options={states}
                  optionLabel="name"
                  filter
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="city" className="p-col-12 p-md-3 p-text-bold">
                City*
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  id="city"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.city}
                  aria-describedby="city-invalid"
                  className={formik.errors.city ? 'p-invalid' : null}
                />
                {formik.touched.city && formik.errors.city && (
                  <small id="city-invalid" className="p-error p-d-block">
                    {formik.errors.city}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="postalCode"
                className="p-col-12 p-md-3 p-text-bold"
              >
                Postal Code*
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  id="postalCode"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.postalCode}
                  aria-describedby="postalCode-invalid"
                  className={formik.errors.postalCode ? 'p-invalid' : null}
                />
                {formik.touched.postalCode && formik.errors.postalCode && (
                  <small id="postalCode-invalid" className="p-error p-d-block">
                    {formik.errors.postalCode}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="type" className="p-col-12 p-md-3 p-text-bold">
                Location Type*
              </label>
              <div className="p-col-12 p-md-9">
                <Dropdown
                  id="type"
                  onChange={formik.handleChange}
                  value={formik.values.type}
                  options={locationTypes}
                  optionLabel="name"
                />
                {formik.touched.type && formik.errors.type && (
                  <small id="type-invalid" className="p-error p-d-block">
                    {formik.errors.type}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="primaryPhone" className="p-col-12 p-md-3">
                Primary Phone
              </label>
              <div className="p-col-12 p-md-9">
                <InputMask
                  className={formik.errors.primaryPhone ? 'p-invalid' : null}
                  id="primaryPhone"
                  mask="(999) 999-9999"
                  placeholder="(999) 999-9999"
                  value={formik.values.primaryPhone}
                  onChange={formik.handleChange}
                  aria-describedby="primaryPhone-invalid"
                />
                {formik.errors.primaryPhone ? (
                  <small
                    id="primaryPhone-invalid"
                    className="p-error p-d-block"
                  >
                    {formik.errors.primaryPhone}
                  </small>
                ) : null}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="firstDay" className="p-col-12 p-md-3 p-text-bold">
                First Day of Week*
              </label>
              <div className="p-col-12 p-md-9">
                <Dropdown
                  id="firstDay"
                  onChange={formik.handleChange}
                  value={formik.values.firstDay}
                  options={firstDaysOfWeek}
                  optionLabel="name"
                />
                {formik.touched.firstDay && formik.errors.firstDay && (
                  <small id="type-invalid" className="p-error p-d-block">
                    {formik.errors.firstDay}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="description" className="p-col-12 p-md-3">
                Description
              </label>
              <div className="p-col-12 p-md-9">
                <InputTextarea
                  className={formik.errors.description ? 'p-invalid' : null}
                  id="description"
                  type="text"
                  rows="4"
                  onChange={formik.handleChange}
                  aria-describedby="description-invalid"
                  value={formik.values.description}
                />
                {formik.errors.description ? (
                  <small id="description-invalid" className="p-error p-d-block">
                    {formik.errors.description}
                  </small>
                ) : null}
              </div>
            </div>
          </div>
          <div className="p-grid p-col-12 p-justify-end pad-r-0 margin-l-0">
            <Button
              className={styles.button}
              label="Save"
              type="submit"
              disabled={Boolean(
                !formik.dirty ||
                  !formik.values.name ||
                  !formik.values.address ||
                  !formik.values.type ||
                  !formik.values.state.name ||
                  formik.errors.city ||
                  formik.errors.state ||
                  formik.errors.postalCode ||
                  formik.errors.country ||
                  formik.errors.type ||
                  formik.errors.primaryPhone ||
                  formik.errors.description ||
                  formik.errors.address ||
                  formik.errors.address2
              )}
              icon="pi pi-check"
              autoFocus
            />
            <Button
              className={`p-button-secondary ${styles.button}`}
              label="Cancel"
              type="button"
              icon="pi pi-times"
              onClick={() => confirm(formik.dirty)}
            />
          </div>
        </form>
      </Dialog>
    </Fragment>
  );
};

export default NewLocationForm;
