import React, { useEffect, useRef, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';
import ShowDetails from '../../Common/showDetails/showDetails';
import ApplicationForm from '../AplicationForm/ApplicationForm';
import {
  errorOptions,
  getDeleteApplicationParams,
  getInitialApplicationsParams,
} from '../constants';
import { urls, useRequest } from '../../Common/ApiServices';
import Footer from '../../Common/Footer/Footer';
import BreadCrumb from '../../BreadCrumb/BreadCrumb';
import { links, showDetailsFields } from '../../Common/globalConstants';
import styles from './AplicationList.module.scss';

const ApplicationList = (props) => {
  const [applications, setApplications] = useState([]);
  const [initialApplications, setInitialApplications] = useState([]);
  const [displayApplicationModal, setDisplayApplicationModal] = useState(false);
  const [editing, setEditing] = useState(false);
  const [applicationToDelete, setApplicationToDelete] = useState(false);
  const [deleteApplicationDialog, setDeleteApplicationDialog] = useState(false);
  const [applicationIsCreated, setApplicationIsCreated] = useState(false);

  const history = useHistory();
  const tableRef = useRef(null);
  const toast = useRef(null);

  const { error, isLoading, sendRequest } = useRequest({});

  const isTrialTracker = useSelector((state) => state.isTrialTracker);

  const showElements = isTrialTracker ? 'none' : 'inline-flex';

  const responseFailed = (message = 'Something went wrong.') => {
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail: message,
      life: 5000,
    });
  };

  const fetchApplications = async () => {
    const requestData = {
      url: urls.SEARCH_PRODUCT_APPLICATIONS,
      method: 'POST',
      data: getInitialApplicationsParams(localStorage.getItem('productId')),
    };
    const response = await sendRequest(requestData);

    const applications = response.data.results;
    setInitialApplications(applications);
    setApplications(applications);

    return response;
  };

  const getUpdatedData = (id) => {
    const editedApp = applications.filter(
      (app) => app.application.id === id
    )[0];

    return {
      id: editedApp.application.id,
      versionNumber: editedApp.application.version || 0,
      code: editedApp.application.code,
      quantity: editedApp.application.quantity,
      mixerQuantity: editedApp.application.mixerQuantity,
      note: editedApp.application.note || null,
    };
  };

  const updateApplication = async (data) => {
    const requestData = {
      url: urls.UPDATE_PRODUCT_APPLICATION,
      method: 'POST',
      data: getUpdatedData(data.application.id),
    };
    const response = await sendRequest(requestData);

    if (response) {
      toast.current.show({
        severity: 'success',
        summary: 'Successful',
        detail: `Application ${data.application.code} was updated.`,
        life: 3000,
      });
      await fetchApplications();
    }

    return response;
  };

  const onRowEditInit = () => {
    setEditing(true);
  };

  const onRowEditSave = (event) => {
    updateApplication(event.data);
    setEditing(false);
  };

  const onRowEditCancel = () => {
    const newApplications = JSON.parse(JSON.stringify(initialApplications));
    setApplications([...newApplications]);
    setEditing(false);
  };
  const onApplicationCreate = () => {
    setDisplayApplicationModal(true);
  };

  const header = (
    <div className={styles.tableHeader}>
      <div className={styles.tableLabel}>
        <Button
          className="p-button-raised"
          label="New Application"
          icon="pi pi-plus"
          style={{ display: showElements }}
          onClick={() => onApplicationCreate()}
        />
      </div>
    </div>
  );

  const hideDeleteApplicationDialog = () => {
    setApplicationToDelete(null);
    setDeleteApplicationDialog(false);
  };

  const deleteApplication = async () => {
    const requestData = {
      url: urls.DELETE_PRODUCTS_APPLICATIONS,
      method: 'POST',
      data: getDeleteApplicationParams(applicationToDelete[0]?.application?.id),
    };
    const response = await sendRequest(requestData);
    if (response) {
      toast.current.show({
        severity: 'success',
        summary: 'Successful',
        detail: `Application ${applicationToDelete[0].application.code} was deleted.`,
        life: 3000,
      });
      setApplicationToDelete(null);
      setDeleteApplicationDialog(false);
      await fetchApplications();
    }

    return response;
  };

  const deleteApplicationDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteApplicationDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-raised p-button-danger"
        onClick={deleteApplication}
      />
    </>
  );

  const confirmDeleteApplication = (e, crop) => {
    e.stopPropagation();
    setApplicationToDelete(crop);
    setDeleteApplicationDialog(true);
  };

  const removeApplicationBodyTemplate = (rowData) => {
    return (
      <>
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          onClick={(e) => confirmDeleteApplication(e, [rowData])}
        />
      </>
    );
  };

  const removeColumn = !isTrialTracker && (
    <Column
      field="remove"
      header="Remove"
      headerStyle={{ width: '70px' }}
      body={removeApplicationBodyTemplate}
    />
  );

  const editColumn = !isTrialTracker && (
    <Column
      className={editing ? 'rowEditor-editButton-hidden ' : ''}
      rowEditor={true}
      header="Edit"
      headerStyle={{ width: '80px', height: '48px' }}
    />
  );

  const onEditorValueChange = (props, value, field) => {
    const updatedApplications = [...props.value];
    updatedApplications[props.rowIndex].application[field] = value;
    setApplications(updatedApplications);
  };

  const inputTextEditor = (props, field) => {
    const inputType =
      field === 'quantity' || field === 'mixerQuantity' ? 'number' : 'text';
    return (
      <InputText
        type={inputType}
        min="1"
        value={props.rowData.application[field]}
        className={styles.editorWidth}
        onChange={(e) => onEditorValueChange(props, e.target.value, field)}
        placeholder=""
      />
    );
  };

  const getErrorMessage = (error) => {
    let errorDetail;
    if (error?.response?.data?.errors) {
      error?.response?.data?.errors.map((err) => {
        errorDetail = errorOptions[err.code]
          ? errorOptions[err.code][err.fieldName]
          : 'Something went wrong.';
      });
    } else if (error?.response?.data?.error?.code === 'TASK_REFERENCES_EXIST') {
      errorDetail = `${applicationToDelete[0].application.code} can not be removed because it is assigned to at least one task.`;
    }
    return errorDetail;
  };

  const breadCrumbItems = [
    {
      label: 'Products',
      command: () => {
        history.push(links.PRODUCTS);
      },
    },
    {
      label: props?.location?.state,
      command: () => {
        history.push(`${links.PRODUCT}/${props?.location?.state}`);
      },
    },
    { label: 'Applications' },
  ];

  useEffect(() => {
    if (applicationIsCreated) {
      toast.current.show({
        severity: 'success',
        summary: 'Successful',
        detail: 'New application was created.',
        life: 5000,
      });
      fetchApplications();
      setApplicationIsCreated(false);
    }
  }, [applicationIsCreated]);

  useEffect(() => {
    fetchApplications();
  }, []);

  useEffect(() => {
    if (error) {
      responseFailed(getErrorMessage(error));
      hideDeleteApplicationDialog();
      fetchApplications();
    }
  }, [error]);

  return (
    <div className={`${styles.applicationList} list-generic`}>
      <Toast ref={toast} />
      <ApplicationForm
        displayApplicationModal={displayApplicationModal}
        setDisplayApplicationModal={setDisplayApplicationModal}
        setApplicationIsCreated={setApplicationIsCreated}
        responseFailed={responseFailed}
        unitOfMeasure={applications[0]?.application?.unitOfMeasure?.description}
        mixerUnitOfMeasure={
          applications[0]?.application?.unitOfMeasure?.description
        }
        mixer={applications[0]?.application?.unitOfMeasure?.description}
      />
      <BreadCrumb items={breadCrumbItems} />
      <DataTable
        ref={tableRef}
        className="table-generic p-datatable-sm"
        value={applications}
        header={header}
        resizableColumns
        columnResizeMode="expand"
        selectionMode="single"
        dataKey="id"
        loading={isLoading}
        reorderableColumns
        scrollable
        emptyMessage=""
        editMode="row"
        onRowEditInit={onRowEditInit}
        onRowEditCancel={onRowEditCancel}
        onRowEditSave={onRowEditSave}
      >
        <Column
          field="application.code"
          reorderable
          columnKey="code"
          header="Code"
          headerStyle={{ width: '150px', height: '48px' }}
        />
        <Column
          field="product.tradeName"
          reorderable
          columnKey="tradeName"
          header="Trade Name"
          headerStyle={{ width: '150px', height: '48px' }}
        />
        <Column
          className="p-text-nowrap p-text-truncate"
          field="application.quantity"
          reorderable
          columnKey="quantity"
          header="Quantity"
          editor={(props) => inputTextEditor(props, 'quantity')}
          headerStyle={{ width: '150px', height: '48px' }}
        />
        <Column
          field="application.unitOfMeasure.description"
          reorderable
          columnKey=""
          header="U of M"
          headerStyle={{ width: '150px', height: '48px' }}
          bodyStyle={{ height: '50px' }}
        />
        <Column
          field="application.mixerQuantity"
          reorderable
          columnKey="mixerQuantity"
          header="Mixer Quantity"
          editor={(props) => inputTextEditor(props, 'mixerQuantity')}
          headerStyle={{ width: '150px' }}
        />
        <Column
          field="application.mixerUnitOfMeasure.description"
          reorderable
          columnKey="mixerUofM"
          header="Mixer U of M"
          headerStyle={{ width: '150px', height: '48px' }}
        />
        <Column
          field="application.mixer.name"
          reorderable
          columnKey="mixer"
          header="Mixer"
          headerStyle={{ width: '150px', height: '48px' }}
        />
        <Column
          className="p-dt-tooltip p-text-nowrap p-text-truncate"
          field="application.note"
          reorderable
          columnKey="note"
          header="Note"
          body={(rowData) =>
            ShowDetails(rowData.application[showDetailsFields.NOTE])
          }
          editor={(props) => inputTextEditor(props, 'note')}
          headerStyle={{ width: '150px', height: '48px' }}
        />
        {editColumn}
        {removeColumn}
      </DataTable>
      <Footer type="application" totalRecords={applications.length} />
      <Dialog
        visible={deleteApplicationDialog}
        className={classNames(styles.dialog, 'confirmDialog')}
        header="Delete Confirmation"
        footer={deleteApplicationDialogFooter}
        onHide={hideDeleteApplicationDialog}
      >
        <div className="confirmation-content">
          <i className="pi pi-info-circle p-mr-3" />
          {applicationToDelete && (
            <span>
              Are you sure you want to delete application{' '}
              <b>{applicationToDelete[0]?.application?.code}</b>?
            </span>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default withRouter(ApplicationList);
