import React from 'react';
import styles from './Preview.module.scss';
import classNames from 'classnames';
function Preview({ url, hidePreview }) {
  return (
    <div className={styles.previewContainer}>
      <div className={styles.backDrop} onClick={() => hidePreview('')}>
        <i className={classNames('pi pi-times-circle')} />
      </div>
      <img src={url} alt="" />
    </div>
  );
}
export default Preview;
