import * as yup from 'yup';
import moment from 'moment';
import { dateFormat } from '../../../Common/globalConstants';

export const initialTrialFormSchema = (trialInfo) => {
  return {
    name: trialInfo.name,
    location: trialInfo.location || '',
    trialManager: trialInfo.trialManager || '',
    dateFormat: dateFormat[trialInfo.dateFormat?.datePattern],
    startDate: trialInfo.startDate && moment(trialInfo.startDate).toDate(),
    closeDate: trialInfo.closeDate && moment(trialInfo.closeDate).toDate(),
    description: trialInfo.description || '',
    contract: trialInfo.contract,
    logFrequency: trialInfo.logFrequency,
    cropTemplate: trialInfo.cropTemplate,
    note: trialInfo.note || '',
    products: trialInfo.products || undefined,
  };
};

export const trialFormSchema = yup.object().shape({
  name: yup
    .string()
    .required('Trial Name should be provided.')
    .max(50, 'Maximum length exceeded.'),
  location: yup.object().required('Location should be provided.'),
  trialManager: yup.object().required('Trial Manager should be provided.'),
  startDate: yup.date().nullable(),
  closeDate: yup
    .date()
    .nullable()
    .min(yup.ref('startDate'), 'Close Date should be after Start Date.'),
  description: yup.string().max(256, 'Maximum length exceeded.'),
  breeder: yup.object(),
  contract: yup.bool(),
  logFrequency: yup.object().nullable(),
  cropTemplate: yup.object().nullable(),
  note: yup.string().max(256, 'Maximum length exceeded.'),
});
