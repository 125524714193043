import React, { useState, useRef, useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import { useAuth0 } from '@auth0/auth0-react';
import { useFormik } from 'formik';
import { checkSubscription } from '../../../Common/utils';
import PromptIfDirty from '../../../Common/PromptIfDirty';
import ReferenceTable from '../../../Common/ReferenceTable/ReferenceTable';
import {
  initialSectionFormSchema,
  sectionFormSchema,
} from './sectionFormValidation';
import { getAuthConfig, checkBlocked } from '../../../../auth/auth-service';
import styles from './SectionForm.module.scss';

const SectionForm = (props) => {
  const locationId = localStorage.getItem('locationId');
  const sectionParams = {
    query: {
      section: {
        id: {
          is: props.sectionId,
        },
      },
    },
  };

  const initialSectionInfo = {
    name: '',
    sectionType: null,
    zone: null,
    description: '',
  };

  const [sectionInfo, setSectionInfo] = useState(initialSectionInfo);
  const [isError, setIsError] = useState(false);
  const [currentRefTableName, setCurrentRefTableName] = useState('');
  const [displayRefTable, setDisplayRefTable] = useState(false);
  const [touchedTypeField, setTouchedTypeField] = useState(false);

  const { logout, getAccessTokenSilently } = useAuth0();

  const toast = useRef(null);
  const isMountedRef = useRef(null);
  const sectionNameRef = useRef('');

  const dispatch = useDispatch();

  const errorCodes = {
    NOT_PROVIDED: 'Name should be provided.',
    MAX_LENGTH_EXCEEDED: 'Maximum length exceeded.',
    NOT_UNIQUE: 'Name should be unique.',
  };

  const refTableValues = {
    sectiontypes: props.sectionTypes,
    zones: props.zones,
  };

  const getSectionInfo = async () => {
    try {
      const config = await getAuthConfig(true, logout, getAccessTokenSilently);
      const response = await axios.post(
        `${config.apiUrl}/extract-section`,
        sectionParams,
        config
      );
      let dataSection = response.data.section;
      if (isMountedRef.current) {
        props.setSectionIsUpdated(false);
        setSectionInfo(dataSection);
      }
    } catch (error) {
      checkBlocked(error, logout);
      checkSubscription(error, dispatch);
      console.log('error -> ', error);
    }
  };

  const createSection = async (sectionParams) => {
    try {
      const config = await getAuthConfig(true, logout, getAccessTokenSilently);
      await axios.post(
        `${config.apiUrl}/create-section`,
        sectionParams,
        config
      );
      setIsError(false);
      props.setSectionIsCreated(true);
    } catch (error) {
      checkBlocked(error, logout);
      checkSubscription(error, dispatch);
      setIsError(true);
      props.setSectionIsCreated(false);
      if (error.response.data.errors) {
        error.response.data.errors.map((err) =>
          formik.setFieldError(err.fieldName, errorCodes[err.code])
        );
      } else {
        let errorDetail = 'Something went wrong.';
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: errorDetail,
          life: 5000,
        });
      }
      console.log('error -> ', error);
    }
  };

  const updateSection = async (sectionParams) => {
    try {
      const config = await getAuthConfig(true, logout, getAccessTokenSilently);
      await axios.post(
        `${config.apiUrl}/update-section`,
        sectionParams,
        config
      );
      setIsError(false);
      props.setSectionIsUpdated(true);
    } catch (error) {
      checkBlocked(error, logout);
      checkSubscription(error, dispatch);
      setIsError(true);
      props.setSectionIsUpdated(false);
      if (error.response.data.errors) {
        error.response.data.errors.map((err) =>
          formik.setFieldError(err.fieldName, errorCodes[err.code])
        );
      } else {
        let errorDetail = 'Something went wrong.';
        if (error.response.data.error?.code === 'CONFLICT') {
          errorDetail =
            'This section has been updated by another user. Please refresh the page and then save your updates.';
        }
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: errorDetail,
          life: 5000,
        });
      }
      console.log('error -> ', error);
    }
  };

  const accept = () => {
    onHide();
  };

  const confirm = (dirty) => {
    if (dirty) {
      confirmDialog({
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        message: 'Are you sure you want to discard the changes?',
        accept,
      });
    } else {
      accept();
    }
  };

  const onHide = () => {
    props.setDisplaySectionModal(false);
    formik.resetForm();
    setSectionInfo(initialSectionInfo);
    props.setSelectedSection(null);
  };

  const formik = useFormik({
    initialValues: initialSectionFormSchema(sectionInfo, props.sectionTypes),
    enableReinitialize: true,
    validationSchema: sectionFormSchema,
    onSubmit: (values) => {
      let newSectionParams = {
        locationId: locationId,
        name: values.name,
        sectionTypeId: values.type?.id || null,
        zoneId: values.zone?.id || null,
        description: values.description || null,
      };
      if (props.sectionId) {
        newSectionParams = {
          ...newSectionParams,
          ...{
            id: props.sectionId,
            versionNumber: sectionInfo.version?.number || 0,
          },
        };
        updateSection(newSectionParams);
      } else {
        createSection(newSectionParams);
      }
    },
  });

  const onOpenRefTable = (value) => {
    setCurrentRefTableName(value);
    setDisplayRefTable(true);
  };

  useEffect(() => {
    isMountedRef.current = true;
    if (props.sectionId) {
      getSectionInfo();
    }
    return () => (isMountedRef.current = false);
  }, [props.sectionId]);

  useEffect(() => {
    if (!isError && (props.sectionIsCreated || props.sectionIsUpdated)) {
      onHide();
    }
  }, [isError, props.sectionIsUpdated, props.sectionIsCreated]);

  useEffect(() => {
    if (displayRefTable === true) {
      sectionNameRef.current = formik.values.name;
    }
    sectionInfo.name = sectionNameRef.current;
  }, [displayRefTable]);

  return (
    <Fragment>
      <Dialog
        className={styles.sectionFormDialog}
        header="Section Information"
        visible={props.displaySectionModal}
        onHide={() => confirm(formik.dirty)}
      >
        <Toast ref={toast} />
        <PromptIfDirty dirty={formik.dirty} />
        <Tooltip
          target=".custom-choose-btn"
          content="Choose"
          position="bottom"
        />
        <Divider />
        <form onSubmit={formik.handleSubmit}>
          <div className="p-fluid">
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="name" className="p-col-12 p-md-3 p-text-bold">
                Name*
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  id="name"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  aria-describedby="name-invalid"
                  className={formik.errors.name ? 'p-invalid' : null}
                />
                {formik.touched.name && formik.errors.name ? (
                  <small id="name-invalid" className="p-error p-d-block">
                    {formik.errors.name}
                  </small>
                ) : (
                  <small>Section Name is required.</small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="type" className="p-col-12 p-md-3 p-text-bold">
                Type*
              </label>
              <div className="p-col-9 p-md-7">
                <Dropdown
                  id="type"
                  name="type"
                  onChange={formik.handleChange}
                  onBlur={() => setTouchedTypeField(true)}
                  value={formik.values.type}
                  options={props.sectionTypes}
                  optionLabel="name"
                  filter
                  aria-describedby="name-invalid"
                  className={
                    props.sectionTypes?.length === 0 && touchedTypeField
                      ? 'p-invalid'
                      : null
                  }
                />
                {props.sectionTypes?.length === 0 && touchedTypeField ? (
                  <small id="name-invalid" className="p-error p-d-block">
                    Section Type should be provided.
                  </small>
                ) : (
                  <small>Section Type is required.</small>
                )}
              </div>
              <div className="p-col-3 p-md-2">
                <Button
                  className="p-button-rounded p-button-info p-button-outlined"
                  type="button"
                  icon="pi pi-pencil"
                  onClick={() => onOpenRefTable('sectiontypes')}
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="zone" className="p-col-12 p-md-3">
                Zone
              </label>
              <div className="p-col-9 p-md-7">
                <Dropdown
                  id="zone"
                  onChange={formik.handleChange}
                  value={formik.values.zone}
                  options={props.zones}
                  optionLabel="name"
                  filter
                  showClear
                />
              </div>
              <div className="p-col-3 p-md-2">
                <Button
                  className="p-button-rounded p-button-info p-button-outlined"
                  type="button"
                  icon="pi pi-pencil"
                  onClick={() => onOpenRefTable('zones')}
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="description" className="p-col-12 p-md-3">
                Description
              </label>
              <div className="p-col-12 p-md-9">
                <InputTextarea
                  className={formik.errors.description ? 'p-invalid' : null}
                  id="description"
                  type="text"
                  rows="4"
                  onChange={formik.handleChange}
                  aria-describedby="description-invalid"
                  value={formik.values.description}
                />
                {formik.errors.description ? (
                  <small id="description-invalid" className="p-error p-d-block">
                    {formik.errors.description}
                  </small>
                ) : null}
              </div>
            </div>
          </div>
          <div className="p-grid p-col-12 p-justify-end pad-r-0 margin-l-0">
            <Button
              className={styles.button}
              label="Save"
              type="submit"
              disabled={Boolean(
                (!formik.dirty && !sectionNameRef) ||
                  !formik.values.name ||
                  formik.errors.description
              )}
              icon="pi pi-check"
              autoFocus
            />
            <Button
              className={`p-button-secondary ${styles.button}`}
              label="Cancel"
              type="button"
              icon="pi pi-times"
              onClick={() => confirm(formik.dirty)}
            />
          </div>
        </form>
      </Dialog>
      <Dialog
        className="confirmDialog"
        header={
          currentRefTableName === 'zones' ? 'Section Zones' : 'Section Types'
        }
        visible={displayRefTable}
        onHide={() => setDisplayRefTable(false)}
      >
        <ReferenceTable
          referenceBook={true}
          values={refTableValues[currentRefTableName]}
          margin="20px"
          referenceName={currentRefTableName}
          editable={true}
        />
        <div />
      </Dialog>
    </Fragment>
  );
};

export default SectionForm;
