import { urls } from '../../../Common/ApiServices';

export const options = {
  NEW_FIELD: [
    { name: 'TEXT' },
    { name: 'SELECT' },
    { name: 'NOTE' },
    { name: 'INTEGER' },
    { name: 'NUMBER' },
    { name: 'DATE' },
    { name: 'TIME' },
    { name: 'DATE_TIME' },
    { name: 'CHECKBOX' },
    { name: 'IMAGE' },
  ],
  TEXT: [
    { name: 'TEXT' },
    { name: 'NOTE' },
    { name: 'INTEGER' },
    { name: 'NUMBER' },
    { name: 'DATE' },
    { name: 'TIME' },
    { name: 'DATE_TIME' },
  ],
  NOTE: [{ name: 'NOTE' }, { name: 'TEXT' }],
  INTEGER: [{ name: 'INTEGER' }, { name: 'TEXT' }, { name: 'NUMBER' }],
  NUMBER: [{ name: 'NUMBER' }, { name: 'TEXT' }, { name: 'INTEGER' }],
  DATE: [{ name: 'DATE' }, { name: 'TEXT' }, { name: 'DATE_TIME' }],
  TIME: [{ name: 'TIME' }, { name: 'TEXT' }],
  DATE_TIME: [{ name: 'DATE_TIME' }, { name: 'TEXT' }, { name: 'DATE' }],
  SELECT: [{ name: 'SELECT' }, { name: 'TEXT' }],
  CHECKBOX: [{ name: 'CHECKBOX' }],
  IMAGE: [{ name: 'IMAGE' }],
};

export const initialFieldInfo = {
  name: '',
  label: '',
  type: '',
  multiselect: false,
  values: [],
  minValue: '',
  maxValue: '',
  group: '',
};

export const errorCodes = {
  NOT_PROVIDED: 'This field should be provided.',
  MAX_LENGTH_EXCEEDED: 'Maximum length exceeded.',
  NOT_UNIQUE: 'Field Name should be unique.',
  INVALID_VALUE: 'Incorrect value.',
  INVALID_RANGE: 'Value is outside of allowed range.',
};

export const refTableHeaders = {
  [urls.PROFILE_FIELD_GROUPS]: 'Profile Field Groups',
};

export const defaultErrorMessage = 'Something went wrong.';
