import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Paginator } from 'primereact/paginator';
import { Toast } from 'primereact/toast';
import { useAuth0 } from '@auth0/auth0-react';
import { getAuthConfig, checkBlocked } from '../../../../auth/auth-service';
import BreadCrumb from '../../../BreadCrumb/BreadCrumb';
import styles from './TrialPlantList.module.scss';
import { InputText } from 'primereact/inputtext';
import { checkSubscription } from '../../../Common/utils';

const TrialPlantList = () => {
  const { id } = useParams();
  const trialName = useLocation();
  const initialPlantParams = {
    query: {
      trial: {
        id: {
          is: id,
        },
      },
    },
    navigation: {
      sort: [
        {
          key: 'name',
          order: 'ASC',
        },
      ],
      page: {
        from: 0,
        size: 20,
      },
    },
  };

  const [plants, setPlants] = useState([]);
  const [loading, setLoading] = useState(false);
  const [plantParams, setPlantParams] = useState(initialPlantParams);
  const [sort, setSort] = useState({ field: 'name', order: 1 });
  const [pagination, setPagination] = useState({ first: 0, rows: 20 });
  const [editing, setEditing] = useState(false);
  const [totalRecords, setTotalRecords] = useState(null);
  const [plantToDelete, setPlantToDelete] = useState(null);
  const [deletePlantDialog, setDeletePlantDialog] = useState(false);
  const [plantIsDeleted, setPlantIsDeleted] = useState(false);
  const [plantIsUpdated, setPlantIsUpdated] = useState(false);
  const [updateOrderFailed, setUpdateOrderFailed] = useState(false);
  const [frozenWidth, setFrozenWidth] = useState(320);

  const toast = useRef(null);
  const isMountedRef = useRef(null);
  const isInitialMount = useRef(true);

  const { logout, getAccessTokenSilently } = useAuth0();
  const isTrialTracker = useSelector((state) => state.isTrialTracker);

  const dispatch = useDispatch();

  const history = useHistory();

  const showElements = isTrialTracker ? 'none' : 'inline-flex';

  const fetchPlants = async () => {
    try {
      const config = await getAuthConfig(true, logout, getAccessTokenSilently);
      const response = await axios.post(
        `${config.apiUrl}/search-trial-plants`,
        plantParams,
        config
      );
      const plants = await response.data.results.map((rawPlant) => ({
        trialId: rawPlant?.id,
        id: rawPlant?.plant?.id,
        name: rawPlant?.plant?.name,
        code: rawPlant?.plant?.code,
        patentNumber: rawPlant?.plant?.patentNumber,
        marketStatus: rawPlant?.plant?.marketStatus?.name,
        plantOrder: rawPlant.order,
        program: rawPlant.program ? rawPlant.program.name : null,
        class: rawPlant.class ? rawPlant.class.name : null,
        series: rawPlant.series ? rawPlant.series.name : null,
        breeder: rawPlant.breeder ? rawPlant.breeder.name : null,
      }));
      if (isMountedRef.current) {
        setPlants(plants);
        setTotalRecords(response.data.page.total);
        setPlantIsDeleted(false);
        setPlantIsUpdated(false);
        setUpdateOrderFailed(false);
      }
    } catch (error) {
      checkBlocked(error, logout);
      checkSubscription(error, dispatch);
      console.log('error -> ', error);
    } finally {
      setLoading(false);
    }
  };

  const updatePlant = async (data) => {
    const plantParams = {
      trialPlantId: data.trialId,
      order: +data.order,
    };
    try {
      const config = await getAuthConfig(true, logout, getAccessTokenSilently);
      await axios.post(
        `${config.apiUrl}/update-trial-plant`,
        plantParams,
        config
      );
      setPlantIsUpdated(true);
      toast.current.show({
        severity: 'success',
        summary: 'Successful',
        detail: `${data.name} was updated.`,
        life: 5000,
      });
    } catch (error) {
      setUpdateOrderFailed(true);
      checkBlocked(error, logout);
      checkSubscription(error, dispatch);
      const errorMessage = (error.response.data.errors[0].code = 'NOT_UNIQUE'
        ? 'Not unique order'
        : 'Something went wrong');
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: errorMessage,
        life: 5000,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (sort && !isInitialMount.current) {
      setPlantParams((oldParams) => {
        let params = cloneDeep(oldParams);
        params.navigation.sort[0].key = sort.field;
        params.navigation.sort[0].order = sort.order === 1 ? 'ASC' : 'DESC';
        return params;
      });
    }
  }, [sort]);

  useEffect(() => {
    if (!isInitialMount.current) {
      setPlantParams((oldParams) => {
        let params = cloneDeep(oldParams);
        params.navigation.page.from = pagination.first;
        params.navigation.page.size = pagination.rows;
        return params;
      });
    }
  }, [pagination]);

  useEffect(() => {
    isMountedRef.current = true;
    setLoading(true);
    fetchPlants();
    isInitialMount.current = false;
    return () => {
      return (isMountedRef.current = false), (isInitialMount.current = false);
    };
  }, [plantParams]);

  useEffect(() => {
    if (plantIsDeleted || plantIsUpdated || updateOrderFailed) {
      setLoading(true);
      fetchPlants();
    }
  }, [plantIsDeleted, plantIsUpdated, updateOrderFailed]);

  const nameBodyTemplate = (rowData) => {
    return <div className={styles.linkStyle}>{rowData.name}</div>;
  };

  const onSort = ({ sortField, sortOrder }) => {
    setSort({ field: sortField, order: sortOrder });
  };

  const onPageSelect = ({ first, rows }) => {
    setPagination({ first, rows });
  };

  const onSelectPlants = () => {
    const selectPlantPage = 'select-plants';
    history.push(`/trial/${id}/${selectPlantPage}`);
  };

  const header = (
    <div className={styles.tableHeader}>
      <div className={styles.tableLabel}>
        <Button
          className="p-button-raised"
          style={{ display: showElements }}
          label="Select Plants"
          icon="pi pi-plus"
          disabled={editing}
          onClick={() => onSelectPlants()}
        />
      </div>
    </div>
  );

  const footer = () => {
    if (!totalRecords) {
      let emptyMessage = '';
      if (totalRecords === 0) {
        emptyMessage =
          'You don’t have plants yet. Please select plants for the trial.';
      }
      return (
        <div className="generic-list-message">
          <h3 className="p-text-center">{emptyMessage}</h3>
        </div>
      );
    } else {
      return (
        <Paginator
          rows={pagination.rows}
          className="tabPaginator"
          totalRecords={totalRecords}
          first={pagination.first}
          rowsPerPageOptions={[20, 50, 100]}
          template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          onPageChange={onPageSelect}
        />
      );
    }
  };

  const confirmDeletePlant = (plant) => {
    setPlantToDelete(plant);
    setDeletePlantDialog(true);
  };

  const actionBodyTemplate = (rowData) => {
    return isTrialTracker ? null : (
      <React.Fragment>
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          disabled={editing}
          onClick={() => confirmDeletePlant(rowData)}
        />
      </React.Fragment>
    );
  };

  const hideDeletePlantDialog = () => {
    setDeletePlantDialog(false);
  };

  const onRowEditInit = () => {
    setEditing(true);
  };

  const onRowEditSave = (event) => {
    updatePlant(event.data);
    setEditing(false);
  };

  const onRowEditCancel = () => {
    setEditing(false);
  };

  const onEditorValueChange = (props, value) => {
    let updatedPlants = [...props.value];
    updatedPlants[props.rowIndex].order = value;
    setPlants(updatedPlants);
  };

  const deleteTrialPlant = () => {
    setDeletePlantDialog(false);
    const plantParams = {
      trialId: id,
      plantId: {
        in: [plantToDelete.id],
      },
    };
    getAuthConfig(true, logout, getAccessTokenSilently).then((res) => {
      axios
        .post(`${res.apiUrl}/delete-trial-plants`, plantParams, res)
        .then(() => {
          toast.current.show({
            severity: 'success',
            summary: 'Successful',
            detail: `${plantToDelete.name} was deleted from the trial.`,
            life: 5000,
          });
          setPlantIsDeleted(true);
        })
        .catch((error) => {
          checkBlocked(error, logout);
          checkSubscription(error, dispatch);
          let errorDetail = 'Something went wrong.';
          if (error.response.data.error.code === 'REFERENCES_EXIST') {
            errorDetail = `${plantToDelete.name} can not be removed because it is assigned to at least one crop.`;
          }
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: errorDetail,
            life: 5000,
          });
          console.log('error -> ', error);
        });
    });
  };

  const changeFrozenWidth = (event) => {
    if (event.column.columnKey === 'name') {
      setFrozenWidth(frozenWidth + event.delta);
    }
  };

  const inputTextEditor = (props) => {
    let defaultWidth = '100px';
    let placeholder = '';
    return (
      <InputText
        type="text"
        value={props.rowData.order}
        style={{ width: defaultWidth }}
        onChange={(e) => onEditorValueChange(props, e.target.value)}
        placeholder={placeholder}
      />
    );
  };

  const deletePlantsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeletePlantDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-raised p-button-danger"
        onClick={deleteTrialPlant}
      />
    </React.Fragment>
  );

  const breadCrumbItems = [
    {
      label: 'Trials',
      command: () => {
        history.push('/trials');
      },
    },
    {
      label: localStorage.getItem('trialName') || trialName.state,
      command: () => {
        history.push(`/trial/${id}`);
      },
    },
    { label: 'Trial Plants' },
  ];

  return (
    <div className={`${styles.trialPlantList} list-generic`}>
      <BreadCrumb items={breadCrumbItems} />
      <Toast ref={toast} />
      <DataTable
        className="table-generic p-datatable-sm trialPlantList"
        value={plants}
        header={header}
        resizableColumns
        columnResizeMode="expand"
        selectionMode="single"
        dataKey="id"
        sortField={sort.field}
        sortOrder={sort.order}
        onSort={onSort}
        loading={loading}
        reorderableColumns
        scrollable
        emptyMessage=""
        frozenWidth={frozenWidth + 'px'}
        onColumnResizeEnd={(event) => changeFrozenWidth(event)}
        editMode="row"
        onRowEditInit={onRowEditInit}
        onRowEditCancel={onRowEditCancel}
        onRowEditSave={onRowEditSave}
      >
        <Column
          className="p-text-nowrap p-text-truncate"
          field="name"
          sortField="name"
          columnKey="name"
          header="Name"
          body={nameBodyTemplate}
          headerStyle={{ width: '270px', height: '48px' }}
          bodyStyle={{ height: '50px' }}
          frozen
          sortable={!editing}
        />
        <Column
          field="program"
          sortField="programName"
          columnKey="program"
          header="Program"
          headerStyle={{ width: '120px', height: '48px' }}
          bodyStyle={{ height: '50px' }}
          sortable={!editing}
        />
        <Column
          field="code"
          sortField="code"
          columnKey="code"
          header="Code"
          headerStyle={{ width: '100px' }}
          sortable={!editing}
        />
        <Column
          field="class"
          sortField="className"
          columnKey="class"
          header="Class"
          headerStyle={{ width: '120px' }}
          sortable={!editing}
        />
        <Column
          field="series"
          sortField="seriesName"
          columnKey="series"
          header="Series"
          headerStyle={{ width: '120px' }}
          sortable={!editing}
        />
        <Column
          field="breeder"
          sortField="breederName"
          reorderable
          columnKey="breeder"
          header="Breeder"
          headerStyle={{ width: '120px' }}
          sortable={!editing}
        />
        <Column
          className="p-text-nowrap p-text-truncate"
          field="marketStatus"
          columnKey="marketStatus"
          header="Market Status"
          headerStyle={{ width: '145px' }}
          reorderable
          sortable={!editing}
        />
        <Column
          field="patentNumber"
          columnKey="patentNumber"
          header="Patent Number"
          headerStyle={{ width: '145px' }}
          reorderable
          sortable={!editing}
        />
        <Column
          field="plantOrder"
          columnKey="plantOrder"
          header="Trial Order"
          headerStyle={{ width: '120px' }}
          editor={(props) => inputTextEditor(props)}
          sortable={!editing}
        />
        <Column
          className={editing ? 'rowEditor-editButton-hidden ' : ''}
          rowEditor={!isTrialTracker}
          header="Edit"
          headerStyle={{ width: '80px', height: '48px' }}
        />
        <Column
          field="remove"
          header="Remove"
          headerStyle={{ width: '70px' }}
          body={actionBodyTemplate}
        />
      </DataTable>
      {footer()}
      <Dialog
        visible={deletePlantDialog}
        className="confirmDialog"
        header="Delete Confirmation"
        modal
        footer={deletePlantsDialogFooter}
        onHide={hideDeletePlantDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-info-circle p-mr-3"
            style={{ fontSize: '2rem' }}
          />
          {plantToDelete && (
            <span>
              Are you sure you want to delete <b>{plantToDelete.name}</b> from
              the trial?
            </span>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default TrialPlantList;
