import { all, put } from 'redux-saga/effects';
import * as actions from '../trial/actions';
import { urls, makeRequest } from '../../components/Common/ApiServices';

export function* getProfileSaga(action) {
  const query = action.queryRole ? action.queryRole : {};
  const actionData = action.actionData;
  yield put(actions.profileRequest());

  const employeeData = {
    url: urls.SEARCH_EMPLOYEES,
    method: 'POST',
    data: query,
  };
  const locationData = {
    url: urls.SEARCH_LOCATIONS,
    method: 'POST',
    data: {},
  };
  const logFrequencyData = {
    url: urls.LOG_FREQUENCIES,
    method: 'GET',
    data: {},
  };

  const cropTemplateData = {
    url: urls.SEARCH_CROP_TEMPLATES,
    method: 'POST',
    data: {},
  };

  try {
    let [employees, locations, logFrequencies, cropTemplates] = yield all([
      makeRequest(employeeData, actionData),
      makeRequest(locationData, actionData),
      makeRequest(logFrequencyData, actionData),
      makeRequest(cropTemplateData, actionData),
    ]);

    logFrequencies = logFrequencies.data.sort((a, b) =>
      a.name < b.name ? -1 : 1
    );

    yield put(
      actions.profileSuccess(
        locations.data.results,
        logFrequencies,
        employees.data.results,
        cropTemplates.data.templates
      )
    );
  } catch (error) {
    yield put(actions.profileFail(error.response));
  }
}
