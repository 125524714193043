import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Paginator } from 'primereact/paginator';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import merge from 'deepmerge';
import { useAuth0 } from '@auth0/auth0-react';
import { getSectionOptions } from '../../../../reduxStore/section/actions';
import {
  getAuthConfig,
  checkBlocked,
  isAuth,
} from '../../../../auth/auth-service';
import ShowDetails from '../../../Common/showDetails/showDetails';
import BreadCrumb from '../../../BreadCrumb/BreadCrumb';
import SectionForm from '../SectionForm/SectionForm';
import { checkSubscription, getFormattedDate } from '../../../Common/utils';
import { dateFormat, showDetailsFields } from '../../../Common/globalConstants';
import styles from './SectionsList.module.scss';

const SectionsList = (props) => {
  const { id } = useParams();
  const initialSectionParams = {
    query: {
      location: {
        id: {
          is: id,
        },
      },
    },
    navigation: {
      sort: [
        {
          key: 'name',
          order: 'ASC',
        },
      ],
      page: {
        from: 0,
        size: 20,
      },
    },
  };

  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sectionParams, setSectionParams] = useState(initialSectionParams);
  const [selectedSection, setSelectedSection] = useState(null);
  const [sort, setSort] = useState({ field: 'name', order: 1 });
  const [pagination, setPagination] = useState({ first: 0, rows: 20 });
  const [totalRecords, setTotalRecords] = useState(null);
  const [displaySectionModal, setDisplaySectionModal] = useState(false);
  const [sectionIsCreated, setSectionIsCreated] = useState(false);
  const [sectionIsUpdated, setSectionIsUpdated] = useState(false);
  const [sectionToDelete, setSectionToDelete] = useState(null);
  const [deleteSectionDialog, setDeleteSectionDialog] = useState(false);
  const [sectionIsDeleted, setSectionIsDeleted] = useState(false);
  const [globalSearch, setGlobalSearch] = useState(null);

  const { logout, getAccessTokenSilently } = useAuth0();

  const toast = useRef(null);
  const isMountedRef = useRef(null);
  const isInitialMount = useRef(true);
  const tableRef = useRef(null);

  const history = useHistory();

  const dispatch = useDispatch();

  const actionData = {
    logout: logout,
    dispatch: dispatch,
    isAuthenticated: isAuth(),
    getAccessTokenSilently: getAccessTokenSilently,
  };

  const fetchSections = async () => {
    try {
      setLoading(true);
      const config = await getAuthConfig(true, logout, getAccessTokenSilently);
      const response = await axios.post(
        `${config.apiUrl}/browse-sections`,
        sectionParams,
        config
      );
      const sections = await response.data.results.map(({ section }) => ({
        id: section.id,
        name: section.name,

        createDate: getFormattedDate(
          section.createDate,
          dateFormat[section.dateFormat.datePattern]
        ),
        type: section.sectionType?.name || null,
        zone: section.zone?.name || null,
        description: section.description || null,
      }));
      if (isMountedRef.current) {
        setSections(sections);
        setTotalRecords(response.data.page.total);
        setSectionIsCreated(false);
        setSectionIsUpdated(false);
        setSectionIsDeleted(false);
        props.getSectionOptions(actionData);
      }
    } catch (error) {
      checkBlocked(error, logout);
      checkSubscription(error, dispatch);
      console.log('error -> ', error);
    } finally {
      setLoading(false);
    }
  };

  const nameBodyTemplate = (rowData) => {
    return (
      <button
        className={styles.linkStyle}
        onClick={() => onSectionSelect(rowData)}
      >
        {rowData.name}
      </button>
    );
  };

  const onSort = ({ sortField, sortOrder }) => {
    setSort({ field: sortField, order: sortOrder });
  };

  const onPageSelect = ({ first, rows }) => {
    setPagination({ first, rows });
  };

  const onSectionSelect = (section) => {
    setSelectedSection(section);
    setDisplaySectionModal(true);
  };

  const debouncedGlobalSearch = useRef(
    debounce((value) => setGlobalSearch(value), 500)
  ).current;

  const onGlobalSearch = (event) => {
    debouncedGlobalSearch(event.target.value);
  };

  const sortFunc = () => {
    return tableRef?.current?.props.value || sections;
  };

  const onNewSectionCreate = () => {
    setDisplaySectionModal(true);
  };

  const header = (
    <div className={styles.tableHeader}>
      <Button
        className="p-button-raised"
        label="New Section"
        icon="pi pi-plus"
        onClick={() => onNewSectionCreate()}
      />
      <span className={classNames('p-input-icon-left')}>
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => onGlobalSearch(e)}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  const footer = () => {
    if (totalRecords === 0) {
      return (
        <div className="generic-list-message">
          <h3 className="p-text-center">
            You don’t have sections yet. Please create a section.
          </h3>
        </div>
      );
    } else {
      return (
        <Paginator
          rows={pagination.rows}
          className="tabPaginator"
          totalRecords={totalRecords}
          first={pagination.first}
          rowsPerPageOptions={[20, 50, 100]}
          template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          onPageChange={onPageSelect}
        />
      );
    }
  };

  const confirmDeleteSection = (section) => {
    setSectionToDelete(section);
    setDeleteSectionDialog(true);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          onClick={() => confirmDeleteSection(rowData)}
        />
      </React.Fragment>
    );
  };

  const hideDeleteSectionDialog = () => {
    setDeleteSectionDialog(false);
  };

  const deleteSections = () => {
    setDeleteSectionDialog(false);
    const sectionParams = {
      id: {
        in: [sectionToDelete.id],
      },
    };
    getAuthConfig(true, logout, getAccessTokenSilently).then((res) => {
      axios
        .post(`${res.apiUrl}/delete-sections`, sectionParams, res)
        .then(() => {
          setSectionIsDeleted(true);
        })
        .catch((error) => {
          checkBlocked(error, logout);
          checkSubscription(error, dispatch);
          let errorDetail = 'Something went wrong.';
          if (error.response.data.error.code === 'REFERENCES_EXIST') {
            errorDetail = `${sectionToDelete.name} can not be removed because it is assigned to at least one crop.`;
          }
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: errorDetail,
            life: 5000,
          });
          console.log('error -> ', error);
        });
    });
  };

  const deleteSectionsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteSectionDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-raised p-button-danger"
        onClick={deleteSections}
      />
    </React.Fragment>
  );

  const breadCrumbItems = [
    {
      label: 'Locations',
      command: () => {
        history.push('/locations');
      },
    },
    {
      label: localStorage.getItem('locationName'),
      command: () => {
        history.push(`/location/${id}`);
      },
    },
    { label: 'Location Sections' },
  ];

  useEffect(() => {
    if (sort && !isInitialMount.current) {
      setSectionParams((oldParams) => {
        let params = cloneDeep(oldParams);
        params.navigation.sort[0].key = sort.field;
        params.navigation.sort[0].order = sort.order === 1 ? 'ASC' : 'DESC';
        return params;
      });
    }
  }, [sort]);

  useEffect(() => {
    if (!isInitialMount.current) {
      setSectionParams((oldParams) => {
        let params = cloneDeep(oldParams);
        params.navigation.page.from = pagination.first;
        params.navigation.page.size = pagination.rows;
        return params;
      });
    }
  }, [pagination]);

  useEffect(() => {
    if (sectionIsCreated) {
      toast.current.show({
        severity: 'success',
        summary: 'Successful',
        detail: 'New section was added to the location.',
        life: 5000,
      });
      fetchSections();
    } else if (sectionIsUpdated) {
      toast.current.show({
        severity: 'success',
        summary: 'Successful',
        detail: 'Section was updated.',
        life: 5000,
      });
      fetchSections();
    } else if (sectionIsDeleted) {
      toast.current.show({
        severity: 'success',
        summary: 'Successful',
        detail: `Section ${sectionToDelete.name} was deleted from the location.`,
        life: 5000,
      });
      fetchSections();
    }
  }, [sectionIsCreated, sectionIsUpdated, sectionIsDeleted]);

  useEffect(() => {
    isMountedRef.current = true;
    fetchSections();
    isInitialMount.current = false;
    return () => {
      return (isMountedRef.current = false), (isInitialMount.current = false);
    };
  }, [sectionParams]);

  useEffect(() => {
    setSectionParams((oldParams) => {
      let params = cloneDeep(oldParams);
      if (globalSearch) {
        const searchParams = { query: { search: globalSearch } };
        params = merge(params, searchParams);
      } else if (!globalSearch && params.query.search) {
        delete params.query.search;
      }
      return params;
    });
  }, [globalSearch]);

  return (
    <div className={`${styles.sectionsList} list-generic`}>
      <SectionForm
        sectionId={selectedSection?.id || null}
        sectionTypes={props.sectionTypes}
        zones={props.zones}
        displaySectionModal={displaySectionModal}
        setDisplaySectionModal={setDisplaySectionModal}
        setSelectedSection={setSelectedSection}
        setSectionIsUpdated={setSectionIsUpdated}
        setSectionIsCreated={setSectionIsCreated}
        sectionIsUpdated={sectionIsUpdated}
        sectionIsCreated={sectionIsCreated}
      />
      <BreadCrumb items={breadCrumbItems} />
      <Toast ref={toast} />
      <DataTable
        ref={tableRef}
        className="table-generic p-datatable-sm"
        reorderableColumns
        value={sections}
        header={header}
        resizableColumns
        columnResizeMode="expand"
        dataKey="id"
        sortField={sort.field}
        sortOrder={sort.order}
        onSort={onSort}
        loading={loading}
        scrollable
      >
        <Column
          className="p-text-nowrap p-text-truncate"
          field="name"
          columnKey="name"
          header="Section Name"
          body={nameBodyTemplate}
          headerStyle={{ width: '200px', height: '48px' }}
          bodyStyle={{ height: '50px' }}
          sortable
          sortFunction={sortFunc}
        />
        <Column
          field="createDate"
          columnKey="createDate"
          header="Create Date"
          headerStyle={{ width: '130px', height: '48px' }}
          sortable
          sortFunction={sortFunc}
        />
        <Column
          field="zone"
          columnKey="zone"
          header="Zone"
          headerStyle={{ width: '120px' }}
          sortable
          sortFunction={sortFunc}
        />
        <Column
          field="type"
          columnKey="type"
          header="Type"
          headerStyle={{ width: '120px' }}
          sortable
          sortFunction={sortFunc}
        />
        <Column
          className="p-dt-tooltip p-text-nowrap p-text-truncate"
          field="description"
          header="Description"
          headerStyle={{ width: '200px' }}
          body={(rowData) =>
            ShowDetails(rowData[showDetailsFields.DESCRIPTION])
          }
          sortable
          sortFunction={sortFunc}
        />
        <Column
          field="remove"
          header="Remove"
          headerStyle={{ width: '70px' }}
          body={actionBodyTemplate}
        />
      </DataTable>
      {footer()}
      <Dialog
        visible={deleteSectionDialog}
        className="confirmDialog"
        header="Delete Confirmation"
        footer={deleteSectionsDialogFooter}
        onHide={hideDeleteSectionDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-info-circle p-mr-3"
            style={{ fontSize: '2rem' }}
          />
          {sectionToDelete && (
            <span>
              Are you sure you want to delete section{' '}
              <b>{sectionToDelete.name}</b> from the location?
            </span>
          )}
        </div>
      </Dialog>
    </div>
  );
};

// ToDo: Should be replaced with hooks
const mapStateToProps = (state) => {
  return {
    sectionTypes: state.section.sectionTypes,
    zones: state.section.zones,
  };
};

// ToDo: Should be replaced with hooks
const mapDispatchToProps = (dispatch) => {
  return {
    getSectionOptions: (actionData) => dispatch(getSectionOptions(actionData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionsList);
