// Use this function in Redux sagas
import React from 'react';
import axios from 'axios';
import { checkBlocked, getAuthConfig } from '../../../auth/auth-service';
import { checkLimitExceeded, checkSubscription } from '../utils';

const makeRequest = async (
  { url, method, data, headers = [] },
  { logout, dispatch, getAccessTokenSilently }
) => {
  const checkTokenExp = true;

  try {
    const authConfig = await getAuthConfig(
      checkTokenExp,
      logout,
      getAccessTokenSilently
    );
    headers.push(authConfig.headers);
    headers = headers.reduce((r, c) => Object.assign(r, c), {});

    return await axios({
      method: method ? method : 'GET',
      headers: headers,
      url: `${authConfig.apiUrl}/${url}`,
      data: data ? data : {},
    });
  } catch (error) {
    if (error?.response?.data?.error?.code === 'NO_ACTIVE_SUBSCRIPTION') {
      checkSubscription(error, dispatch);
    } else if (error?.response?.data?.error?.code === 'ENTITY_LIMIT_EXCEEDED') {
      checkLimitExceeded(dispatch, true);
    }
    checkBlocked(error, logout);
    return error;
  }
};

export default makeRequest;
