import { cropIdLength, sectionIdLength } from './constants';
import { isEqual } from 'lodash';
import moment from 'moment';

const checkIsDirty = (selectedCrops, controlSelectedCrops, setDirty) => {
  const result = isEqual(selectedCrops, controlSelectedCrops);

  setDirty(!result);
};

const getSectionsAndCrops = (mappedCrops, selectedCrops) => {
  const sectionsWithAllCrops = [];
  const sectionsWithoutAllCrops = [];

  const getSectionId = (key) => {
    return mappedCrops.root.find((crop) => crop.key === key).data.id;
  };

  const getCrops = (sectionKey) => {
    const cropsKeys = Object.keys(selectedCrops).filter(
      (key) => key[0] === sectionKey && key.length > 1
    );

    return mappedCrops.root
      .find((crop) => crop.key === sectionKey)
      .children.filter((child) => {
        return cropsKeys.includes(child.key);
      })
      .map((item) => item.data.name);
  };

  Object.keys(selectedCrops).forEach((key) => {
    if (key.length === 1) {
      sectionsWithAllCrops.push({
        allCrops: selectedCrops[key].checked,
        section: {
          id: {
            is: getSectionId(key.substring(0, 1)),
          },
        },
        crop: { id: { in: getCrops(key) } },
      });
    } else if (key.length > 1) {
      sectionsWithoutAllCrops.push({
        allCrops: false,
        section: {
          id: {
            is: getSectionId(key.substring(0, 1)),
          },
        },
        crop: { id: { in: getCrops(key[0]) } },
      });
    }
  });

  const sections = sectionsWithAllCrops.concat(sectionsWithoutAllCrops);

  const filteredSections = [];

  sections.forEach((section) => {
    const i = filteredSections.findIndex(
      (x) => x.section.id.is === section.section.id.is
    );
    if (i <= -1) {
      filteredSections.push(section);
    }
  });

  return filteredSections;
};

//ToDo uncomment when will implement partial checked section functionality

// const setPartialChecked = (e, selectedCrops) => {
//   let copiedCrops = JSON.parse(JSON.stringify(selectedCrops));
//   if (e.target.checked) {
//     copiedCrops = {
//       ...copiedCrops,
//       [e.target.id]: { checked: e.target.checked, partialChecked: false },
//     };
//   } else {
//     delete copiedCrops[e.target.id];
//   }
//   return !!Object.keys(copiedCrops).filter((crop) => crop.length === 3).length;
// };

const prepareDataToSave = (e, setSelectedCrops) => {
  if (e.target.id.length === sectionIdLength) {
    setSelectedCrops((prevState) => {
      for (let key in prevState) {
        key[0] === e.target.id && delete prevState[key];
      }
      !e.target.checked && delete prevState[e.target.id];
      return !e.target.checked
        ? {
            ...prevState,
          }
        : {
            ...prevState,
            [e.target.id]: {
              checked: e.target.checked,
              //partialChecked: e.target.checked,
            },
          };
    });
  } else if (e.target.id.length === cropIdLength) {
    setSelectedCrops((prevState) => {
      delete prevState[e.target.id];
      return e?.target?.checked
        ? {
            ...prevState,
            [e.target.id]: {
              checked: e?.target?.checked,
              //partialChecked: false,
            },
          }
        : {
            ...prevState,
          };
    });

    //ToDo uncomment when will implement partial checked section functionality

    // newCrops[e.target.id[0]].partialChecked = setPartialChecked(
    //   e,
    //   selectedCrops,
    //   setSelectedCrops
    // );
    //setSelectedCrops(newCrops);
  }
};

const combineDateAndTime = (date, time) => {
  const dateString = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  const timeString = moment(time).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

  return dateString.substring(0, 10) + timeString.substring(10);
};

const getDateWithoutOffset = (dateString = '') => {
  const subString = dateString?.substring(0, dateString?.length - 1);
  return moment(subString).toDate();
};

const getThisWeek = (id) => {
  let today = moment();
  let start = today.clone().weekday(0).format('YYYY-MM-DD');
  let end = today.clone().weekday(6).format('YYYY-MM-DD');

  return {
    query: {
      location: {
        id: {
          in: [id],
        },
      },
      task: { startDate: { from: start, to: end } },
    },
    navigation: {
      sort: [{ key: 'description', order: 'ASC' }],
      page: { from: 0, size: 20 },
    },
  };
};

export {
  checkIsDirty,
  getSectionsAndCrops,
  prepareDataToSave,
  combineDateAndTime,
  getDateWithoutOffset,
  getThisWeek,
};
