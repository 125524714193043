const errorOptions = {
  NOT_PROVIDED: {
    id: 'Trial Field ID should be provided.',
    required: 'Required value should be provided.',
    imageCount: 'Photo Qty should be provided.',
    order: 'Order should be provided.',
  },
  NOT_UNIQUE: {
    order: 'Order is not unique.',
  },
  IS_EMPTY: {
    id: 'Trial Field ID should not be empty.',
  },
  MAX_LENGTH_EXCEEDED: {
    label: 'Maximum length exceeded for Display Name.',
  },
  INVALID_RANGE: {
    imageCount: 'Photo Qty must be in the range from 1 to 20.',
    order: 'Order must be in the range from 1 to 100.',
    minValue: 'Min Value is outside of allowed range.',
    maxValue: 'Max Value is outside of allowed range.',
  },
  FIELDS_EXIST: {
    required: 'Required value cannot be edited because log records exist.',
  },
  CANNOT_SET_REQUIRED: {
    required: 'Cannot set required property since some values are missing.',
  },
  IMAGES_EXIST: {
    imageCount: 'Some images are already logged.',
  },
  INVALID_VALUE: {
    imageCount: 'Incorrect Photo Qty value.',
    minValue: 'Incorrect Min Value.',
    maxValue: 'Incorrect Max Value.',
    label: 'Incorrect Dispaly Name value.',
    order: 'Incorrect Order value.',
  },
  VALUE_SHOULD_BE_POSITIVE: {
    imageCount: 'Photo Qty should be positive.',
    minValue: 'Min Value should be positive.',
    maxValue: 'Max Value should be positive.',
    order: 'Order should be positive.',
  },
};

export { errorOptions };
