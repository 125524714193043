import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { checkBlocked, getAuthConfig } from '../../../../../auth/auth-service';
import {
  checkEmailValidity,
  checkErrorCode,
  checkSubscription,
} from '../../../../Common/utils';
import styles from './Trialshare.module.scss';

const TrialShare = (props) => {
  const [emailValue, setEmailValue] = useState('');
  const [emailList, setEmailList] = useState([]);
  const [emailError, setEmailError] = useState('');

  const { logout, getAccessTokenSilently } = useAuth0();

  const toast = useRef(null);

  const dispatch = useDispatch();

  const onHide = () => {
    props.setDisplayShareModal(false);
    setEmailList([]);
    setEmailValue('');
    setEmailError('');
  };
  const onTrialShare = async () => {
    const shareInfo = {
      trialId: props.trialInfo.id,
      employee: {
        emails: [...emailList],
      },
    };
    try {
      const config = await getAuthConfig(true, logout, getAccessTokenSilently);
      await axios.post(`${config.apiUrl}/share-trial`, shareInfo, config);
      props.trialShared({ ...shareInfo, trialName: props.trialInfo.name });
    } catch (error) {
      checkBlocked(error, logout);
      checkSubscription(error, dispatch);
      toast.current.show({
        severity: 'error',
        summary: checkErrorCode(error),
        life: 6000,
      });
    }
  };

  const renderFooter = () => {
    return (
      <div>
        <Button
          label="Send Invitation"
          icon="pi pi-send"
          disabled={emailList.length === 0 || emailError || emailValue}
          onClick={() => {
            onTrialShare();
            onHide();
          }}
          autoFocus
        />
        <Button
          label="Cancel"
          icon="pi pi-times"
          onClick={() => {
            onHide();
          }}
          className="p-button-secondary"
          style={{ marginRight: '0px' }}
        />
      </div>
    );
  };

  const addEmailToList = () => {
    const validity = checkEmailValidity(emailValue);
    if (validity) {
      setEmailList([...emailList, emailValue]);
      setEmailValue('');
    }
    setEmailError('Wrong email address');
  };

  const removeEmail = (email) => {
    const newList = emailList.filter((listItem) => listItem !== email);
    setEmailList(newList);
  };
  const errorStyle = emailError
    ? { border: '1px solid red', boxShadow: 'none' }
    : {};

  useEffect(() => {
    if (!emailValue) {
      setEmailError('');
    }
  }, [emailValue]);

  return (
    <div>
      <Toast ref={toast} />
      <Dialog
        className={styles.trialShare}
        header={props.header}
        visible={props.displayShareModal}
        footer={renderFooter('displayBasic')}
        onHide={() => onHide()}
      >
        <div className={styles.emailList}>
          {emailList.map((email, index) => (
            <div key={index}>
              {email}
              &nbsp;
              <span
                id={email}
                className="p-multiselect-token-icon pi pi-times-circle"
                style={{ display: 'inline-block' }}
                onClick={(e) => removeEmail(e.target.id)}
              />
            </div>
          ))}
        </div>
        <div className="p-inputgroup">
          <InputText
            style={errorStyle}
            placeholder="Enter email"
            value={emailValue}
            onChange={(e) => setEmailValue(e.target.value)}
          />
          <Button
            icon="pi pi-plus"
            disabled={!emailValue || emailError}
            onClick={() => {
              addEmailToList();
            }}
          />
        </div>
        {emailError && <span style={{ color: 'red' }}>{emailError}</span>}
      </Dialog>
    </div>
  );
};

export default TrialShare;
