import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Toast } from 'primereact/toast';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import { dateFormat, errorCodes, popUp } from '../../../Common/globalConstants';
import { getInitialParams } from '../constants';
import { useRequest, urls } from '../../../Common/ApiServices';
import SharedTrialsFilters from '../SharedTrialsFilters/SharedTrialsFilters';
import SharedTrialsList from '../SharedTrialsList/SharedTrialsList';
import LeftBar from '../../../LeftBar/LeftBar';
import { getDateWithoutOffset } from '../../../Tasks/utils';
import { setPopUp } from '../../../../reduxStore/popUp/actions';
import { useParams } from 'react-router-dom';

const SharedTrialPage = () => {
  const trialName = localStorage.getItem('trialName');
  const { id } = useParams();

  const [filters, setFilters] = useState(null);
  const [sharedTrials, setSharedTrials] = useState([]);
  const [sharedTrialsParams, setSharedTrialsParams] = useState(
    getInitialParams(id)
  );
  const [totalRecords, setTotalRecords] = useState(null);

  const defaultDateFormat = useSelector(
    (state) => state.profileInfo?.dateFormat?.datePattern
  );
  const defaultFirstDay = useSelector(
    (state) => state.profileInfo?.firstDayOfWeek?.day
  );

  const dateFormatPattern = useMemo(
    () =>
      sharedTrials.length ? sharedTrials[0]?.dateFormat : defaultDateFormat,
    [defaultDateFormat, sharedTrials]
  );

  const firstDay = useMemo(
    () =>
      sharedTrials.length ? sharedTrials[0]?.firstDayOfWeek : defaultFirstDay,
    [defaultFirstDay, sharedTrials]
  );

  const toast = useRef(null);
  const { error, isLoading, sendRequest } = useRequest({});
  const dispatch = useDispatch();

  const onApplyNewFilters = (selectedDate) => {
    setFilters({
      sharedDate: selectedDate
        ? {
            from: moment(selectedDate[0]).format('yyyy-MM-DD'),
            to: selectedDate[1]
              ? moment(selectedDate[1]).format('yyyy-MM-DD')
              : moment(selectedDate[0]).format('yyyy-MM-DD'),
          }
        : null,
    });
  };

  const fetchSharedTrials = useCallback(async () => {
    const requestData = {
      url: urls.SEARCH_SHARED_TRIALS,
      method: 'POST',
      data: sharedTrialsParams,
    };
    const response = await sendRequest(requestData);
    if (response) {
      const res = response.data.results.map((item) => {
        return {
          confirmed: item.confirmed ? 'Yes' : 'No',
          id: item.id,
          sharedDate: moment(getDateWithoutOffset(item.sharedDate))
            .local()
            .format(dateFormat[item.dateFormat.datePattern]),
          sharedTrial: { ...item.sharedTrial },
          sourceCompany: { ...item.sourceCompany },
          invitedCompany: { ...item.invitedCompany },
          dateFormat: item.dateFormat.datePattern,
          firstDayOfWeek: item.firstDayOfWeek?.day,
        };
      });
      setSharedTrials(res);
      setTotalRecords(response.data.page.total);
    }
    return response;
  }, [sharedTrialsParams, sendRequest]);

  const updateFilters = (newFilters) => {
    if (!newFilters) {
      return null;
    } else {
      setSharedTrialsParams((oldParams) => {
        const params = cloneDeep(oldParams);

        newFilters?.sharedDate
          ? (params.query.sharedDate = newFilters.sharedDate)
          : delete params.query.sharedDate;

        return params;
      });
    }
  };

  useEffect(() => {
    fetchSharedTrials();
  }, [fetchSharedTrials, sharedTrialsParams]);

  useEffect(() => {
    updateFilters(filters);
  }, [filters]);

  useEffect(() => {
    error &&
      dispatch(
        setPopUp({
          severity: popUp.severities.ERROR,
          summary: popUp.summary.ERROR,
          detail: errorCodes.DEFAULT_MESSAGE.text,
          life: 5000,
          sticky: null,
        })
      );
  }, [error, dispatch]);

  return (
    <>
      <Toast ref={toast} />
      <LeftBar>
        <SharedTrialsFilters
          applyNewFilters={onApplyNewFilters}
          dateFormatPattern={dateFormatPattern}
          firstDay={firstDay}
        />
      </LeftBar>
      <SharedTrialsList
        sharedTrials={sharedTrials}
        totalRecords={totalRecords}
        isLoading={isLoading}
        fetchSharedTrials={fetchSharedTrials}
        sharedTrialsParams={sharedTrialsParams}
        setSharedTrialsParams={setSharedTrialsParams}
        trialId={id}
        trialName={trialName}
      />
    </>
  );
};

export default SharedTrialPage;
