import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import merge from 'deepmerge';
import { useAuth0 } from '@auth0/auth0-react';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Paginator } from 'primereact/paginator';
import { Toast } from 'primereact/toast';
import { switchPage } from '../../../reduxStore/page/actions';
import { trialInfo } from '../../../reduxStore/trial/actions';
import { checkBlocked, getAuthConfig } from '../../../auth/auth-service';
import NewTrialForm from '../NewTrial/NewTrialForm';
import BreadCrumb from '../../BreadCrumb/BreadCrumb';
import ShowDetails from '../../Common/showDetails/showDetails';
import { dateFormat, showDetailsFields } from '../../Common/globalConstants';
import { getFormattedDate } from '../../Common/utils';
import styles from './TrialList.module.scss';

const TrialList = (props) => {
  const initialTrialParams = {
    query: {},
    navigation: {
      sort: [
        {
          key: 'name',
          order: 'ASC',
        },
      ],
      page: {
        from: 0,
        size: 20,
      },
    },
  };

  const [trials, setTrials] = useState([]);
  const [loading, setLoading] = useState(false);
  const [trialParams, setTrialParams] = useState(initialTrialParams);
  const [selectedTrial, setSelectedTrial] = useState(null);
  const [globalSearch, setGlobalSearch] = useState(null);
  const [sort, setSort] = useState({ field: 'name', order: 1 });
  const [pagination, setPagination] = useState({ first: 0, rows: 20 });
  const [totalRecords, setTotalRecords] = useState(null);
  const [displayTrialModal, setDisplayTrialModal] = useState(false);
  const [trialIsCreated, setTrialIsCreated] = useState(false);
  const [frozenWidth, setFrozenWidth] = useState(440);
  const [userCompanyId, setUserCompanyId] = useState(null);
  const [trialToDelete, setTrialToDelete] = useState(null);
  const [deleteTrialDialog, setDeleteTrialDialog] = useState(false);
  const [sharedTrials, setSharedTrials] = useState(false);

  const userCompany = useSelector((state) => state.profileInfo?.company);
  const logFrequencies = useSelector((state) => state.trial.logFrequencies);
  const isTrialTracker = useSelector((state) => state.isTrialTracker);

  const tableRef = useRef(null);
  const toast = useRef(null);
  const isMountedRef = useRef(null);
  const isInitialMount = useRef(true);

  const { logout, getAccessTokenSilently } = useAuth0();

  const dispatch = useDispatch();
  const history = useHistory();

  const statuses = {
    IN_PROGRESS: 'green',
    PENDING: 'cornflowerblue',
    COMPLETED: 'red',
    INACTIVE: 'grey',
  };

  const showElements = isTrialTracker ? 'none' : 'inline-flex';

  const fetchTrials = async () => {
    try {
      const config = await getAuthConfig(true, logout, getAccessTokenSilently);
      const response = await axios.post(
        `${config.apiUrl}/search-trials`,
        trialParams,
        config
      );
      const trials = await response.data.results.map((rawTrial) => ({
        id: rawTrial.trial.id,
        companyId: rawTrial.trial.companyId,
        trialStatus: {
          name: rawTrial.trial.status,
          color: statuses[rawTrial.trial.status],
        },
        name: rawTrial.trial.name,
        startDateSort: getFormattedDate(
          rawTrial.trial.startDate,
          dateFormat[rawTrial.trial.dateFormat.datePattern]
        ),
        startDate: rawTrial.trial.startDate,
        closeDateSort: getFormattedDate(
          rawTrial.trial.closeDate,
          dateFormat[rawTrial.trial.dateFormat.datePattern]
        ),
        closeDate: rawTrial.trial.closeDate,
        trialManager: `${rawTrial.trialManager.firstName} ${rawTrial.trialManager.lastName}`,
        locationName: rawTrial.location.name,
        description: rawTrial.trial.description,
        lastLogDate: rawTrial.lastLog.createDate,
        lastLogSort: getFormattedDate(
          rawTrial.lastLog.createDate,
          dateFormat[rawTrial.trial.dateFormat.datePattern]
        ),
        logFrequency: rawTrial.logFrequency ? rawTrial.logFrequency.name : null,
      }));
      if (isMountedRef.current) {
        setTrials(trials);
        setTotalRecords(response.data.page.total);
        setTrialIsCreated(false);
      }
    } catch (error) {
      checkBlocked(error, logout);
      checkSubscription(error, dispatch);
    } finally {
      setLoading(false);
    }
  };

  const updateFilters = (newFilters) => {
    if (!newFilters) return null;
    const newStatuses = newFilters.statuses
      ? newFilters.statuses.map((status) =>
          status.name.toUpperCase().replace(' ', '_')
        )
      : null;
    const newLocations = newFilters.locations
      ? newFilters.locations.map((location) => location.id)
      : null;
    let newDateFrom = null;
    let newDateTo = null;
    if (newFilters.startDate) {
      newDateFrom = moment(newFilters.startDate[0]).format('YYYY-MM-DD');
      newDateTo = newDateFrom;
      if (newFilters.startDate[1]) {
        newDateTo = moment(newFilters.startDate[1]).format('YYYY-MM-DD');
      }
    }
    setTrialParams((oldParams) => {
      let params = cloneDeep(oldParams);
      if (newStatuses) {
        const statusParams = { query: { trial: { status: { in: null } } } };
        params = merge(params, statusParams);
        params.query.trial.status.in = newStatuses;
      } else if (!newStatuses && params.query.trial) {
        if (params.query.trial.status) delete params.query.trial.status;
      }
      if (newLocations) {
        const locationParams = { query: { location: { id: { in: null } } } };
        params = merge(params, locationParams);
        params.query.location.id.in = newLocations;
      } else if (!newLocations && params.query.location) {
        delete params.query.location;
      }
      if (newDateFrom) {
        const dateParams = {
          query: { trial: { startDate: { from: newDateFrom, to: newDateTo } } },
        };
        params = merge(params, dateParams);
      } else if (!newDateFrom && params.query.trial) {
        if (params.query.trial.startDate) delete params.query.trial.startDate;
      }
      if (newDateFrom) {
        const dateParams = {
          query: { trial: { startDate: { from: newDateFrom, to: newDateTo } } },
        };
        params = merge(params, dateParams);
      } else if (!newDateFrom && params.query.trial) {
        if (params.query.trial.startDate) delete params.query.trial.startDate;
      }
      return params;
    });
  };

  const onTrialSelect = (event) => {
    const trialInfoPage = 'trial';
    const trialName = event.data.name;
    const trialId = event.data.id;
    history.push(`/${trialInfoPage}/${event.data.id}`);
    localStorage.setItem('trialName', trialName);
    localStorage.setItem('trialId', event.data.id);
    dispatch(switchPage(trialInfoPage));
    dispatch(trialInfo(trialName, trialId));
  };

  const hideDeleteTrialDialog = () => {
    setTrialToDelete(null);
    setDeleteTrialDialog(false);
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <div className={styles.circleCentered}>
        <i
          className={`pi pi-circle-on ${styles.statusIcon}`}
          style={{ color: rowData.trialStatus.color }}
        />
      </div>
    );
  };

  const nameBodyTemplate = (rowData) => {
    return <div className={styles.linkStyle}>{rowData.name}</div>;
  };

  const partnerBodyTemplate = (rowData) => {
    if (userCompanyId !== rowData.companyId) {
      return (
        <div className={styles.partnerIcon}>
          <i
            className={`pi pi-check ${styles.statusIcon}`}
            style={{ margin: '0 auto' }}
          />
        </div>
      );
    }
  };

  const onSort = ({ sortField, sortOrder }) => {
    setSort({ field: sortField, order: sortOrder });
  };

  const onPageSelect = ({ first, rows }) => {
    setPagination({ first, rows });
  };

  const sortFunc = () => {
    return tableRef?.current?.props.value || trials;
  };

  const debouncedGlobalSearch = useRef(
    debounce((value) => setGlobalSearch(value), 500)
  ).current;

  const onGlobalSearch = (event) => {
    debouncedGlobalSearch(event.target.value);
  };

  const onNewTrialCreate = () => {
    setDisplayTrialModal(true);
  };

  const changeFrozenWidth = (event) => {
    if (
      event.column.columnKey === 'name' ||
      event.column.columnKey === 'status'
    ) {
      setFrozenWidth(frozenWidth + event.delta);
    }
  };

  const deleteTrial = () => {
    toast.current.show({
      severity: 'success',
      summary: 'Successful',
      detail: 'Trial was deleted.',
      life: 3000,
    });
    setTrialToDelete(null);
    setDeleteTrialDialog(false);
  };

  const deleteTrialDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteTrialDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-raised p-button-danger"
        onClick={deleteTrial}
      />
    </>
  );

  const confirmDeleteTrial = (e, trial) => {
    e.stopPropagation();
    setTrialToDelete(trial);
    setDeleteTrialDialog(true);
  };

  const removeBodyTemplate = (rowData) => {
    if (userCompanyId !== rowData.companyId) {
      return (
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          style={{ display: 'none' }}
          disabled
          onClick={(e) => confirmDeleteTrial(e, [rowData])}
        />
      );
    }
  };

  const header = (
    <div className={styles.tableHeader}>
      <div className={styles.tableLabel}>
        <Button
          className="p-button-raised"
          label="New Trial"
          icon="pi pi-plus"
          style={{ display: showElements }}
          onClick={() => onNewTrialCreate()}
        />
      </div>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => onGlobalSearch(e)}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  const footer = () => {
    if (!totalRecords) {
      let emptyMessage = '';
      if (totalRecords === 0) {
        emptyMessage = 'No trials conform to filters.';
        if (isEqual(initialTrialParams, trialParams)) {
          emptyMessage = 'You don’t have trials yet. Please create a trial.';
        }
      }
      return (
        <div className="generic-list-message">
          <h3 className="p-text-center">{emptyMessage}</h3>
        </div>
      );
    } else {
      return (
        <Paginator
          rows={pagination.rows}
          totalRecords={totalRecords}
          className="tabPaginator"
          first={pagination.first}
          rowsPerPageOptions={[20, 50, 100]}
          template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          onPageChange={onPageSelect}
        />
      );
    }
  };

  const partnerColumn = sharedTrials && (
    <Column
      field="partner"
      reorderable
      columnKey="partner"
      header="Partner"
      body={partnerBodyTemplate}
      headerStyle={{ width: '60px', height: '48px' }}
      bodyStyle={{ height: '50px' }}
      frozen
    />
  );

  const removeColumn = sharedTrials && (
    <Column
      field="remove"
      header="Remove"
      headerStyle={{ width: '70px' }}
      body={removeBodyTemplate}
    />
  );

  const breadCrumbItems = [{ label: 'Trials' }];

  useEffect(() => {
    setUserCompanyId(userCompany?.id);
  }, []);

  useEffect(() => {
    if (!isInitialMount.current) {
      updateFilters(props.newFilters);
    }
  }, [props.newFilters]);

  useEffect(() => {
    if (sort && !isInitialMount.current) {
      setTrialParams((oldParams) => {
        let params = cloneDeep(oldParams);
        params.navigation.sort[0].key = sort.field;
        params.navigation.sort[0].order = sort.order === 1 ? 'ASC' : 'DESC';
        return params;
      });
    }
  }, [sort]);

  useEffect(() => {
    if (!isInitialMount.current) {
      setTrialParams((oldParams) => {
        let params = cloneDeep(oldParams);
        params.navigation.page.from = pagination.first;
        params.navigation.page.size = pagination.rows;
        return params;
      });
    }
  }, [pagination]);

  useEffect(() => {
    if (!isInitialMount.current) {
      setTrialParams((oldParams) => {
        let params = cloneDeep(oldParams);
        if (globalSearch) {
          const searchParams = { query: { search: { is: globalSearch } } };
          params = merge(params, searchParams);
        } else if (!globalSearch && params.query.search) {
          delete params.query.search;
        }
        return params;
      });
    }
  }, [globalSearch]);

  useEffect(() => {
    isMountedRef.current = true;
    setLoading(true);
    fetchTrials();
    isInitialMount.current = false;
    return () => {
      return (isMountedRef.current = false), (isInitialMount.current = false);
    };
  }, [trialParams]);

  useEffect(() => {
    if (trialIsCreated) {
      toast.current.show({
        severity: 'success',
        summary: 'Successful',
        detail: 'New trial was created.',
        life: 5000,
      });
      setLoading(true);
      fetchTrials();
    }
  }, [trialIsCreated]);

  useEffect(() => {
    if (
      userCompanyId &&
      trials.filter((trial) => userCompanyId !== trial.companyId).length > 0
    ) {
      setSharedTrials(true);
    } else {
      setSharedTrials(false);
    }
  }, [userCompanyId, trials]);

  return (
    <div className={`${styles.trialList} list-generic`}>
      <NewTrialForm
        locations={props.locations}
        logFrequencies={logFrequencies}
        displayTrialModal={displayTrialModal}
        setDisplayTrialModal={setDisplayTrialModal}
        setTrialIsCreated={setTrialIsCreated}
        trialIsCreated={trialIsCreated}
      />
      <BreadCrumb items={breadCrumbItems} />
      <Toast ref={toast} />
      <DataTable
        ref={tableRef}
        className={classNames(
          'table-generic p-datatable-sm',
          styles.customTableStyle
        )}
        value={trials}
        header={header}
        resizableColumns
        columnResizeMode="expand"
        selection={selectedTrial}
        onRowSelect={(e) => onTrialSelect(e)}
        onSelectionChange={(e) => setSelectedTrial(e.value)}
        selectionMode="single"
        dataKey="id"
        sortField={sort.field}
        sortOrder={sort.order}
        onSort={onSort}
        loading={loading}
        reorderableColumns
        scrollable
        emptyMessage=""
        frozenWidth={frozenWidth + 'px'}
        onColumnResizeEnd={(event) => changeFrozenWidth(event)}
      >
        {partnerColumn}
        <Column
          field="trialStatus"
          reorderable
          columnKey="status"
          header="Status"
          body={statusBodyTemplate}
          headerStyle={{ width: '100px', height: '48px' }}
          bodyStyle={{ height: '50px' }}
          frozen
          sortable
          sortFunction={sortFunc}
        />
        <Column
          className="p-text-nowrap p-text-truncate"
          field="name"
          reorderable
          columnKey="name"
          header="Name"
          body={nameBodyTemplate}
          headerStyle={{ width: '280px', height: '48px' }}
          frozen
          sortable
          sortFunction={sortFunc}
        />
        <Column
          field="startDateSort"
          sortField="startDate"
          reorderable
          columnKey="startDate"
          header="Start Date"
          headerStyle={{ width: '150px', height: '48px' }}
          bodyStyle={{ height: '50px' }}
          sortable
          sortFunction={sortFunc}
        />
        <Column
          field="closeDateSort"
          sortField="closeDate"
          reorderable
          columnKey="closeDate"
          header="Close Date"
          headerStyle={{ width: '150px' }}
          sortable
          sortFunction={sortFunc}
        />
        <Column
          field="trialManager"
          reorderable
          sortField="trialManagerFirstName"
          columnKey="trialManager"
          header="Trial Manager"
          headerStyle={{ width: '170px' }}
          sortable
          sortFunction={sortFunc}
        />
        <Column
          field="locationName"
          reorderable
          columnKey="locationName"
          header="Location"
          headerStyle={{ width: '150px' }}
          sortable
          sortFunction={sortFunc}
        />
        <Column
          className="p-dt-tooltip"
          field="description"
          reorderable
          columnKey="description"
          header="Description"
          body={(rowData) =>
            ShowDetails(rowData[showDetailsFields.DESCRIPTION])
          }
          headerStyle={{ width: '220px' }}
        />
        <Column
          field="lastLogSort"
          sortField="lastLogDate"
          reorderable
          header="Last Log"
          headerStyle={{ width: '150px' }}
          sortable
          sortFunction={sortFunc}
        />
        {removeColumn}
      </DataTable>
      {footer()}
      <Dialog
        visible={deleteTrialDialog}
        className="confirmDialog"
        header="Delete Confirmation"
        footer={deleteTrialDialogFooter}
        onHide={hideDeleteTrialDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-info-circle p-mr-3"
            style={{ fontSize: '2rem' }}
          />
          {trialToDelete && (
            <span>
              Are you sure you want to delete trial{' '}
              <b>{trialToDelete[0].name}</b>?
            </span>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default TrialList;
