import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import Guides from '@scena/react-guides';
import { isEqual } from 'lodash';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import classNames from 'classnames';
import {
  cssToEnum,
  defaultFontSize,
  enumToCss,
  multiplier,
  types,
  zplFonts,
} from './constants';
import {
  confirmDiscard,
  createField,
  getFieldTypeOptions,
  getInitialLabel,
  getFieldFontSize,
} from './utils';
import LabelsEditorHeader from './LabelsEditorHeader/LabelsEditorHeader';
import LabelsEditorMenu from './LabelsEditorMenu/LabelsEditorMenu';
import DeleteDialog from './DeleteDialog/DeleteDialog';
import AddNewFieldDialog from './AddNewFieldDialog/AddNewFieldDialog';
import { LabelTemplateField } from './LabelTemplateField/LabelTemplateField';
import PromptIfDirty from '../../../Common/PromptIfDirty';
import {
  initialLabelFormSchema,
  labelFormSchema,
} from './labelTemplateFormValidation';
import PanelHeader from './PanelHeader/PanelHeader';
import styles from './LabelTemplateEditor.module.scss';

const LabelTemplateEditor = ({
  showSystemTemplates,
  isSystem,
  name,
  description,
  labelOptions,
  originTemplate,
  setOriginTemplate,
  fullTemplate,
  customFields,
  updateTemplate,
  setCustomFields,
  setDisplayLabelModal,
  setIsDirty,
  createDraftTemplate,
  setFullTemplate,
  currentTemplateId,
  initialFullTemplate,
}) => {
  const [initialTemplate, setInitialTemplate] = useState({});
  const [templateName, setTemplateName] = useState(name ? name : '');
  const [templateDescription, setTemplateDescription] = useState(
    description ? description : ''
  );
  const [fontFamily, setFontFamily] = useState(zplFonts[0]);
  const [fontSize, setFontSize] = useState(defaultFontSize);
  const [fontBold, setFontBold] = useState(true);
  const [inputValue, setInputValue] = useState({});
  const [activeField, setActiveField] = useState(null);
  const [fieldType, setFieldType] = useState(null);
  const [fieldTypeOptions, setFieldTypeOptions] = useState([]);
  const [barcodeHeight, setBarcodeHeight] = useState(14);
  const [activeFieldIndex, setActiveFieldIndex] = useState(null);
  const [activeFieldAxisX, setActiveFieldAxisX] = useState(0);
  const [activeFieldAxisY, setActiveFieldAxisY] = useState(0);
  const [activeImageDimensions, setActiveImageDimensions] = useState({});
  const [activeFieldPosition, setActiveFieldPosition] = useState(null);
  const [activeFieldWrap, setActiveFieldWrap] = useState(false);
  const [deleteFieldDialog, setDeleteFieldDialog] = useState(false);
  const [addNewFieldDialog, setAddNewFieldDialog] = useState(false);
  const [showPrettyName, setShowPrettyName] = useState(false);
  const [showBarcodeText, setShowBarcodeText] = useState(false);
  const [isDropdownOptionsOpen, setIsDropdownOptionsOpen] = useState(false);

  const isTrialTracker = useSelector((state) => state.isTrialTracker);

  const toast = useRef(null);
  let guides = useRef(null);
  let guidesV = useRef(null);
  let onPush;
  let onRemove;

  const setDefaultSettings = () => {
    setShowPrettyName(false);
    setShowBarcodeText(false);
    setActiveField(null);
    setActiveFieldIndex(null);
    setActiveImageDimensions({});
    setFontFamily(zplFonts[0]);
    setFontSize(defaultFontSize);
    setInputValue({});
    setFieldType(null);
    setBarcodeHeight(14);
    setFieldTypeOptions(
      getFieldTypeOptions(formik.values, fullTemplate, customFields)
    );
  };

  const formik = useFormik({
    initialValues: initialLabelFormSchema(initialTemplate, showSystemTemplates),
    enableReinitialize: true,
    validationSchema: labelFormSchema,
    onSubmit: () => onFormSubmit(),
  });

  const onFormSubmit = () => {
    updateTemplate(formik.values, fieldTypeOptions);
    showSystemTemplates && setDisplayLabelModal(false);
    setDefaultSettings();
    setIsDropdownOptionsOpen(false);
  };

  const updateField = () => {
    const newFields = formik?.values?.fields.map((field) => {
      if (field?.name === activeField?.name) {
        const res = {
          name: field.name,
          type: field.type,
          prettyName: inputValue.prettyName,
          showPrettyName: showPrettyName,
          style: {
            font: {
              dimension: {
                size: fontSize,
                measureUnit:
                  field.style.font?.dimension?.measureUnit || 'POINT',
              },
              family: cssToEnum.family[fontFamily.name],
              weight: fontBold ? 'BOLD' : 'NORMAL',
            },
            axisXY: {
              x: +(
                activeFieldAxisX / multiplier[fullTemplate?.style?.measureUnit]
              ).toFixed(2),
              y: +(
                activeFieldAxisY / multiplier[fullTemplate?.style?.measureUnit]
              ).toFixed(2),
            },
            position: activeFieldPosition,
            ...(field.type === types.TEXT && {
              wrapInTwoLines: activeFieldWrap,
            }),
          },
        };
        if (fieldType?.type === 'BARCODE') {
          (res.style.dimension = {
            height: barcodeHeight,
            width: null,
            measureUnit: 'POINT',
          }),
            (res.style.showText = showBarcodeText);
        } else if (
          fieldType?.type === types.IMAGE ||
          fieldType?.type === types.QR
        ) {
          (res.link = activeField.link),
            (res.style = {
              axisXY: {
                x: +(
                  activeFieldAxisX /
                  multiplier[fullTemplate?.style?.measureUnit]
                ).toFixed(2),
                y: +(
                  activeFieldAxisY /
                  multiplier[fullTemplate?.style?.measureUnit]
                ).toFixed(2),
              },
              position: activeFieldPosition,
              dimension: {
                height:
                  fieldType?.type === types.IMAGE
                    ? activeImageDimensions.height
                    : activeImageDimensions.width,
                width: activeImageDimensions.width,
                measureUnit: 'PIXEL',
              },
            });
        }
        return res;
      }
      return field;
    });
    formik.setFieldValue('fields', newFields);
  };

  const updateFieldTypeOptions = () => {
    const newFieldTypeOptions = fieldTypeOptions.map((op) => {
      if (op.name === activeField.name) {
        return { ...op, disabled: true };
      }
      return { ...op, disabled: true };
    });
    setFontSize(+activeField?.style?.font?.dimension?.size || fontSize);
    activeField.type === types.BARCODE &&
      setBarcodeHeight(+activeField?.style?.dimension?.height);
    setShowPrettyName(activeField?.showPrettyName);
    setShowBarcodeText(activeField?.style.showText);
    setFontFamily({
      name:
        enumToCss.family[activeField?.style?.font?.family] ||
        activeField?.style?.font?.family ||
        fontFamily.name,
    });
    setFieldType({
      name: activeField?.name,
      prettyName: activeField?.prettyName,
      type: activeField?.type,
      disabled: false,
    });
    setInputValue({
      prettyName: activeField?.prettyName,
      name: activeField?.name,
    });
    setFieldTypeOptions(newFieldTypeOptions);
  };

  const editAreaStyle = {
    width:
      formik.values.width -
      formik.values.rightMargin -
      formik.values.leftMargin +
      formik.values.measureUnit.name,
    height:
      formik.values.height -
      formik.values.topMargin -
      formik.values.bottomMargin +
      formik.values.measureUnit.name,
    border: '1px dashed grey',
  };

  const outerContainerStyle = {
    paddingTop: formik.values.topMargin + formik.values.measureUnit.name,
    paddingBottom: formik.values.bottomMargin + formik.values.measureUnit.name,
    paddingRight: formik.values.rightMargin + formik.values.measureUnit.name,
    paddingLeft: formik.values.leftMargin + formik.values.measureUnit.name,
  };

  const hideAddNewFieldDialog = () => {
    setAddNewFieldDialog(false);
  };

  const hideDeleteFieldDialog = () => {
    setDeleteFieldDialog(false);
  };

  const deleteField = () => {
    const newCustomFields = customFields?.filter(
      (field) => field.name !== activeField.name
    );
    onRemove(activeFieldIndex);
    setCustomFields(newCustomFields);
    setDefaultSettings();
    hideDeleteFieldDialog();
  };

  const prepareToDelete = (e) => {
    e.preventDefault();
    setDeleteFieldDialog(true);
  };

  const changeOriginTemplate = (template) => {
    setDefaultSettings();
    formik.handleReset();
    setOriginTemplate(template);
  };

  const accept = () => {
    formik.handleReset();
    showSystemTemplates && setDisplayLabelModal(false);
    setDefaultSettings();
  };

  const pushFieldToArray = (fieldInfo) => {
    onPush(createField(fieldInfo));
  };

  const getName = (prettyName) => {
    return prettyName?.replace(/-|#|&|:|'|,|\.|\s+/g, '');
  };

  const addCustomFieldOption = async (value, prettyName, type, image) => {
    const availableField = {
      name: getName(prettyName),
      type: type,
      val: type === types.IMAGE ? image : value,
      prettyName: prettyName,
      showPrettyName: false,
      exampleValue: type === types.IMAGE ? image : value,
    };
    const newState = [...fieldTypeOptions];
    const newFullTemplate = { ...fullTemplate };
    newState.unshift(availableField);
    newFullTemplate.availableFields.unshift(availableField);
    setFieldTypeOptions(newState);
    setFullTemplate(newFullTemplate);
  };

  const changeFieldType = (value) => {
    setActiveField(null);
    setFieldType({
      name: value.dataset.name,
      val: value.dataset.val,
      prettyName: value.dataset.prettyname,
      showPrettyName: !!value.dataset.showprettyname,
      type: value.dataset.typeof,
      disabled: value.dataset.disabled,
      exampleValue: value.dataset.examplevalue,
      origin: value.dataset.origin,
    });
  };

  const onDeleteCustomOption = (e, val) => {
    e.stopPropagation();
    const options = [...fieldTypeOptions];
    const newOptions = options.filter((option) => option.val !== val);
    const newAvailableFields = fullTemplate.availableFields.filter(
      (field) => field.val !== val
    );
    const newFullTemplate = { ...fullTemplate };
    newFullTemplate.availableFields = newAvailableFields;
    setFieldTypeOptions(newOptions);
    setFullTemplate(newFullTemplate);
    setFieldType(null);
  };

  const getImageUrl = (name) => {
    return fieldTypeOptions?.find((option) => option.name === name)?.val;
  };

  useEffect(() => {
    labelOptions && setOriginTemplate(labelOptions[0]);
    showSystemTemplates && createDraftTemplate();
    guides.resize();
    guidesV.resize();
    window.addEventListener('resize', () => {
      guides && guides.resize();
      guides && guidesV.resize();
    });
    return () => {
      formik.handleReset;
    };
  }, []);

  useEffect(() => {
    activeField && updateField();
  }, [
    fontBold,
    fontSize,
    fontFamily,
    showPrettyName,
    showBarcodeText,
    barcodeHeight,
    activeFieldAxisX,
    activeFieldAxisY,
    inputValue,
    activeField,
    activeImageDimensions,
  ]);

  useEffect(() => {
    if (fullTemplate) {
      setInitialTemplate(getInitialLabel(fullTemplate));
      setFontSize(defaultFontSize);
    }
  }, [fullTemplate]);

  useEffect(() => {
    setFieldTypeOptions(
      getFieldTypeOptions(formik.values, fullTemplate, customFields)
    );
  }, [formik.values]);

  useEffect(() => {
    activeField && updateFieldTypeOptions();
  }, [activeField]);

  useEffect(() => {
    formik.dirty && setIsDirty && setIsDirty(formik.dirty);
  }, [formik.dirty]);

  return (
    <div className={styles.labelEditor}>
      <Toast ref={toast} />
      <PromptIfDirty dirty={formik.dirty} />
      <form
        className="trialForm"
        onSubmit={formik.handleSubmit}
        autoComplete={'off'}
      >
        <LabelsEditorHeader
          formik={formik}
          showSystemTemplates={showSystemTemplates}
          originTemplate={originTemplate}
          labelOptions={labelOptions}
          changeOriginTemplate={changeOriginTemplate}
          isSystem={isSystem}
          templateName={templateName}
          templateDescription={templateDescription}
          setTemplateName={setTemplateName}
          setTemplateDescription={setTemplateDescription}
        />
        <div className={styles.editorWrapper}>
          <LabelsEditorMenu formik={formik} isSystem={isSystem} />
          <div className={styles.editorPanel}>
            <div className={styles.verticalRuler}>
              <Guides
                ref={(e) => {
                  guidesV = e;
                }}
                type="vertical"
                zoom={multiplier[fullTemplate?.style?.measureUnit] || 'px'}
                unit={1}
              />
            </div>
            <div className={styles.horizontalRuler}>
              <Guides
                ref={(e) => {
                  guides = e;
                }}
                type="horizontal"
                zoom={multiplier[fullTemplate?.style?.measureUnit] || 'px'}
                unit={1}
              />
            </div>
            <div className={styles.panel}>
              <FormikProvider value={formik}>
                <>
                  <PanelHeader
                    fontSize={fontSize}
                    fontFamily={fontFamily}
                    fieldType={fieldType}
                    fontBold={fontBold}
                    isSystem={isSystem}
                    activeField={activeField}
                    fieldTypeOptions={fieldTypeOptions}
                    barcodeHeight={barcodeHeight}
                    setFontFamily={setFontFamily}
                    setFontSize={setFontSize}
                    setFontBold={setFontBold}
                    setDefaultSettings={setDefaultSettings}
                    setAddNewFieldDialog={setAddNewFieldDialog}
                    changeFieldType={changeFieldType}
                    onDeleteCustomOption={onDeleteCustomOption}
                    isDropdownOptionsOpen={isDropdownOptionsOpen}
                    setIsDropdownOptionsOpen={setIsDropdownOptionsOpen}
                    setBarcodeHeight={setBarcodeHeight}
                    showPrettyName={showPrettyName}
                    showBarcodeText={showBarcodeText}
                    setShowPrettyName={setShowPrettyName}
                    setShowBarcodeText={setShowBarcodeText}
                    prepareToDelete={prepareToDelete}
                    pushFieldToArray={pushFieldToArray}
                  />
                  <div className={styles.panelEditArea}>
                    <div
                      className={styles.leftSide}
                      onClick={() => {
                        setDefaultSettings(), setIsDropdownOptionsOpen(false);
                      }}
                    >
                      <FieldArray name="fields">
                        {({ push, remove }) => {
                          {
                            onPush = push;
                          }
                          {
                            onRemove = remove;
                          }
                          return (
                            <div
                              className={styles.outerContainer}
                              style={outerContainerStyle}
                            >
                              <div
                                id="container"
                                style={editAreaStyle}
                                className={classNames(
                                  (isSystem || isTrialTracker) &&
                                    styles.notClickable
                                )}
                              >
                                {formik?.values?.fields &&
                                  formik.values.fields.map((field, index) => (
                                    <LabelTemplateField
                                      key={index}
                                      fieldItem={field}
                                      value={`fields.${index}.name`}
                                      axisX={field?.style.axisXY.x}
                                      axisY={field?.style.axisXY.y}
                                      name={field?.name}
                                      prettyName={field?.prettyName}
                                      showPrettyName={field?.showPrettyName}
                                      showBarcodeText={
                                        field?.style?.showText
                                          ? field?.style?.showText
                                          : false
                                      }
                                      type={field.type}
                                      measureUnit={
                                        fullTemplate.style.measureUnit
                                      }
                                      barcodeHeight={
                                        field?.style?.dimension?.height &&
                                        field?.style?.dimension?.height
                                      }
                                      fontSize={getFieldFontSize(field)}
                                      fontFamily={field?.style?.font?.family}
                                      fontWeight={field?.style?.font?.weight}
                                      index={index}
                                      remove={remove}
                                      inputValue={inputValue}
                                      activeField={activeField}
                                      setActiveField={setActiveField}
                                      setActiveFieldIndex={setActiveFieldIndex}
                                      setActiveFieldAxisX={setActiveFieldAxisX}
                                      setActiveFieldAxisY={setActiveFieldAxisY}
                                      setInputValue={setInputValue}
                                      setActiveImageDimensions={
                                        setActiveImageDimensions
                                      }
                                      setActiveFieldPosition={
                                        setActiveFieldPosition
                                      }
                                      setActiveFieldWrap={setActiveFieldWrap}
                                      setFontBold={setFontBold}
                                      setIsDropdownOptionsOpen={
                                        setIsDropdownOptionsOpen
                                      }
                                      formik={formik}
                                      getImageUrl={getImageUrl}
                                      fullTemplate={fullTemplate}
                                    />
                                  ))}
                              </div>
                            </div>
                          );
                        }}
                      </FieldArray>
                    </div>
                  </div>
                </>
              </FormikProvider>
            </div>
          </div>
        </div>
        <div
          className={classNames(
            styles.buttonsBlock,
            isTrialTracker && styles.hideElement
          )}
        >
          <Button
            label="Save"
            type="submit"
            icon="pi pi-check"
            autoFocus
            disabled={
              !(formik.isValid && formik.dirty) &&
              isEqual(fullTemplate, initialFullTemplate)
            }
          />
          <Button
            className={classNames('p-button-secondary')}
            label="Cancel"
            type="button"
            icon="pi pi-times"
            disabled={!showSystemTemplates && !(formik.isValid && formik.dirty)}
            onClick={() => confirmDiscard(formik.values, formik.dirty, accept)}
          />
        </div>
      </form>
      <DeleteDialog
        activeField={activeField}
        deleteField={deleteField}
        deleteFieldDialog={deleteFieldDialog}
        hideDeleteFieldDialog={hideDeleteFieldDialog}
        setDeleteFieldDialog={setDeleteFieldDialog}
      />
      {addNewFieldDialog && (
        <AddNewFieldDialog
          addCustomFieldOption={addCustomFieldOption}
          addNewFieldDialog={addNewFieldDialog}
          setAddNewFieldDialog={setAddNewFieldDialog}
          hideAddNewFieldDialog={hideAddNewFieldDialog}
          updateTemplate={updateTemplate}
          templateInfo={formik.values}
          fieldTypeOptions={fieldTypeOptions}
          templateId={currentTemplateId}
          isTrialTracker={isTrialTracker}
          setShowPrettyName={setShowPrettyName}
        />
      )}
    </div>
  );
};
export default LabelTemplateEditor;
