import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Toast } from 'primereact/toast';
import AdminMenu from '../AdminMenu/AdminMenu';
import CompanyForm from './CompanyForm';
import LeftBar from '../../LeftBar/LeftBar';

const CompanyInfo = () => {
  const [messageIsActive, setMessageIsActive] = useState(false);

  const toast = useRef(null);
  const isActiveSubscription = useSelector(
    (state) => state.isActiveSubscription.isActive
  );

  const showError = () => {
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail:
        'No active subscriptions. Please, click "Billing Details" to proceed',
      sticky: true,
    });
  };

  useLayoutEffect(() => {
    return () => {
      setMessageIsActive(false);
    };
  }, []);

  useEffect(() => {
    !isActiveSubscription && !messageIsActive && setMessageIsActive(true);
  }, [isActiveSubscription]);

  useEffect(() => {
    messageIsActive && showError();
  }, [messageIsActive]);

  return (
    <>
      <Toast ref={toast} />;
      <LeftBar>
        <AdminMenu />
      </LeftBar>
      <CompanyForm />
    </>
  );
};

export default CompanyInfo;
