import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { Divider } from 'primereact/divider';
import { Captcha } from 'primereact/captcha';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputMask } from 'primereact/inputmask';
import { InputTextarea } from 'primereact/inputtextarea';
import { getInitialFormInfo } from '../utils';
import { urls, useRequest } from '../../Common/ApiServices';
import PromptIfDirty from '../../Common/PromptIfDirty';
import { contactUsFormSchema } from './contactUsFormValidation';
import { errorCodes, links } from '../../Common/globalConstants';
import styles from './ContactUsForm.module.scss';

const ContactUsPage = ({ profileData }) => {
  const [isVerified, setIsVerified] = useState(false);

  const { error, sendRequest } = useRequest({
    checkTokenExp: false,
  });

  const toast = useRef(null);
  const history = useHistory();

  const responseFailed = (message) => {
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail: message,
      life: 5000,
    });
  };
  const requestSuccessful = () => {
    return toast.current.show({
      severity: 'success',
      summary: 'Successful',
      detail: 'Your request was sent. You will be redirected to home page.',
      life: 5000,
    });
  };

  const handleResponse = (res) => {
    setIsVerified(res.response);
  };

  const formik = useFormik({
    initialValues: getInitialFormInfo(
      profileData?.email,
      profileData?.firstName,
      profileData?.lastName
    ),
    enableReinitialize: true,
    validationSchema: contactUsFormSchema,
    onSubmit: async (values) => {
      const newSupportParams = {
        email: values.email || '',
        name: values.name || '',
        companyName: values.companyName,
        phoneNumber: values.phoneNumber || null,
        subject: values.subject,
        message: values.message,
      };

      const requestData = {
        url: urls.SEND_SALES_CONTACT_SUPPORT_EMAIL,
        method: 'POST',
        data: newSupportParams,
      };

      const response = await sendRequest(requestData);
      if (response) {
        requestSuccessful();
        setTimeout(() => history.push(links.HOME), 5000);
      }
      return response;
    },
  });

  useEffect(() => {
    if (
      formik.errors?.employee?.email === errorCodes.INVALID_EMAIL_FORMAT.code
    ) {
      responseFailed(errorCodes.INVALID_EMAIL_FORMAT.text);
    } else {
      error && responseFailed(errorCodes.DEFAULT_MESSAGE.text);
    }
  }, [error, formik.errors]);

  return (
    <div className={styles.contactUsForm}>
      <Toast ref={toast} />
      <PromptIfDirty dirty={formik.dirty} />
      <form className="p-shadow-24" onSubmit={formik.handleSubmit}>
        <div className="p-fluid">
          <h2>Contact Us</h2>
          <Divider />
          <h4>How can we help you?</h4>
          <div className="p-field p-grid p-jc-center">
            <label htmlFor="email" className="p-col-12 p-md-4 p-text-bold">
              Email*
            </label>
            <div className="p-col-12 p-md-8">
              <InputText
                className={
                  formik.errors.email && formik.touched.email
                    ? 'p-invalid'
                    : null
                }
                id="email"
                type="text"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                aria-describedby="email-invalid"
              />
              {formik.errors.email && formik.touched.email ? (
                <small id="email-invalid" className="p-error p-d-block">
                  {formik.errors.email}
                </small>
              ) : null}
            </div>
          </div>
          <div className="p-field p-grid p-jc-center">
            <label htmlFor="firstName" className="p-col-12 p-md-4 p-text-bold">
              Name*
            </label>
            <div className="p-col-12 p-md-8">
              <InputText
                id="name"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values?.name}
                aria-describedby="name-invalid"
                className={
                  formik.errors.name && formik.touched.name ? 'p-invalid' : null
                }
              />
              {formik.touched.name && formik.errors.name && (
                <small id="name-invalid" className="p-error p-d-block">
                  {formik.errors.name}
                </small>
              )}
            </div>
          </div>
          <div className="p-field p-grid p-jc-center">
            <label
              htmlFor="companyName"
              className="p-col-12 p-md-4 p-text-bold"
            >
              Company Name*
            </label>
            <div className="p-col-12 p-md-8">
              <InputText
                className={
                  formik.errors.companyName && formik.touched.companyName
                    ? 'p-invalid'
                    : null
                }
                id="companyName"
                type="text"
                onBlur={formik.handleBlur}
                value={formik.values.companyName}
                onChange={formik.handleChange}
                aria-describedby="companyName-invalid"
              />
              {formik.errors.companyName && formik.touched.companyName ? (
                <small id="companyName-invalid" className="p-error p-d-block">
                  {formik.errors.companyName}
                </small>
              ) : null}
            </div>
          </div>
          <div className="p-field p-grid p-jc-center">
            <label htmlFor="phoneNumber" className="p-col-12 p-md-4">
              Phone Number
            </label>
            <div className="p-col-12 p-md-8">
              <InputMask
                className={
                  formik.errors.phoneNumber && formik.touched.phoneNumber
                    ? 'p-invalid'
                    : null
                }
                id="phoneNumber"
                mask="(999) 999-9999"
                placeholder="(999) 999-9999"
                onBlur={formik.handleBlur}
                value={formik.values?.phoneNumber}
                onChange={formik.handleChange}
                aria-describedby="phone-invalid"
              />
              {formik.errors.phoneNumber && formik.touched.phoneNumber ? (
                <small id="phone-invalid" className="p-error p-d-block">
                  {formik.errors.phoneNumber}
                </small>
              ) : null}
            </div>
          </div>
          <div className="p-field p-grid p-jc-center">
            <label htmlFor="lastName" className="p-col-12 p-md-4 p-text-bold">
              Subject*
            </label>
            <div className="p-col-12 p-md-8">
              <InputText
                id="subject"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values?.subject}
                aria-describedby="subject-invalid"
                className={
                  formik.errors.subject && formik.touched.subject
                    ? 'p-invalid'
                    : null
                }
              />
              {formik.touched.subject && formik.errors.subject && (
                <small id="lastName-invalid" className="p-error p-d-block">
                  {formik.errors.subject}
                </small>
              )}
            </div>
          </div>
          <div className="p-field p-grid p-ai-start">
            <label htmlFor="note" className="p-col-12 p-md-4 p-text-bold">
              Message*
            </label>
            <div className="p-col-12 p-md-8">
              <InputTextarea
                id="message"
                type="text"
                rows="4"
                onChange={formik.handleChange}
                aria-describedby="message-invalid"
                value={formik.values.message}
              />
              {formik.errors.message && (
                <small id="note-invalid" className="p-error p-d-block">
                  {formik.errors.message}
                </small>
              )}
            </div>
          </div>
          <div
            className={classNames(
              'p-field p-grid p-ai-start',
              styles.leftPadding,
              styles.leftOffset
            )}
          >
            <Captcha
              siteKey={process.env.REACT_APP_CAPTCHA_KEY}
              onResponse={handleResponse}
              size="normal"
            />
          </div>
        </div>
        <div className="p-grid ">
          <div className={classNames('p-col-8', styles.leftOffset)}>
            <Button
              className={styles.submitButton}
              label="Send Request"
              type="submit"
              disabled={!formik.dirty || !formik.isValid || !isVerified}
            />
          </div>
          <div className={classNames('p-col-8', styles.leftOffset)}>
            *-Required fields. All information submitted is confidential.
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactUsPage;
