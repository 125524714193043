import { all, put } from 'redux-saga/effects';
import * as actions from '../profileFieldGroups/actions';
import { urls, makeRequest } from '../../components/Common/ApiServices';

export function* getProfileFieldGroupsSaga(action) {
  yield put(actions.profileFieldGroupsRequest());
  const actionData = action.actionData;
  const groupsData = {
    url: urls.PROFILE_FIELD_GROUPS,
    method: 'GET',
    data: {},
  };

  try {
    let [groups] = yield all([makeRequest(groupsData, actionData)]);
    groups = groups.data.sort((a, b) => (a.name < b.name ? -1 : 1));

    yield put(actions.profileFieldGroupsSuccess(groups));
  } catch (error) {
    console.log('error', error);
    yield put(actions.profileFieldGroupsFail(error.response));
  }
}
