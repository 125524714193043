import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Toast } from 'primereact/toast';
import HistoryList from '../HistoryList/HistoryList';
import HistoryFilters from '../HistoryFilters/HistoryFilters';
import LeftBar from '../../../../components/LeftBar/LeftBar';
import { urls, useRequest } from '../../../Common/ApiServices';
import { getInitialHistoryParams } from '../constants';
import { getDateWithoutOffset } from '../../../Tasks/utils';
import {
  dateFormat,
  timeFormat,
  errorCodes,
} from '../../../Common/globalConstants';
import { formatFilterDataToIsoString } from '../../../Common/utils';
import { getHistoryOptions } from '../../../../reduxStore/historyOptions/actions';
import { isAuth } from '../../../../auth/auth-service';
import { useAuth0 } from '@auth0/auth0-react';
import isEqual from 'lodash/isEqual';

const HistoryPage = (props) => {
  const [filters, setFilters] = useState(null);
  const [historyItems, setHistoryItems] = useState([]);
  const [historyParams, setHistoryParams] = useState(
    getInitialHistoryParams(props.match.params.id)
  );
  const [controlHistoryParams, setControlHistoryParams] = useState({});
  const [totalRecords, setTotalRecords] = useState(null);

  const plants = useSelector((state) => state.historyOptions.plants);
  const products = useSelector((state) => state.historyOptions.products);
  const sections = useSelector((state) => state.historyOptions.sections);

  const { getAccessTokenSilently, logout } = useAuth0();

  const toast = useRef(null);
  const { error, isLoading, sendRequest } = useRequest({});
  const dispatch = useDispatch();

  const defaultDateFormat = useSelector(
    (state) => state?.profileInfo?.dateFormat?.datePattern
  );
  const defaultFirstDay = useSelector(
    (state) => state?.profileInfo?.firstDayOfWeek?.day
  );

  const dateFormatPattern = useMemo(
    () =>
      historyItems.length
        ? historyItems[0]?.dateFormat.datePattern
        : defaultDateFormat,
    [defaultDateFormat, historyItems]
  );

  const firstDay = useMemo(
    () =>
      historyItems.length
        ? historyItems[0]?.firstDayOfWeek.day
        : defaultFirstDay,
    [defaultFirstDay, historyItems]
  );

  const actionData = {
    logout: logout,
    dispatch: dispatch,
    isAuthenticated: isAuth(),
    getAccessTokenSilently: getAccessTokenSilently,
    trialId: props.match.params.id,
  };

  const fetchHistory = async () => {
    setControlHistoryParams(historyParams);
    const requestData = {
      url: urls.SEARCH_APPLICATION_HISTORY,
      method: 'POST',
      data: historyParams,
    };
    const response = await sendRequest(requestData);

    const historyItems = response.data.results.map((item) => ({
      ...item,
      appDate: moment(getDateWithoutOffset(item.task?.endDate)).format(
        dateFormat[item.dateFormat.datePattern]
      ),
      appTime: moment(getDateWithoutOffset(item.task?.endDate)).format(
        timeFormat[item.dateFormat.datePattern]
      ),
    }));
    setHistoryItems(historyItems);
    setTotalRecords(response?.data?.page?.total);

    return response;
  };

  const updateFilters = useCallback((newFilters) => {
    if (!newFilters) {
      return null;
    } else {
      console.log('selected plants ', newFilters.selectedPlants);
      const newPlants = newFilters?.selectedPlants
        ? newFilters?.selectedPlants?.map((plant) => plant.id)
        : null;
      const newProducts = newFilters?.selectedProducts
        ? newFilters?.selectedProducts?.map((product) => product.id)
        : null;
      const newSections = newFilters?.selectedSections
        ? newFilters?.selectedSections?.map((section) => section.id)
        : null;
      const newAppDate = newFilters?.appDate;

      setHistoryParams((oldParams) => {
        const { navigation } = oldParams;
        const trial = oldParams.query.trial;
        const newQuery = {
          trial: trial,
          ...(newPlants && { plant: { id: { in: newPlants } } }),
          ...(newProducts && { product: { id: { in: newProducts } } }),
          ...(newSections && { section: { id: { in: newSections } } }),
          ...(newAppDate && {
            task: { endDate: { from: newAppDate[0], to: newAppDate[1] } },
          }),
        };
        return { navigation: navigation, query: newQuery };
      });
    }
  }, []);

  const onApplyNewFilters = (
    appDate,
    selectedPlants,
    selectedProducts,
    selectedSections
  ) => {
    setFilters({
      appDate: formatFilterDataToIsoString(appDate),
      selectedPlants:
        selectedPlants && selectedPlants.length > 0 ? selectedPlants : null,
      selectedProducts:
        selectedProducts && selectedProducts.length > 0
          ? selectedProducts
          : null,
      selectedSections:
        selectedSections && selectedSections.length > 0
          ? selectedSections
          : null,
    });
  };
  const responseFailed = (message = errorCodes.DEFAULT_MESSAGE.text) => {
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail: message,
      life: 5000,
    });
  };

  useEffect(() => {
    !isEqual(historyParams, controlHistoryParams) && fetchHistory();
  }, [historyParams]);

  useEffect(() => {
    dispatch(getHistoryOptions(actionData));
  }, []);

  useEffect(() => {
    updateFilters(filters);
  }, [filters]);

  useEffect(() => {
    error && responseFailed();
  }, [error]);

  return (
    <>
      <Toast ref={toast} />
      <LeftBar>
        <HistoryFilters
          applyNewFilters={onApplyNewFilters}
          plants={plants}
          products={products}
          sections={sections}
          dateFormatPattern={dateFormatPattern}
          firstDay={firstDay}
        />
      </LeftBar>
      <HistoryList
        historyItems={historyItems}
        isLoading={isLoading}
        totalRecords={totalRecords}
        setHistoryParams={setHistoryParams}
        trialId={props.match.params.id}
        historyParams={historyParams}
      />
    </>
  );
};

export default HistoryPage;
