import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import merge from 'deepmerge';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { ProgressBar } from 'primereact/progressbar';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { useAuth0 } from '@auth0/auth0-react';
import classNames from 'classnames';
import { getCropOptions } from '../../../../reduxStore/crop/actions';
import {
  getAuthConfig,
  checkBlocked,
  isAuth,
} from '../../../../auth/auth-service';
import BreadCrumb from '../../../BreadCrumb/BreadCrumb';
import NewCropForm from '../NewCrop/NewCropForm';
import CropBatchForm from '../CropBatchForm/CropBatchForm';
import PrintCropLabelsForm from '../PrintCropLabels/PrintCropLabelsForm';
import {
  checkSubscription,
  getExportURL,
  getFormattedDate,
  getImportErrorMessage,
} from '../../../Common/utils';
import ShowDetails from '../../../Common/showDetails/showDetails';
import { exportCropsParams } from '../../constants';
import { modals } from '../constants';
import { dateFormat, showDetailsFields } from '../../../Common/globalConstants';
import styles from './TrialCropList.module.scss';

const TrialCropList = () => {
  const { id } = useParams();
  const trialName = useLocation();
  const initialCropParams = {
    query: {
      trial: {
        id: {
          in: [id],
        },
      },
    },
    navigation: {
      sort: [
        {
          key: 'plantName',
          order: 'ASC',
        },
      ],
      page: {
        from: 0,
        size: 20,
      },
    },
  };

  const [crops, setCrops] = useState([]);
  const [loading, setLoading] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [cropParams, setCropParams] = useState(initialCropParams);
  const [selectedCrops, setSelectedCrops] = useState([]);
  const [sort, setSort] = useState({ field: 'plantName', order: 1 });
  const [pagination, setPagination] = useState({ first: 0, rows: 20 });
  const [totalRecords, setTotalRecords] = useState(null);
  const [globalSearch, setGlobalSearch] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [displayModal, setDisplayModal] = useState('');
  const [batchIsUpdated, setBatchIsUpdated] = useState(false);
  const [cropIsCreated, setCropIsCreated] = useState(false);
  const [cropToDelete, setCropToDelete] = useState(null);
  const [deleteCropDialog, setDeleteCropDialog] = useState(false);
  const [cropIsDeleted, setCropIsDeleted] = useState(false);
  const [frozenWidth, setFrozenWidth] = useState(320);
  const [showCancelButton, setShowCancelButton] = useState(false);
  const [cropsUpload, setCropsUpload] = useState({
    inProgress: false,
    finished: false,
  });

  const fileUploadRef = useRef(null);
  const tableRef = useRef(null);
  const toastBC = useRef(null);
  const toast = useRef(null);
  const isInitialMount = useRef(true);

  const { logout, getAccessTokenSilently } = useAuth0();

  const dispatch = useDispatch();

  const history = useHistory();

  const potSizes = useSelector((state) => state.crop.potSizes);
  const startForms = useSelector((state) => state.crop.startForms);
  const plants = useSelector((state) => state.crop.plants);
  const sections = useSelector((state) => state.crop.sections);
  const isTrialTracker = useSelector((state) => state.isTrialTracker);
  const cropError = useSelector((state) => state.crop.error);

  const showElements = isTrialTracker ? ['none', ''] : ['inline-flex', ''];

  const requestFailed = (
    errorMessage = 'Something went wrong.',
    sticky = false
  ) => {
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail: errorMessage,
      ...(sticky ? { sticky: true } : { life: 5000 }),
    });
  };

  const importRequestFailed = (error) => {
    toastBC.current.show({
      severity: 'error',
      sticky: true,
      content: (
        <div className={styles.errorsTable} style={{ flex: '1' }}>
          <div className={styles.infoBlock}>
            <i
              className="pi pi-exclamation-triangle"
              style={{ fontSize: '3rem' }}
            />
            <div className={styles.infoMessage}>Check the following errors</div>
          </div>
          {getImportErrorMessage(error)}
        </div>
      ),
    });
  };

  const requestSuccessful = (message = '') => {
    toast.current.show({
      severity: 'success',
      summary: 'Success',
      detail: message,
      life: 5000,
    });
    setBatchIsUpdated(false);
  };

  const redirectToTrialForm = () => {
    history.push(`/trial/${id}`);
  };

  const showConfirm = () => {
    toastBC.current.show({
      severity: 'warn',
      sticky: true,
      content: (
        <div className="flex flex-column" style={{ flex: '1' }}>
          <div className="text-center">
            <i
              className="pi pi-exclamation-triangle"
              style={{ fontSize: '3rem' }}
            />
            <h4>You should choose default template</h4>
            <p>Confirm to proceed</p>
          </div>
          <div className="grid p-fluid">
            <div className="col-6">
              <Button
                type="button"
                label="Yes"
                className="p-button-success"
                onClick={redirectToTrialForm}
              />
            </div>
          </div>
        </div>
      ),
    });
  };

  const fetchCrops = async () => {
    try {
      const config = await getAuthConfig(true, logout, getAccessTokenSilently);
      const response = await axios.post(
        `${config.apiUrl}/search-crops`,
        cropParams,
        config
      );
      const crops = await response.data.results.map((rawCrop) => ({
        id: rawCrop.crop.id,
        plantName: rawCrop.plant.name,
        cropNumber: rawCrop.crop.number,
        plantWeek: rawCrop.crop.plantWeek,
        plantDate: getFormattedDate(
          rawCrop.crop?.plantDate,
          dateFormat[rawCrop.crop?.dateFormat?.datePattern]
        ),
        section: rawCrop.section.name,
        quantity: rawCrop.crop.quantity,
        lastLogDate: getFormattedDate(
          rawCrop.lastLog?.createDate,
          dateFormat[rawCrop.lastLog?.dateFormat?.datePattern]
        ),
        lotNumber: rawCrop.crop.lotNumber,
        potSize: rawCrop.crop.potSize && rawCrop.crop.potSize.name,
        plantsPerPot: rawCrop.crop.plantsPerPot,
        breeder: rawCrop.breeder?.name,
        location: rawCrop.location?.name,
        startWeek: rawCrop.crop.startWeek,
        startForm: rawCrop.crop.startForm && rawCrop.crop.startForm.name,
        transplantWeek: rawCrop.crop.transplantWeek,
        spaceWeek1: rawCrop.crop.spaceWeek1,
        spaceWeek2: rawCrop.crop.spaceWeek2,
        note: rawCrop.crop.note,
      }));

      setCrops(crops);
      setTotalRecords(response.data.page.total);
      setCropIsDeleted(false);
      setCropIsCreated(false);
    } catch (error) {
      checkBlocked(error, logout);
      checkSubscription(error, dispatch);
    } finally {
      setLoading(false);
    }
  };

  const exportCrops = async () => {
    const crops = selectedCrops.length
      ? selectedCrops.map((crop) => {
          return crop.id;
        })
      : null;

    try {
      setExporting(true);
      const config = await getAuthConfig(true, logout, getAccessTokenSilently);
      config.responseType = 'blob';
      const response = await axios.post(
        `${config.apiUrl}/export-crops`,
        exportCropsParams(id, crops),
        config
      );

      const contentType = response.headers['content-type'];
      const contentDisposition = response.headers['content-disposition'];

      const trialName = localStorage.getItem('trialName').replace(' ', '_');
      const dayToday = moment().format('YYYY-MM-DD_HH:mm:ss');
      const fileName =
        contentDisposition?.slice(contentDisposition.indexOf('=') + 1) ||
        `${trialName}_${dayToday}.zip`;
      const link = document.createElement('a');
      link.href = getExportURL([response.data], { type: contentType });
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      setExporting(false);
    } catch (error) {
      const parsedError =
        error.response.data && (await new Response(error.response.data).text());

      const newError = parsedError && {
        response: { data: JSON.parse(parsedError) },
      };

      setExporting(false);
      checkBlocked(newError, logout);
      checkSubscription(newError, dispatch);
    }
  };

  const onSort = ({ sortField, sortOrder }) => {
    setSort({ field: sortField, order: sortOrder });
  };

  const onPageSelect = ({ first, rows }) => {
    setPagination({ first, rows });
  };

  const onCropSelect = (rowData) => {
    const selectedCrop = crops.filter(
      (crop) => crop.id === rowData.target.id
    )[0];
    const cropInfoPage = 'crop';
    const cropName = selectedCrop.plantName;
    const cropId = selectedCrop.id;
    history.push(`/${cropInfoPage}/${cropId}`);
    localStorage.setItem('cropName', cropName);
    localStorage.setItem('cropId', cropId);
  };

  const numberBodyTemplate = (rowData) => {
    return (
      <button
        id={rowData.id}
        className={styles.linkStyle}
        onClick={(rowData) => onCropSelect(rowData)}
      >
        {rowData.cropNumber}
      </button>
    );
  };

  const sortFunc = () => {
    return tableRef?.current?.props.value || crops;
  };

  const debouncedGlobalSearch = useRef(
    debounce((value) => setGlobalSearch(value), 500)
  ).current;

  const onGlobalSearch = (event) => {
    debouncedGlobalSearch(event.target.value);
  };

  const actionData = {
    id: id,
    logout: logout,
    dispatch: dispatch,
    isAuthenticated: isAuth(),
    getAccessTokenSilently: getAccessTokenSilently,
  };

  const onNewCropCreate = () => {
    dispatch(getCropOptions(actionData));
    setDisplayModal(modals.CROP);
  };

  const onCropsPrint = () => {
    setDisplayModal(modals.PRINT);
  };

  const onBatchUpdate = () => {
    dispatch(getCropOptions(actionData));
    setDisplayModal(modals.BATCH);
  };

  const onCropsUpload = async (event) => {
    setCropsUpload({ inProgress: true, finished: false });
    setShowCancelButton(false);

    try {
      const config = await getAuthConfig(true, logout, getAccessTokenSilently);
      const bodyFormData = new FormData();
      bodyFormData.append('trialId', id);
      bodyFormData.append('file', event.files[0]);
      const response = await axios.post(
        `${config.apiUrl}/import-crops`,
        bodyFormData,
        config
      );
      if (response) {
        setCropsUpload({ inProgress: false, finished: true });
        fetchCrops();
      }
    } catch (error) {
      setCropsUpload({ inProgress: false, finished: false });
      checkBlocked(error, logout);
      checkSubscription(error, dispatch);
      if (error.response.data.errors) {
        importRequestFailed(error.response.data.errors);
      } else if (error.response.data.error) {
        requestFailed();
      }
    }
  };

  const header = (
    <div
      className={styles.tableHeader}
      style={{ justifyContent: showElements[1] }}
    >
      <div className={styles.tableLabel}>
        <Button
          className="p-button-raised"
          label="New Crop"
          icon="pi pi-plus"
          style={{ display: showElements[0] }}
          onClick={() => onNewCropCreate()}
        />
        <FileUpload
          className={classNames('p-mr-2 p-d-inline-block')}
          ref={fileUploadRef}
          mode="basic"
          accept=".csv,.xls,.xlsx"
          maxFileSize={1000000}
          label="Import"
          chooseLabel="Import Crops"
          customUpload
          uploadHandler={onCropsUpload}
          onSelect={() => setShowCancelButton(true)}
        />
        {showCancelButton && (
          <Button
            style={{ background: 'transparent', display: 'inline-flex' }}
            onClick={() => {
              fileUploadRef.current.clear();
              setShowCancelButton(false);
            }}
            className="p-button p-button-rounded p-button-danger p-button-outlined p-button-icon-only"
            icon="pi pi-times"
          />
        )}
        <Button
          className="p-button-primary p-button-raised"
          label="Update"
          disabled={!selectedCrops.length}
          onClick={() => onBatchUpdate()}
        />
        <Button
          className="p-button-raised"
          label="Print Crop Labels"
          onClick={() => onCropsPrint()}
          disabled={!selectedCrops.length}
        />
      </div>
      <div>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onInput={(e) => onGlobalSearch(e)}
            placeholder="Search..."
          />
        </span>
        <Button
          label="Export"
          icon="pi pi-upload"
          style={{ display: showElements[0] }}
          className={classNames(
            'p-button-help p-button-raised',
            styles.exportButton
          )}
          onClick={exportCrops}
        />
      </div>
    </div>
  );

  const footer = () => {
    if (!totalRecords) {
      let emptyMessage = '';
      if (totalRecords === 0) {
        emptyMessage = 'No crops conform to filters.';
        if (isEqual(initialCropParams, cropParams)) {
          emptyMessage = 'You don’t have crops yet. Please create a crop.';
        }
      }
      return (
        <div className="generic-list-message">
          <h3 className="p-text-center">{emptyMessage}</h3>
        </div>
      );
    } else {
      return (
        <Paginator
          rows={pagination.rows}
          className="tabPaginator"
          totalRecords={totalRecords}
          first={pagination.first}
          rowsPerPageOptions={[20, 50, 100]}
          template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          onPageChange={onPageSelect}
        />
      );
    }
  };

  const confirmDeleteCrop = (crop) => {
    setCropToDelete(crop);
    setDeleteCropDialog(true);
  };

  const actionBodyTemplate = (rowData) => {
    return isTrialTracker ? null : (
      <React.Fragment>
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          onClick={() => confirmDeleteCrop(rowData)}
        />
      </React.Fragment>
    );
  };

  const hideDeleteCropDialog = () => {
    setDeleteCropDialog(false);
  };

  const deleteTrialCrop = () => {
    setDeleteCropDialog(false);
    const cropParams = {
      id: {
        in: [cropToDelete.id],
      },
    };
    getAuthConfig(true, logout, getAccessTokenSilently).then((res) => {
      axios
        .post(`${res.apiUrl}/delete-crops`, cropParams, res)
        .then(() => {
          setCropIsDeleted(true);
        })
        .catch((error) => {
          checkBlocked(error, logout);
          checkSubscription(error, dispatch);

          const errorDetail =
            error.response.data.error.code === 'REFERENCES_EXIST'
              ? `${cropToDelete.plantName} can not be removed because it has at least one trial log.`
              : 'Something went wrong.';

          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: errorDetail,
            life: 5000,
          });
        });
    });
  };

  const changeFrozenWidth = (event) => {
    if (event.column.columnKey === 'plantName') {
      setFrozenWidth(frozenWidth + event.delta);
    }
  };

  const deleteCropsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteCropDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-raised p-button-danger"
        onClick={deleteTrialCrop}
      />
    </React.Fragment>
  );

  const breadCrumbItems = [
    {
      label: 'Trials',
      command: () => {
        history.push('/trials');
      },
    },
    {
      label: localStorage.getItem('trialName') || trialName.state,
      command: () => {
        history.push(`/trial/${id}`);
      },
    },
    { label: 'Crops' },
  ];

  useEffect(() => {
    if (sort && !isInitialMount.current) {
      setCropParams((oldParams) => {
        let params = cloneDeep(oldParams);
        params.navigation.sort[0].key = sort.field;
        params.navigation.sort[0].order = sort.order === 1 ? 'ASC' : 'DESC';
        return params;
      });
    }
  }, [sort]);

  useEffect(() => {
    if (!isInitialMount.current) {
      setCropParams((oldParams) => {
        let params = cloneDeep(oldParams);
        params.navigation.page.from = pagination.first;
        params.navigation.page.size = pagination.rows;
        return params;
      });
    }
  }, [pagination]);

  useEffect(() => {
    if (cropIsCreated) {
      toast.current.show({
        severity: 'success',
        summary: 'Successful',
        detail: 'New crop was added to the trial.',
        life: 5000,
      });
      setLoading(true);
      fetchCrops();
    } else if (cropIsDeleted) {
      toast.current.show({
        severity: 'success',
        summary: 'Successful',
        detail: `Crop number ${cropToDelete.cropNumber} was deleted from the trial.`,
        life: 5000,
      });
      setLoading(true);
      fetchCrops();
    } else if (batchIsUpdated) {
      requestSuccessful('Batch of plants was updated.');
      setLoading(true);
      fetchCrops();
    }
  }, [cropIsCreated, cropIsDeleted, batchIsUpdated]);

  useEffect(() => {
    if (!isInitialMount.current) {
      setCropParams((oldParams) => {
        let params = cloneDeep(oldParams);
        if (globalSearch) {
          const searchParams = { query: { search: { is: globalSearch } } };
          params = merge(params, searchParams);
        } else if (!globalSearch && params.query.search) {
          delete params.query.search;
        }
        return params;
      });
    }
  }, [globalSearch]);

  useEffect(() => {
    setLoading(true);
    fetchCrops();
    isInitialMount.current = false;
    return () => {
      return (isInitialMount.current = false);
    };
  }, [cropParams]);

  if (exporting || cropsUpload.inProgress) {
    return (
      <div className="generic-list-message export">
        <h2 className="p-text-center">
          Please wait while the logs are exported...
        </h2>
        <ProgressBar
          mode="indeterminate"
          style={{ height: '7px', width: '25%', margin: '0 auto' }}
          color="#0097A7"
        />
      </div>
    );
  } else {
    return (
      <div className={`${styles.trialCropList} list-generic`}>
        {displayModal === modals.CROP && (
          <NewCropForm
            trialId={id}
            cropPlants={plants}
            cropSections={sections}
            startForms={startForms}
            potSizes={potSizes}
            error={cropError}
            displayModal={displayModal}
            setDisplayModal={setDisplayModal}
            setCropIsCreated={setCropIsCreated}
            cropIsCreated={cropIsCreated}
            isTrialTracker={isTrialTracker}
          />
        )}
        {displayModal === modals.BATCH && (
          <CropBatchForm
            displayModal={displayModal}
            setDisplayModal={setDisplayModal}
            selectedCrops={selectedCrops}
            setSelectedCrops={setSelectedCrops}
            setBatchIsUpdated={setBatchIsUpdated}
            trialId={id}
            startForms={startForms}
            potSizes={potSizes}
            requestFailed={requestFailed}
            requestSuccessful={requestSuccessful}
          />
        )}

        {displayModal === modals.PRINT && (
          <PrintCropLabelsForm
            setDisplayModal={setDisplayModal}
            displayModal={displayModal}
            requestFailed={requestFailed}
            showConfirm={showConfirm}
            selectedCrops={selectedCrops}
            trialId={id}
            isTrialTracker={isTrialTracker}
          />
        )}
        <BreadCrumb items={breadCrumbItems} />
        <Toast ref={toast} />
        <Toast ref={toastBC} />
        <DataTable
          ref={tableRef}
          className={classNames(
            'table-generic p-datatable-sm',
            styles.customTableStyle
          )}
          value={crops}
          header={header}
          reorderableColumns
          resizableColumns
          columnResizeMode="expand"
          selection={selectedCrops}
          onSelectionChange={(e) => setSelectedCrops(e.value)}
          selectionMode="checkbox"
          cellSelection
          dataKey="id"
          sortField={sort.field}
          sortOrder={sort.order}
          onSort={onSort}
          loading={loading}
          scrollable
          emptyMessage=""
          frozenWidth={frozenWidth + 'px'}
          onColumnResizeEnd={(event) => changeFrozenWidth(event)}
        >
          <Column
            selectionMode="multiple"
            headerStyle={{
              width: '40px',
              height: '48px',
              padding: '0 7px',
            }}
            frozen
          />
          <Column
            className="p-text-nowrap p-text-truncate"
            field="plantName"
            sortField="plantName"
            columnKey="plantName"
            header="Plant Name"
            headerStyle={{ width: '270px', height: '48px' }}
            bodyStyle={{ height: '50px' }}
            frozen
            sortFunction={sortFunc}
            sortable
          />
          <Column
            field="cropNumber"
            sortField="cropNumber"
            columnKey="cropNumber"
            header="Crop Number"
            body={numberBodyTemplate}
            headerStyle={{ width: '130px', height: '48px' }}
            bodyStyle={{ height: '50px' }}
            sortFunction={sortFunc}
            sortable
          />
          <Column
            field="plantWeek"
            sortField="cropPlantWeek"
            columnKey="plantWeek"
            header="Plant Week"
            headerStyle={{ width: '120px' }}
            sortFunction={sortFunc}
            sortable
          />
          <Column
            field="plantDate"
            sortField="cropPlantDate"
            columnKey="plantDate"
            header="Plant Date"
            headerStyle={{ width: '120px' }}
            sortFunction={sortFunc}
            sortable
          />
          <Column
            field="section"
            sortField="sectionName"
            columnKey="section"
            header="Section"
            headerStyle={{ width: '120px' }}
            sortFunction={sortFunc}
            sortable
          />
          <Column
            field="quantity"
            sortField="cropPlantedQuantity"
            columnKey="quantity"
            header="Quantity"
            headerStyle={{ width: '80px' }}
            bodyStyle={{ height: '50px' }}
          />
          <Column
            field="lastLogDate"
            sortField="lastLogDate"
            columnKey="lastLogDate"
            header="Last Log Date"
            headerStyle={{ width: '140px' }}
            sortable
            sortFunction={sortFunc}
          />
          <Column
            className="p-text-nowrap p-text-truncate"
            field="breeder"
            sortField="breederName"
            columnKey="breeder"
            header="Breeder"
            headerStyle={{ width: '130px' }}
            sortFunction={sortFunc}
            sortable
          />
          <Column
            field="lotNumber"
            sortField="cropLotNumber"
            columnKey="lotNumber"
            header="Lot Number"
            headerStyle={{ width: '120px' }}
            sortable
            sortFunction={sortFunc}
          />
          <Column
            field="location"
            sortField="locationName"
            columnKey="location"
            header="Location"
            headerStyle={{ width: '150px' }}
            sortFunction={sortFunc}
            sortable
          />
          <Column
            field="potSize"
            columnKey="potSize"
            header="Pot Size"
            sortable
            sortFunction={sortFunc}
            headerStyle={{ width: '120px' }}
          />
          <Column
            field="plantsPerPot"
            columnKey="plantsPerPot"
            header="Plants per Pot"
            headerStyle={{ width: '120px' }}
          />
          <Column
            field="startWeek"
            columnKey="startWeek"
            header="Start Week"
            headerStyle={{ width: '100px' }}
          />
          <Column
            field="startForm"
            columnKey="startForm"
            header="Start Form"
            headerStyle={{ width: '100px' }}
          />
          <Column
            field="transplantWeek"
            columnKey="transplantWeek"
            header="Transplant Week"
            headerStyle={{ width: '130px' }}
          />
          <Column
            field="spaceWeek1"
            columnKey="spaceWeek1"
            header="Space 1 Week"
            headerStyle={{ width: '110px' }}
          />
          <Column
            field="spaceWeek2"
            columnKey="spaceWeek2"
            header="Space 2 Week"
            headerStyle={{ width: '110px' }}
          />
          <Column
            field="note"
            columnKey="note"
            header="Crop Note"
            className="p-dt-tooltip p-text-nowrap p-text-truncate"
            headerStyle={{ width: '150px' }}
            body={(rowData) => ShowDetails(rowData[showDetailsFields.NOTE])}
          />
          <Column
            field="remove"
            header="Remove"
            headerStyle={{ width: '70px' }}
            body={actionBodyTemplate}
          />
        </DataTable>
        {footer()}
        <Dialog
          visible={deleteCropDialog}
          className="confirmDialog"
          style={{ width: '450px' }}
          header="Delete Confirmation"
          footer={deleteCropsDialogFooter}
          onHide={hideDeleteCropDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-info-circle p-mr-3"
              style={{ fontSize: '2rem' }}
            />
            {cropToDelete && (
              <span>
                Are you sure you want to delete crop number{' '}
                <b>{cropToDelete.cropNumber}</b> from the trial?
              </span>
            )}
          </div>
        </Dialog>
      </div>
    );
  }
};

export default TrialCropList;
