import * as yup from 'yup';

export const initialCropFormSchema = () => {
  return {
    plants: null,
    plantDate: undefined,
    section: '',
    quantity: '',
    startDate: null,
    transplantDate: null,
    spaceDate1: null,
    spaceDate2: null,
    lotNumber: '',
    plantsPerPot: '',
    note: '',
  };
};

export const cropFormSchema = yup.object().shape({
  plants: yup
    .array()
    .of(yup.object())
    .nullable()
    .required('Plant name should be provided'),
  quantity: yup
    .number()
    .typeError('Quantity should be a number.')
    .required('Quantity should be provided.')
    .min(1, 'Quantity should be positive.')
    .max(999, 'Quantity can not be more than 999'),
  section: yup.object().required('Section should be provided.'),
  plantDate: yup.date().required('Plant Date should be provided.'),
  lotNumber: yup.string().max(50, 'Maximum length exceeded.'),
  plantsPerPot: yup.string().max(3, 'Maximum length exceeded.'),
  note: yup.string().max(1024, 'Maximum length exceeded.'),
});
