import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Toast } from 'primereact/toast';
import { useRequest, urls } from '../../Common/ApiServices';
import { statuses } from '../constants';
import Menu from '../../Common/Menu/Menu';
import { errorCodes } from '../../Common/globalConstants';

const AdminMenu = () => {
  const { error, sendRequest } = useRequest({});

  const subscriptionStatus = useSelector((state) => state.subscription.status);

  const toast = useRef(null);

  const responseFailed = (message = errorCodes.DEFAULT_MESSAGE.text) => {
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail: message,
      life: 5000,
    });
  };

  const toCustomerPortal = async (event) => {
    event.preventDefault();

    const requestData = {
      url: urls.CREATE_SUBSCRIPTION_PORTAL,
      method: 'POST',
      data: {},
    };

    const response = await sendRequest(requestData);
    response && window.location.assign(response.data.url);
    return response;
  };

  const items =
    !subscriptionStatus || subscriptionStatus === statuses.CANCELED
      ? [
          {
            link: '/administration/company-info',
            value: 'Company Information',
          },
          { link: '/pricing', value: 'Billing Details' },
        ]
      : [
          {
            link: '/administration/company-info',
            value: 'Company Information',
          },
          {
            link: '/',
            value: 'Billing Details',
            onClick: (event) => toCustomerPortal(event),
          },
        ];
  useEffect(() => {
    error && responseFailed();
  }, [error]);
  return (
    <>
      <Toast ref={toast} />
      <Menu items={items} />
    </>
  );
};

export default AdminMenu;
