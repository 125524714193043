import { links } from '../Common/globalConstants';
const destinationInstruction = {
  Version: '2012-10-17',
  Id: 'PolicyForDestinationBucket',
  Statement: [
    {
      Sid: 'Permissions on objects and buckets',
      Effect: 'Allow',
      Principal: {
        AWS: 'arn:aws:iam::243479112798:role/s3-replication-role',
      },
      Action: [
        's3:List*',
        's3:GetBucketVersioning',
        's3:PutBucketVersioning',
        's3:ReplicateDelete',
        's3:ReplicateObject',
      ],
      Resource: [
        'arn:aws:s3:::destination-bucket-name',
        'arn:aws:s3:::destination-bucket-name/*',
      ],
    },
    {
      Sid: 'Permission to override bucket owner',
      Effect: 'Allow',
      Principal: {
        AWS: 'arn:aws:iam::243479112798:root',
      },
      Action: 's3:ObjectOwnerOverrideToBucketOwner',
      Resource: [
        'arn:aws:s3:::destination-bucket-name',
        'arn:aws:s3:::destination-bucket-name/*',
      ],
    },
  ],
};

const adminLinks = [
  { link: links.REPLICATION, value: 'System' },
  { link: links.LABELS, value: 'Labels' },
];

const userLinks = [{ link: '/settings/labels', value: 'Labels' }];

const templateParams = {
  navigation: {
    sort: [
      {
        key: 'name',
        order: 'ASC',
      },
    ],
    page: {
      from: 0,
      size: 20,
    },
  },
};

const sourceType = {
  SYSTEM: 'SYSTEM',
  USER: 'USER',
};

const operations = {
  UPDATE: 'update',
  CREATE: 'create',
};

export {
  destinationInstruction,
  adminLinks,
  userLinks,
  templateParams,
  sourceType,
  operations,
};
