import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Chart } from 'primereact/chart';
import { useAuth0 } from '@auth0/auth0-react';
import { randomColor } from 'randomcolor';
import axios from 'axios';
import BreadCrumb from '../../../BreadCrumb/BreadCrumb';
import { getAuthConfig, checkBlocked } from '../../../../auth/auth-service';
import { checkSubscription } from '../../../Common/utils';
import styles from './ReportList.module.scss';

const ReportList = ({ filters, setFilters }) => {
  const [reportParams, setReportParams] = useState({});
  const [report, setReport] = useState(null);

  const history = useHistory();

  const dispatch = useDispatch();
  const location = useLocation();

  const { logout, getAccessTokenSilently } = useAuth0();

  const fetchReport = async () => {
    if (!filters) {
      setReport(null);
      return null;
    }
    try {
      const config = await getAuthConfig(true, logout, getAccessTokenSilently);
      const response = await axios.post(
        `${config.apiUrl}/report-plant-comparison`,
        reportParams,
        config
      );
      let report = {
        labels: response.data.labels,
        datasets: response.data.results.map(({ name, values }) => ({
          label: name,
          data: values,
          backgroundColor: randomColor(),
        })),
      };
      setReport(report);
    } catch (error) {
      checkBlocked(error, logout);
      checkSubscription(error, dispatch);
      console.log(error);
    }
  };

  const updateFilters = (newFilters) => {
    if (!newFilters) {
      setReportParams({});
      return null;
    }

    const { plants, trialField, startWeek, endWeek } = newFilters;
    if (!plants || !trialField || !startWeek || !endWeek) {
      setFilters(null);
      return null;
    }

    let params = {
      trialField: {
        id: {
          is: trialField.id,
        },
      },
      logWeek: {
        from: startWeek.id,
        to: endWeek.id,
      },
      plant: {
        id: {
          in: plants.map((plant) => plant.id),
        },
      },
    };
    setReportParams({
      query: { ...params },
    });
  };

  useEffect(() => {
    updateFilters(filters);
  }, [filters]);

  useEffect(() => {
    fetchReport();
  }, [reportParams]);

  const breadCrumbItems = [
    {
      label: 'Trials',
      command: () => {
        history.push('/trials');
      },
    },
    {
      label: localStorage.getItem('trialName') || location.state,
      command: () => {
        history.push(`/trial/${localStorage.getItem('trialId')}`);
      },
    },
    { label: 'Reports' },
  ];
  const options = {
    plugins: {
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      },
    },
  };

  const content = () => {
    if (!report) {
      return (
        <div className={styles.message}>
          <h3 className="p-text-center">
            Please set up filters to get the report.
          </h3>
        </div>
      );
    } else {
      return <Chart type="bar" data={report} options={options} />;
    }
  };

  return (
    <div className={styles.reportList}>
      <BreadCrumb items={breadCrumbItems} />
      <div className={styles.reportForm}>{content()}</div>
    </div>
  );
};

export default ReportList;
