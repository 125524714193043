import * as actionTypes from './actions';

const initialState = null;

export const popUpReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_POP_UP:
      return action.payload;
    default:
      return state;
  }
};
