import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import BreadCrumb from '../../BreadCrumb/BreadCrumb';
import { cloneDeep, debounce } from 'lodash';
import merge from 'deepmerge';
import Footer from '../../Common/Footer/Footer';
import { initialProductParams, productFields } from '../constants';
import ProductForm from '../NewProductForm/NewProductForm';
import ShowDetails from '../../Common/showDetails/showDetails';
import { getDeleteProductParams } from '../constants';
import { urls, useRequest } from '../../Common/ApiServices';
import { showDetailsFields } from '../../Common/globalConstants';
import styles from './ProductList.module.scss';

const ProductList = ({
  products,
  totalRecords,
  productParams,
  categories,
  loading,
  classifications,
  manufacturers,
  responseFailed,
  fetchProducts,
  setProductParams,
  setProductIsCreated,
}) => {
  const [pagination, setPagination] = useState({ first: 0, rows: 20 });
  const [displayProductModal, setDisplayProductModal] = useState(false);
  const [globalSearch, setGlobalSearch] = useState(null);
  const [sort, setSort] = useState({ field: 'tradeName', order: 1 });
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productToDelete, setProductToDelete] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [frozenWidth, setFrozenWidth] = useState(420);

  const tableRef = useRef(null);

  const toast = useRef(null);

  const history = useHistory();

  const { error, isLoading, sendRequest } = useRequest({});

  const isTrialTracker = useSelector((state) => state.isTrialTracker);

  const showElements = isTrialTracker ? 'none' : 'inline-flex';

  const onPageSelect = ({ first, rows }) => {
    setPagination({ first, rows });
  };

  const onSort = ({ sortField, sortOrder }) =>
    setSort({ field: sortField, order: sortOrder });

  const sortFunc = () => tableRef?.current?.props.value || products;

  const debouncedGlobalSearch = useRef(
    debounce((value) => setGlobalSearch(value), 500)
  ).current;

  const onGlobalSearch = (event) => {
    debouncedGlobalSearch(event.target.value);
  };

  const onNewProductCreate = () => {
    setDisplayProductModal(true);
  };

  const header = (
    <div className={styles.tableHeader}>
      <div className={styles.tableLabel}>
        <Button
          className="p-button-raised"
          label="New Product"
          style={{ display: showElements }}
          icon="pi pi-plus"
          onClick={() => onNewProductCreate()}
        />
      </div>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => onGlobalSearch(e)}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  const nameBodyTemplate = (rowData, columnName) => {
    return (
      <div
        className={styles.linkStyle}
        onClick={() => onProductSelect(rowData)}
      >
        {rowData.product[columnName]}{' '}
      </div>
    );
  };

  const onProductSelect = (event) => {
    const productInfoPage = 'product';
    history.push(`/${productInfoPage}/${event.product.id}`);
    localStorage.setItem('productId', event.product.id);
  };

  const hideDeleteProductDialog = () => {
    setProductToDelete(null);
    setDeleteProductDialog(false);
  };

  const deleteProduct = async () => {
    const requestData = {
      url: urls.DELETE_PRODUCTS,
      method: 'POST',
      data: getDeleteProductParams(productToDelete[0].product.id),
    };
    const response = await sendRequest(requestData);
    if (response) {
      toast.current.show({
        severity: 'success',
        summary: 'Successful',
        detail: `Product ${productToDelete[0].product.tradeName} was deleted.`,
        life: 3000,
      });
      setProductToDelete(null);
      setDeleteProductDialog(false);
      fetchProducts();
    }
    return response;
  };

  const deleteProductDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteProductDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-raised p-button-danger"
        onClick={deleteProduct}
      />
    </>
  );

  const confirmDeleteProduct = (e, crop) => {
    e.stopPropagation();
    setProductToDelete(crop);
    setDeleteProductDialog(true);
  };

  const removeProductBodyTemplate = (rowData) => {
    return (
      <>
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          onClick={(e) => confirmDeleteProduct(e, [rowData])}
        />
      </>
    );
  };

  const removeColumn = !isTrialTracker && (
    <Column
      field="remove"
      header="Remove"
      headerStyle={{ width: '70px' }}
      body={removeProductBodyTemplate}
    />
  );

  const changeFrozenWidth = (event) => {
    if (event.column.columnKey === 'name') {
      setFrozenWidth(frozenWidth + event.delta);
    }
  };

  const breadCrumbItems = [{ label: 'Products' }];

  useEffect(() => {
    if (sort) {
      setProductParams((oldParams) => {
        const params = cloneDeep(oldParams);
        params.navigation.sort[0].key = sort.field;
        params.navigation.sort[0].order = sort.order === 1 ? 'ASC' : 'DESC';
        return params;
      });
    }
  }, [sort]);

  useEffect(() => {
    setProductParams((oldParams) => {
      let params = cloneDeep(oldParams);
      if (globalSearch) {
        const searchParams = { query: { search: { is: globalSearch } } };
        params = merge(params, searchParams);
      } else if (!globalSearch && params.query.search) {
        delete params.query.search;
      }
      return params;
    });
  }, [globalSearch]);

  useEffect(() => {
    setProductParams((oldParams) => {
      const params = cloneDeep(oldParams);
      params.navigation.page.from = pagination.first;
      params.navigation.page.size = pagination.rows;
      return params;
    });
  }, [pagination]);

  useEffect(() => {
    if (error) {
      responseFailed(
        `${productToDelete[0].product.tradeName} can not be removed because it is assigned to at least one task`
      );
      hideDeleteProductDialog();
    }
  }, [error]);

  return (
    <div className={`${styles.productList} list-generic`}>
      <Toast ref={toast} />
      <ProductForm
        displayProductModal={displayProductModal}
        setDisplayProductModal={setDisplayProductModal}
        setProductIsCreated={setProductIsCreated}
        categories={categories}
        classifications={classifications}
        manufacturers={manufacturers}
        responseFailed={responseFailed}
      />
      <BreadCrumb items={breadCrumbItems} />
      <DataTable
        ref={tableRef}
        className={classNames(
          'table-generic p-datatable-sm',
          styles.customTableStyle
        )}
        value={products}
        header={header}
        resizableColumns
        columnResizeMode="expand"
        selection={selectedProduct}
        onSelectionChange={(e) => setSelectedProduct(e.value)}
        dataKey="id"
        sortField={sort.field}
        sortOrder={sort.order}
        onSort={onSort}
        loading={isLoading || loading}
        reorderableColumns
        scrollable
        emptyMessage=""
        frozenWidth={frozenWidth + 'px'}
        onColumnResizeEnd={(event) => changeFrozenWidth(event)}
      >
        <Column
          field="product.code"
          sortField="productCode"
          reorderable
          columnKey="productCode"
          header="Product Code"
          body={(rowData) => nameBodyTemplate(rowData, productFields.CODE)}
          headerStyle={{ width: '130px', height: '48px' }}
          bodyStyle={{ height: '50px' }}
          frozen
          sortable
          sortFunction={sortFunc}
        />
        <Column
          field="product.tradeName"
          sortField="tradeName"
          reorderable
          columnKey="tradeName"
          header="Trade Name"
          body={(rowData) =>
            nameBodyTemplate(rowData, productFields.TRADE_NAME)
          }
          headerStyle={{ width: '150px', height: '48px' }}
          sortable
          ortFunction={sortFunc}
          frozen
        />
        <Column
          className="p-text-nowrap p-text-truncate"
          field="product.name"
          sortField="productName"
          reorderable
          columnKey="productName"
          header="Product Name"
          headerStyle={{ width: '220px', height: '48px' }}
          sortable
          sortFunction={sortFunc}
          frozen
        />
        <Column
          field="product.category.name"
          sortField="categoryName"
          reorderable
          columnKey="categoryName"
          header="Category"
          headerStyle={{ width: '150px', height: '48px' }}
          bodyStyle={{ height: '50px' }}
          sortable
          sortFunction={sortFunc}
        />
        <Column
          field="product.classification.name"
          sortField="classificationName"
          reorderable
          columnKey="classificationName"
          header="Classification"
          headerStyle={{ width: '150px' }}
          sortable
          sortFunction={sortFunc}
        />
        <Column
          field="product.manufacturer.name"
          sortField="manufacturerName"
          reorderable
          columnKey="manufacturerName"
          header="Manufacturer"
          headerStyle={{ width: '170px', height: '48px' }}
          sortable
          sortFunction={sortFunc}
        />
        <Column
          field="product.note"
          sortField="note"
          reorderable
          columnKey="note"
          header="Note"
          className="p-dt-tooltip p-text-nowrap p-text-truncate"
          headerStyle={{ width: '170px', height: '48px' }}
          body={(rowData) =>
            ShowDetails(rowData.product[showDetailsFields.NOTE])
          }
        />
        {removeColumn}
      </DataTable>
      <Footer
        totalRecords={totalRecords}
        initParams={initialProductParams}
        params={productParams}
        pagination={pagination}
        type="product"
        onPageSelect={onPageSelect}
      />
      <Dialog
        visible={deleteProductDialog}
        className={classNames(styles.dialog, 'confirmDialog')}
        header="Delete Confirmation"
        footer={deleteProductDialogFooter}
        onHide={hideDeleteProductDialog}
      >
        <div className="confirmation-content">
          <i className="pi pi-info-circle p-mr-3" />
          {productToDelete && (
            <span>
              Are you sure you want to delete product
              <b> {productToDelete[0].product.tradeName}</b>?
            </span>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default ProductList;
