import React, {
  forwardRef,
  Fragment,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { checkLimitExceeded } from '../../Common/utils';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { FileUpload } from 'primereact/fileupload';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { ProgressBar } from 'primereact/progressbar';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import { useFormik } from 'formik';
import { useAuth0 } from '@auth0/auth0-react';
import DatePicker from 'datepicker-special-week-numbers';
import { capitalize, checkSubscription, uploadImage } from '../../Common/utils';
import {
  employeeFormSchema,
  initialEmployeeFormSchema,
} from './employeeFormValidation';
import { urls, useRequest } from '../../Common/ApiServices';
import PromptIfDirty from '../../Common/PromptIfDirty';
import { checkBlocked, getAuthConfig } from '../../../auth/auth-service';
import styles from './EmployeeForm.module.scss';

const EmployeeForm = (props) => {
  const employeeParams = {
    query: {
      employee: {
        id: {
          is: props.employeeId,
        },
      },
    },
  };

  const initialEmployeeInfo = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    title: '',
    roles: [],
    department: '',
    status: '',
    note: '',
    picture: '',
  };

  const [employeeInfo, setEmployeeInfo] = useState(initialEmployeeInfo);
  const [isActive, setIsActive] = useState(true);
  const [initialRoles, setInitialRoles] = useState([]);
  const [isError, setIsError] = useState(false);
  const [createdImage, setCreatedImage] = useState(null);
  const [isImageDeleted, setIsImageDeleted] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [validationFail, setValidationFail] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [employeeIsInvited, setEmployeeIsInvited] = useState(false);
  const [roles, setRoles] = useState([]);

  const toast = useRef(null);
  const fileUploadRef = useRef(null);
  const isMountedRef = useRef(null);
  const { logout, getAccessTokenSilently } = useAuth0();

  const dispatch = useDispatch();

  const { error, sendRequest } = useRequest({});

  const errorCodes = {
    NOT_PROVIDED: 'This field should be provided.',
    MAX_LENGTH_EXCEEDED: 'Maximum length exceeded.',
    NOT_UNIQUE: 'This field should be unique.',
  };

  const getEmployeeInfo = async () => {
    try {
      const config = await getAuthConfig(true, logout, getAccessTokenSilently);
      const response = await axios.post(
        `${config.apiUrl}/extract-employee`,
        employeeParams,
        config
      );
      let dataEmployee = response.data.employee;
      dataEmployee.roles = dataEmployee.roles?.map((role) =>
        roles.find((role_) => role_.code === role)
      );
      if (isMountedRef.current) {
        props.setEmployeeIsUpdated(false);
        setEmployeeInfo(dataEmployee);
        setIsActive(dataEmployee.status === 'ACTIVE');
        setInitialRoles(dataEmployee.roles);
      }
    } catch (error) {
      checkBlocked(error, logout);
      checkSubscription(error, dispatch);
      console.log('error -> ', error);
    }
  };

  // ToDo: Should be replaced with useRequest hook
  const createEmployeeImage = async () => {
    try {
      const imageType = selectedImage.type.slice(6);
      const config = await getAuthConfig(true, logout, getAccessTokenSilently);
      const response = await axios.post(
        `${config.apiUrl}/create-employee-picture`,
        { picture: { type: imageType } },
        config
      );
      setCreatedImage(response.data.picture);
    } catch (error) {
      checkBlocked(error, logout);
      checkSubscription(error, dispatch);
      if (error.response.data.errors[0]?.code === 'NOT_VALID_IMAGE_TYPE') {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail:
            'Invalid image type. Supported image types are PNG, JPG, JPEG',
          life: 5000,
        });
        onTemplateRemove();
      }
      console.log('error -> ', error);
    }
  };

  const createEmployee = async (employeeParams) => {
    const requestData = {
      url: urls.CREATE_EMPLOYEE,
      method: 'POST',
      data: employeeParams,
    };
    createdImage &&
      (await uploadImage(
        setUploading,
        selectedImage.objectURL,
        createdImage.uploadUrl,
        setIsError
      ));
    const response = await sendRequest(requestData);
    if (response) {
      props.setNewEmployeeId(response.data.id);
      props.setEmployeeIsCreated(true);
    }
    return response;
  };

  // ToDo: Should be replaced with useRequest hook
  const updateEmployee = async (employeeParams) => {
    try {
      createdImage &&
        (await uploadImage(
          setUploading,
          selectedImage.objectURL,
          createdImage.uploadUrl,
          setIsError
        ));
      const config = await getAuthConfig(true, logout, getAccessTokenSilently);
      await axios.post(
        `${config.apiUrl}/update-employee`,
        employeeParams,
        config
      );
      props.setEmployeeIsUpdated(true);
    } catch (error) {
      checkBlocked(error, logout);
      checkSubscription(error, dispatch);
      props.setEmployeeIsUpdated(false);
      if (error.response.data.errors) {
        error.response.data.errors.map((err) =>
          formik.setFieldError(err.fieldName, errorCodes[err.code])
        );
      } else if (
        error?.response?.data?.error?.code === 'ENTITY_LIMIT_EXCEEDED'
      ) {
        checkLimitExceeded(dispatch, true);
      } else {
        let errorDetail = 'Something went wrong.';
        if (error.response.data.error?.code === 'CONFLICT') {
          errorDetail =
            'This employee has been updated by another user. Please refresh the page and then save your updates.';
        }
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: errorDetail,
          life: 5000,
        });
      }
    }
  };

  const accept = () => {
    onHide();
  };

  const confirm = (dirty) => {
    if (dirty || selectedImage || isImageDeleted) {
      confirmDialog({
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        message: 'Are you sure you want to discard the changes?',
        accept,
      });
    } else {
      accept();
    }
  };

  const onHide = () => {
    props.setDisplayEmployeeModal(false);
    formik.resetForm();
    setEmployeeInfo(initialEmployeeInfo);
    setSelectedImage(null);
    setCreatedImage(null);
    setIsImageDeleted(false);
    setInitialRoles([]);
    setIsActive(true);
    setEmployeeIsInvited(false);
    props.setSelectedEmployee(null);
    fileUploadRef.current.clear();
    setIsError(false);
  };

  const onTemplateSelect = (e) => {
    setSelectedImage(e.files[0]);
  };

  const onTemplateRemove = () => {
    setSelectedImage(null);
    setCreatedImage(null);
    fileUploadRef.current.clear();
  };

  const onImageRemove = () => {
    setIsImageDeleted(true);
    setSelectedImage(null);
    setCreatedImage(null);
  };

  const onValidationFail = () => {
    setValidationFail(true);
  };

  const headerTemplate = (options) => {
    const { className, chooseButton } = options;
    const progressBar = uploading && (
      <div className="p-d-flex p-jc-end">
        <h4>Uploading photo...</h4>
        <ProgressBar
          className="p-ml-5 p-as-center"
          mode="indeterminate"
          style={{ width: '350px', height: '7px' }}
        />
      </div>
    );
    return (
      <div
        className={`${className} p-jc-between`}
        style={{
          backgroundColor: 'transparent',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {chooseButton}
        {progressBar}
      </div>
    );
  };

  const itemTemplate = (file, props) => {
    return (
      <div className="p-d-flex p-ai-center p-flex-wrap">
        <div className="p-d-flex p-ai-center" style={{ width: '75%' }}>
          <img
            className={styles.employeeImage}
            alt={file.name}
            role="presentation"
            src={file.objectURL}
            width={150}
          />
          <span className="p-d-flex p-dir-col p-text-left p-ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag
          value={props.formatSize}
          severity="warning"
          className="p-px-3 p-py-2"
        />
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded p-button-danger p-ml-auto"
          onClick={() => onTemplateRemove(file, props.onRemove)}
        />
      </div>
    );
  };

  const emptyTemplate = () => {
    if (employeeInfo.picture && !isImageDeleted) {
      return (
        <div className="p-d-flex p-ai-center p-flex-wrap">
          <div className="p-d-flex p-ai-center" style={{ width: '75%' }}>
            <img
              className={styles.employeeImage}
              role="presentation"
              src={employeeInfo.picture.full}
              width={250}
            />
          </div>
          <Button
            type="button"
            icon="pi pi-times"
            className="p-button-outlined p-button-rounded p-button-danger p-ml-auto"
            onClick={onImageRemove}
          />
        </div>
      );
    }
    return (
      <div className="p-d-flex p-ai-center p-dir-col">
        <i
          className="pi pi-image p-mt-1 p-p-3"
          style={{
            fontSize: '3em',
            borderRadius: '50%',
            backgroundColor: 'var(--surface-b)',
            color: 'var(--surface-d)',
          }}
        />
        <span
          style={{ fontSize: '1em', color: 'var(--text-color-secondary)' }}
          className="p-my-2"
        >
          Drag and Drop Photo Here
        </span>
      </div>
    );
  };

  const chooseOptions = {
    icon: 'pi pi-fw pi-images',
    iconOnly: true,
    className: `custom-choose-btn p-button-rounded p-button-outlined
    ${
      (selectedImage || (employeeInfo.picture && !isImageDeleted)) &&
      'p-disabled'
    }`,
  };

  const formik = useFormik({
    initialValues: initialEmployeeFormSchema(employeeInfo),
    enableReinitialize: true,
    validationSchema: employeeFormSchema,
    onSubmit: (values) => {
      const currentImage =
        !isImageDeleted && employeeInfo.picture
          ? employeeInfo.picture?.id
          : null;
      let newEmployeeParams = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone || null,
        title: values.title || null,
        roles: !isActive
          ? initialRoles?.map((role) => role?.code)
          : values.roles?.map((role) => role?.code),
        department: values.department || null,
        status: values.status ? 'ACTIVE' : 'INACTIVE',
        note: values.note || null,
        pictureId: currentImage || createdImage?.id || null,
      };
      if (props.employeeId) {
        newEmployeeParams = {
          ...newEmployeeParams,
          ...{
            id: props.employeeId,
            versionNumber: employeeInfo.version?.number || 0,
          },
        };
        updateEmployee(newEmployeeParams);
      } else {
        delete newEmployeeParams.status;
        createEmployee(newEmployeeParams);
      }
    },
  });

  const uniqueRoleByName = (roles) => {
    let seen = {};
    return roles.filter((role) => {
      return {}.hasOwnProperty.call(seen, role?.name)
        ? false
        : (seen[role?.name] = true);
    });
  };

  const activeEmployeeCheckbox = props.employeeId && (
    <>
      <div className="p-field p-grid p-ai-start">
        <div className={`${styles.checkbox} p-field-checkbox p-offset-3`}>
          <Checkbox
            id="status"
            onChange={formik.handleChange}
            value={formik.values.status}
            checked={formik.values.status}
          />
          <label htmlFor="status">Active</label>
        </div>
      </div>
    </>
  );

  const sendInviteButton = props.employeeId && (
    <Button
      className={`${styles.button} ${
        employeeInfo.lastLoginDate !== null ||
        employeeInfo.emailVerified ||
        !isActive
          ? styles.hidden
          : null
      }`}
      label="Send Invite"
      type="button"
      icon="pi pi-send"
      disabled={employeeIsInvited}
      onClick={() => sendInvite()}
    />
  );

  // ToDo: Should be replaced with useRequest hook
  const sendInvite = async () => {
    try {
      const config = await getAuthConfig(true, logout, getAccessTokenSilently);
      await axios.post(
        `${config.apiUrl}/send-welcome-email`,
        { employeeId: props.employeeId },
        config
      );
      toast.current.show({
        severity: 'success',
        summary: 'Successful',
        detail: "Invitation was sent to the employee's email.",
        life: 5000,
      });
      setEmployeeIsInvited(true);
    } catch (error) {
      checkBlocked(error, logout);
      checkSubscription(error, dispatch);
      setEmployeeIsInvited(false);
      console.log('error -> ', error);
    }
  };

  const CustomCalendarInput = forwardRef(({ value, onClick }, ref) => (
    <Button
      label={value}
      className={`${styles.calendarButton} p-button-text p-button-plain`}
      icon="pi pi-calendar"
      iconPos="right"
      disabled
      type="button"
    />
  ));

  const employeeDates = props.employeeId && (
    <>
      <div className="p-field p-grid p-ai-start">
        <label htmlFor="lastLoginDate" className="p-col-12 p-md-3">
          Last Login Date
        </label>
        <div className="p-col-8">
          <DatePicker
            id="lastLoginDate"
            selected={formik.values.lastLoginDate}
            dateFormat={formik.values.dateFormat}
            customInput={<CustomCalendarInput />}
          />
        </div>
      </div>
      <div className="p-field p-grid p-ai-start">
        <label htmlFor="inactiveDate" className="p-col-12 p-md-3">
          Inactive Date
        </label>
        <div className="p-col-8">
          <DatePicker
            id="inactiveDate"
            selected={formik.values.inactiveDate}
            dateFormat={formik.values.dateFormat}
            customInput={<CustomCalendarInput />}
          />
        </div>
      </div>
      <div className="p-field p-grid p-ai-start">
        <label htmlFor="lastInvitationDate" className="p-col-12 p-md-3">
          Last Invitation Date
        </label>
        <div className="p-col-8">
          <DatePicker
            id="lastInvitationDate"
            selected={formik.values.lastInvitationDate}
            dateFormat={formik.values.dateFormat}
            customInput={<CustomCalendarInput />}
          />
        </div>
      </div>
    </>
  );

  useEffect(async () => {
    // ToDo: Should be replaced with useRequest hook
    const config = await getAuthConfig(true, logout, getAccessTokenSilently);
    const response = await axios.post(`${config.apiUrl}/get-roles`, {}, config);
    const roles = response.data.roles?.map((role) => ({
      code: role.name,
      name: capitalize(role.name.replace('PPT_', '').replace('_', ' ')),
    }));
    setRoles(roles);
  }, []);

  useEffect(() => {
    isMountedRef.current = true;
    if (props.employeeId) {
      getEmployeeInfo();
    }
    return () => (isMountedRef.current = false);
  }, [props.employeeId]);

  useEffect(() => {
    if (!isError && (props.employeeIsCreated || props.employeeIsUpdated)) {
      onHide();
    }
  }, [isError, props.employeeIsUpdated, props.employeeIsCreated]);

  useEffect(() => {
    if (selectedImage) {
      createEmployeeImage();
      setValidationFail(false);
    }
  }, [selectedImage]);

  useEffect(() => {
    if (validationFail) {
      setCreatedImage(null);
      setSelectedImage(null);
    }
  }, [validationFail]);

  useEffect(() => {
    if (props.employeeId) {
      setIsActive(formik.values.status);
    }
  }, [formik.values.status, props.employeeId]);
  useEffect(() => {
    if (error) {
      props.setEmployeeIsCreated(false);
      if (error.response.data.errors) {
        error.response.data.errors.map((err) =>
          formik.setFieldError(err.fieldName, errorCodes[err.code])
        );
      } else {
        let errorDetail = 'Something went wrong.';
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: errorDetail,
          life: 5000,
        });
      }
    }
  }, [error]);

  return (
    <Fragment>
      <Dialog
        className={styles.employeeFormDialog}
        header="Employee Information"
        visible={props.displayEmployeeModal}
        onHide={() => confirm(formik.dirty)}
      >
        <Toast ref={toast} />
        <PromptIfDirty dirty={formik.dirty} />
        <Tooltip
          target=".custom-choose-btn"
          content="Choose"
          position="bottom"
        />
        <Divider />
        <form onSubmit={formik.handleSubmit}>
          <div className="p-fluid">
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="firstName"
                className="p-col-12 p-md-3 p-text-bold"
              >
                First Name*
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  id="firstName"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                  aria-describedby="firstName-invalid"
                  className={formik.errors.firstName ? 'p-invalid' : null}
                />
                {formik.touched.firstName && formik.errors.firstName ? (
                  <small id="firstName-invalid" className="p-error p-d-block">
                    {formik.errors.firstName}
                  </small>
                ) : (
                  <small>Employee First Name is required.</small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="lastName" className="p-col-12 p-md-3 p-text-bold">
                Last Name*
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  id="lastName"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                  aria-describedby="lastName-invalid"
                  className={formik.errors.lastName ? 'p-invalid' : null}
                />
                {formik.touched.lastName && formik.errors.lastName ? (
                  <small id="lastName-invalid" className="p-error p-d-block">
                    {formik.errors.lastName}
                  </small>
                ) : (
                  <small>Employee Last Name is required.</small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="email" className="p-col-12 p-md-3 p-text-bold">
                Email*
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  id="email"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  aria-describedby="email-invalid"
                  className={formik.errors.email ? 'p-invalid' : null}
                />
                {formik.touched.email && formik.errors.email ? (
                  <small id="email-invalid" className="p-error p-d-block">
                    {formik.errors.email}
                  </small>
                ) : (
                  <small>Employee Email is required.</small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="phone" className="p-col-12 p-md-3">
                Phone
              </label>
              <div className="p-col-12 p-md-9">
                <InputMask
                  className={formik.errors.phone ? 'p-invalid' : null}
                  id="phone"
                  mask="(999) 999-9999"
                  placeholder="(999) 999-9999"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  aria-describedby="phone-invalid"
                />
                {formik.touched.phone && formik.errors.phone && (
                  <small id="phone-invalid" className="p-error p-d-block">
                    {formik.errors.phone}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="title" className="p-col-12 p-md-3">
                Title
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  id="title"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.title}
                  aria-describedby="title-invalid"
                  className={formik.errors.title ? 'p-invalid' : null}
                />
                {formik.touched.title && formik.errors.title && (
                  <small id="title-invalid" className="p-error p-d-block">
                    {formik.errors.title}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="department" className="p-col-12 p-md-3">
                Department
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  id="department"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.department}
                  aria-describedby="department-invalid"
                  className={formik.errors.department ? 'p-invalid' : null}
                />
                {formik.touched.department && formik.errors.department && (
                  <small id="department-invalid" className="p-error p-d-block">
                    {formik.errors.department}
                  </small>
                )}
              </div>
            </div>
            {activeEmployeeCheckbox}
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="roles" className="p-col-12 p-md-3">
                Roles
              </label>
              <div className="p-col-12 p-md-9">
                <MultiSelect
                  id="roles"
                  value={
                    !isActive
                      ? initialRoles
                      : uniqueRoleByName(formik.values.roles, name)
                  }
                  aria-describedby="roles-invalid"
                  options={roles}
                  onChange={formik.handleChange}
                  optionLabel="name"
                  display="chip"
                  disabled={!isActive}
                />
              </div>
            </div>
            {employeeDates}
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="note" className="p-col-12 p-md-3">
                Note
              </label>
              <div className="p-col-12 p-md-9">
                <InputTextarea
                  className={formik.errors.note ? 'p-invalid' : null}
                  id="note"
                  type="text"
                  rows="4"
                  onChange={formik.handleChange}
                  aria-describedby="note-invalid"
                  value={formik.values.note}
                />
                {formik.errors.note ? (
                  <small id="note-invalid" className="p-error p-d-block">
                    {formik.errors.note}
                  </small>
                ) : null}
              </div>
            </div>
          </div>
          <FileUpload
            ref={fileUploadRef}
            name="employeeImage"
            accept=".jpeg,.jpg,.png"
            maxFileSize={1000000}
            onSelect={onTemplateSelect}
            onValidationFail={onValidationFail}
            headerTemplate={headerTemplate}
            itemTemplate={itemTemplate}
            emptyTemplate={emptyTemplate}
            chooseOptions={chooseOptions}
            disabled={Boolean(
              selectedImage || (employeeInfo.picture && !isImageDeleted)
            )}
          />
          <div className="p-grid p-col-12 p-justify-end pad-r-0 margin-l-0">
            {sendInviteButton}
            <Button
              className={styles.button}
              label="Save"
              type="submit"
              disabled={Boolean(
                (!formik.dirty && !selectedImage && !isImageDeleted) ||
                  uploading ||
                  !formik.values.firstName ||
                  !formik.values.lastName ||
                  !formik.values.email ||
                  formik.errors.title ||
                  formik.errors.department ||
                  formik.errors.phone ||
                  formik.errors.note
              )}
              icon="pi pi-check"
              autoFocus
            />
            <Button
              className={`p-button-secondary ${styles.button}`}
              label="Cancel"
              type="button"
              icon="pi pi-times"
              disabled={Boolean(uploading)}
              onClick={() => confirm(formik.dirty)}
            />
          </div>
        </form>
      </Dialog>
    </Fragment>
  );
};

export default EmployeeForm;
