import React, { useState, useEffect } from 'react';
import CalendarFilter from '../../Filters/CalendarFilter';
import FilterControls from '../../Filters/FilterControls';
import MultiSelectFilter from '../../Filters/MultiSelectFilter';
import MenuDivider from '../../Common/Menu/MenuDivider';
import 'datepicker-special-week-numbers/dist/react-datepicker.css';
import { locales } from '../../Common/globalConstants';
import { getDefaultDateFormat } from '../../Common/utils';
import { getDefaultFirstDay } from '../../Common/utils';
import styles from './TrialFilters.module.scss';

const TrialFilters = (props) => {
  const [initialLocations, setInitialLocations] = useState(null);
  const [selectedStatuses, setSelectedStatuses] = useState(null);
  const [selectedLocations, setSelectedLocations] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(null);

  const defaultFirstDay = getDefaultFirstDay();

  const statuses = [
    { name: 'Pending', color: 'cornflowerblue' },
    { name: 'In Progress', color: 'green' },
    { name: 'Completed', color: 'red' },
    { name: 'Inactive', color: 'grey' },
  ];

  useEffect(() => {
    if (props.locations) {
      setInitialLocations(
        props.locations.map((location) => ({
          name: location.location?.name,
          id: location.location?.id,
        }))
      );
    }
  }, [props.locations]);

  const onResetFilters = () => {
    setSelectedStatuses(null);
    setSelectedLocations(null);
    setSelectedStartDate(null);
    props.applyNewFilters(null, null, null);
  };

  const onApplyFilters = () => {
    props.applyNewFilters(
      selectedStatuses,
      selectedLocations,
      selectedStartDate
    );
  };

  const statusTemplate = (option) => {
    return (
      <div className={styles.statusFilter}>
        <i
          className={`pi pi-circle-on ${styles.statusIcon}`}
          style={{ color: option.color }}
        />
        <div>{option.name}</div>
      </div>
    );
  };

  return (
    <div className="side-filters">
      <FilterControls
        resetFilters={onResetFilters}
        applyFilters={onApplyFilters}
      />
      <MenuDivider />
      <div className="filtersInputs">
        <MultiSelectFilter
          label="Status"
          items={statuses}
          selectedItems={selectedStatuses}
          setSelectedItems={setSelectedStatuses}
          filterName="Select Statuses"
          itemTemplate={statusTemplate}
        />
        <CalendarFilter
          label="Start Date"
          selectedDate={selectedStartDate}
          setDate={setSelectedStartDate}
          dateFormat={getDefaultDateFormat()}
          filterName="Select Start Date Range"
          locale={locales[defaultFirstDay]}
        />
        <MultiSelectFilter
          label="Location"
          items={initialLocations}
          selectedItems={selectedLocations}
          setSelectedItems={setSelectedLocations}
          filterName="Select Locations"
        />
      </div>
    </div>
  );
};

export default TrialFilters;
