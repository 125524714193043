import React, { useEffect, useRef, useState } from 'react';
import { cloneDeep } from 'lodash';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { urls, useRequest } from '../../Common/ApiServices';
import { errorCodes } from '../../Common/globalConstants';
import { getSearchCropsParams } from '../constants';
import { checkIsDirty, getSectionsAndCrops, prepareDataToSave } from '../utils';
import styles from './CropSelectionList.module.scss';

const CropSelectionList = ({
  displayCropSelectionModal,
  setDisplayCropSelectionModal,
  taskId,
  setCropParams,
  cropsUpdatedSuccessfully,
  responseFailed,
}) => {
  const [selectedCrops, setSelectedCrops] = useState({});
  const [controlSelectedCrops, setControlSelectedCrops] = useState({});
  const [extractedCrops, setExtractedCrops] = useState([]);
  const [mappedCrops, setMappedCrops] = useState({});
  const [dirty, setDirty] = useState(false);

  const treeTableRef = useRef(null);

  const { error, sendRequest } = useRequest({});

  const accept = () => {
    onHide();
  };

  const confirm = () => {
    if (dirty) {
      confirmDialog({
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        message: 'Are you sure you want to discard the changes?',
        accept,
      });
    } else {
      accept();
    }
  };

  const cropsToUpdate = () => {
    return {
      task: {
        id: {
          is: Object.keys(taskId)[0],
        },
      },
      sections: getSectionsAndCrops(mappedCrops, selectedCrops),
    };
  };

  //ToDo uncomment commented code when will implement partial checked section functionality

  const initialSelectedCrops = (data) => {
    const selected = {};
    data.forEach((item, index) => {
      // const initialPartial = !!item.crops.filter(
      //   (crop) => crop.taskCrop.selected
      // ).length;
      if (item.allCrops !== null) {
        item.allCrops &&
          (selected[index] = {
            checked: item.allCrops,
            //partialChecked: initialPartial,
          });
        item.crops.forEach((crop, cropIndex) => {
          if (crop.taskCrop.selected) {
            const name = `${index}-${cropIndex}`;
            selected[name] = {
              checked: true,
              //partialChecked: false,
            };
          }
        });
      }
    });
    setSelectedCrops(selected);
    setControlSelectedCrops(cloneDeep(selected));
  };

  const mapCropsToTreeTable = (data) => {
    const crops = data.map((section, index) => {
      return {
        key: `${index}`,
        allCrops: section?.allCrops,
        data: {
          name: section?.section?.name,
          id: section?.section?.id,
          type: 'Section',
        },
        children: section.crops.map((crop, cropIndex) => {
          return {
            key: `${index}-${cropIndex}`,
            disabled: 'true',
            data: {
              name: crop?.taskCrop?.id,
              number: crop?.taskCrop?.number,
              type: 'Crop',
              skipped: crop?.taskCrop?.skipped,
              selected: crop?.taskCrop?.selected,
              plantWeek: crop?.taskCrop?.plantWeek,
              plantName: crop?.plant?.name,
              breeder: crop?.breeder?.name,
              trial: crop?.trial?.name,
            },
          };
        }),
      };
    });
    const mappedData = { root: [...crops] };
    setMappedCrops(mappedData);
  };

  const updateCrops = async (e) => {
    e.preventDefault();

    const requestData = {
      url: urls.UPDATE_TASK_SECTIONS_CROPS,
      method: 'POST',
      data: cropsToUpdate(),
    };

    const response = await sendRequest(requestData);
    if (response) {
      cropsUpdatedSuccessfully();
    }
    setDisplayCropSelectionModal(false);
    setSelectedCrops({});
    setControlSelectedCrops({});
    setExtractedCrops([]);
    setMappedCrops({});
    setCropParams(getSearchCropsParams(Object.keys(taskId)[0]));
    return response;
  };

  const extractCrops = async () => {
    const cropsParams = {
      task: {
        id: {
          is: Object.keys(taskId)[0],
        },
      },
    };

    const requestData = {
      url: urls.EXTRACT_TASK_SECTIONS_CROPS,
      method: 'POST',
      data: cropsParams,
    };
    const response = await sendRequest(requestData);
    setExtractedCrops(response.data.sections);
    return response;
  };

  const onHide = () => {
    setDisplayCropSelectionModal(false);
    setSelectedCrops({});
    setControlSelectedCrops({});
    setExtractedCrops([]);
    setMappedCrops({});
  };

  const checkboxBodyTemplateAll = (data) => {
    let isChecked = false;
    if (data.data.type === 'Section') {
      isChecked = selectedCrops[data?.key]?.checked;
      return <Checkbox disabled={true} checked={isChecked} />;
    }
  };

  const checkboxBodyTemplate = (data) => {
    return (
      <>
        <Checkbox
          id={data.key}
          disabled={selectedCrops[data.key[0]]?.checked && data.key.length > 1}
          checked={selectedCrops[data.key]?.checked}
          onChange={(e) => prepareDataToSave(e, setSelectedCrops)}
        />
        <label htmlFor={data.key} className={styles.label}>
          {data.data.name}
        </label>
      </>
    );
  };

  const showErrorMessage = () => {
    const message =
      error?.response?.data?.error?.code ===
      errorCodes.TASK_ALREADY_COMPLETED.code
        ? errorCodes.TASK_ALREADY_COMPLETED.text
        : errorCodes.DEFAULT_MESSAGE.text;
    responseFailed(message);
  };

  useEffect(() => {
    displayCropSelectionModal && extractCrops();
  }, [displayCropSelectionModal]);

  useEffect(() => {
    extractedCrops && mapCropsToTreeTable(extractedCrops);
    extractedCrops && initialSelectedCrops(extractedCrops);
  }, [extractedCrops]);

  useEffect(() => {
    checkIsDirty(selectedCrops, controlSelectedCrops, setDirty);
  }, [selectedCrops]);

  useEffect(() => {
    if (error) {
      showErrorMessage();
    }
  }, [error]);

  const handleSubmit = () => {};

  return (
    <>
      <Dialog
        className={styles.cropSelectionDialog}
        header="Select Section/Crop"
        visible={displayCropSelectionModal}
        onHide={() => confirm()}
      >
        <Divider />
        <form onSubmit={handleSubmit}>
          <TreeTable
            ref={treeTableRef}
            className={styles.treeTable}
            value={mappedCrops.root}
            propagateSelectionUp={false}
            propagateSelectionDown={false}
            selectionKeys={selectedCrops}
            scrollable
            frozenWidth="0"
          >
            <Column
              field="name"
              header="Name"
              expander
              headerStyle={{ width: '200px' }}
              body={(rowData) => checkboxBodyTemplate(rowData)}
            />
            <Column
              field="type"
              header="Type"
              headerStyle={{ width: '150px' }}
            />
            <Column
              field="number"
              header="Number"
              headerStyle={{ width: '150px' }}
            />
            <Column
              field="isAllCrops"
              header="All Crops"
              headerStyle={{ width: '100px' }}
              body={(rowData) => checkboxBodyTemplateAll(rowData)}
            />
            <Column
              field="plantName"
              header="Plant Name"
              headerStyle={{ width: '200px' }}
            />
            <Column
              field="plantWeek"
              header="Plant Week"
              headerStyle={{ width: '200px' }}
            />
            <Column
              field="breeder"
              header="Breeder"
              headerStyle={{ width: '200px' }}
            />
            <Column
              field="trial"
              header="Trial"
              headerStyle={{ width: '200px' }}
            />
          </TreeTable>
          <div className="p-grid p-col-12 p-justify-end pad-r-0 margin-l-0">
            <Button
              className={styles.button}
              label="Save"
              type="submit"
              icon="pi pi-check"
              autoFocus
              disabled={!dirty}
              onClick={(e) => updateCrops(e)}
            />
            <Button
              className={`p-button-secondary ${styles.button}`}
              label="Cancel"
              type="button"
              icon="pi pi-times"
              onClick={() => confirm()}
            />
          </div>
        </form>
      </Dialog>
    </>
  );
};

export default CropSelectionList;
