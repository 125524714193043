export const TRIAL_INFO = 'TRIAL_INFO';
export const GET_PROFILE = 'GET_PROFILE';
export const PROFILE_REQUEST = 'PROFILE_REQUEST';
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
export const PROFILE_FAIL = 'PROFILE_FAIL';

export const trialInfo = (name, id) => ({
  type: TRIAL_INFO,
  name: name,
  id: id,
});

export const getProfile = ({
  queryRole,
  logout,
  dispatch,
  isAuthenticated,
  getAccessTokenSilently,
}) => ({
  type: GET_PROFILE,
  queryRole: queryRole,
  actionData: {
    logout: logout,
    dispatch: dispatch,
    isAuthenticated: isAuthenticated,
    getAccessTokenSilently: getAccessTokenSilently,
  },
});

export const profileRequest = () => ({ type: PROFILE_REQUEST });

export const profileSuccess = (
  locations,
  logFrequencies,
  employees,
  cropTemplates
) => ({
  type: PROFILE_SUCCESS,
  locations: locations,
  logFrequencies: logFrequencies,
  employees: employees,
  cropTemplates: cropTemplates,
});

export const profileFail = (error) => ({ type: PROFILE_FAIL, error: error });
