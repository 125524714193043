import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Dropdown } from 'primereact/dropdown';
import { Divider } from 'primereact/divider';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useFormik } from 'formik';
import { getFilteredCountries } from '../Common/utils';
import { signUpFormSchema } from './signUpFormValidation';
import PromptIfDirty from '../Common/PromptIfDirty';
import countries from 'iso3166-2-db/i18n/dispute/UN/en';
import successIcon from '../../assets/img/success_icon.png';
import styles from './SignUpPage.module.scss';

const SignUpPage = (props) => {
  const initialSignUpInfo = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    companyName: '',
    country: {},
  };

  const [isConfirmed, setIsConfirmed] = useState(false);
  const [filteredCountries, setFilteredCountries] = useState({});

  const toast = useRef(null);

  const countryList = Object.entries(filteredCountries)
    .map((country) => ({ code: country[0], name: country[1].name }))
    .sort((a, b) => (a.name < b.name ? -1 : 1));

  const errorCodes = {
    NOT_UNIQUE: 'This field should be unique.',
    BAD_CONTENT: 'Invalid value.',
    INVALID_FIELD_FORMAT: 'Invalid format.',
    IS_EMPTY: 'This field should not be empty.',
  };

  const responseFailed = (message) => {
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail: message,
      life: 5000,
    });
  };

  const formik = useFormik({
    initialValues: initialSignUpInfo,
    validationSchema: signUpFormSchema,
    onSubmit: (values, { setSubmitting }) => {
      const newSignUpParams = {
        company: {
          name: values.companyName,
          countryId: values.country.code,
          phone: values.phoneNumber,
        },
        employee: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
        },
      };
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/company-signup`,
          newSignUpParams
        )
        .then((response) => {
          onSignUp();
        })
        .catch((error) => {
          if (error.response.data.errors) {
            error.response.data.errors.map((err) =>
              formik.setFieldError(err.fieldName, errorCodes[err.code])
            );
          } else {
            responseFailed('Something went wrong.');
          }
          console.log('error -> ', error);
        })
        .finally(setSubmitting(false));
    },
  });

  const onCountryChange = (event) => {
    formik.setFieldValue('country', event.value);
  };

  const onSignUp = () => {
    setIsConfirmed(true);
  };

  const formContent = isConfirmed ? (
    <form className="p-shadow-24 p-jc-center p-as-center">
      <img alt="success_icon" src={successIcon} />
      <h3>
        Email Confirmation sent to
        <br />
        {formik.values.email}
      </h3>
      <p>Follow the instructions in the email to finish your registration.</p>
    </form>
  ) : (
    <form className="p-shadow-24" onSubmit={formik.handleSubmit}>
      <div className="p-fluid">
        <h2>Welcome to Plant Partner Trials</h2>
        <p>
          Start here by creating an account. <br /> No credit card required.
        </p>
        <div className="p-field p-grid p-jc-center">
          <label htmlFor="email" className="p-col-12 p-md-3 p-text-bold">
            Email
          </label>
          <div className="p-col-12 p-md-9">
            <InputText
              className={
                formik.errors.email && formik.touched.email ? 'p-invalid' : null
              }
              id="email"
              type="text"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              aria-describedby="email-invalid"
            />
            {formik.errors.email && formik.touched.email ? (
              <small id="email-invalid" className="p-error p-d-block">
                {formik.errors.email}
              </small>
            ) : null}
          </div>
        </div>
        <div className="p-field p-grid p-jc-center">
          <label htmlFor="firstName" className="p-col-12 p-md-3 p-text-bold">
            First Name
          </label>
          <div className="p-col-12 p-md-9">
            <InputText
              id="firstName"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values?.firstName}
              aria-describedby="firstName-invalid"
              className={
                formik.errors.firstName && formik.touched.firstName
                  ? 'p-invalid'
                  : null
              }
            />
            {formik.touched.firstName && formik.errors.firstName && (
              <small id="firstName-invalid" className="p-error p-d-block">
                {formik.errors.firstName}
              </small>
            )}
          </div>
        </div>
        <div className="p-field p-grid p-jc-center">
          <label htmlFor="lastName" className="p-col-12 p-md-3 p-text-bold">
            Last Name
          </label>
          <div className="p-col-12 p-md-9">
            <InputText
              id="lastName"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values?.lastName}
              aria-describedby="lastName-invalid"
              className={
                formik.errors.lastName && formik.touched.lastName
                  ? 'p-invalid'
                  : null
              }
            />
            {formik.touched.lastName && formik.errors.lastName && (
              <small id="lastName-invalid" className="p-error p-d-block">
                {formik.errors.lastName}
              </small>
            )}
          </div>
        </div>
        <div className="p-field p-grid p-jc-center">
          <label htmlFor="companyName" className="p-col-12 p-md-3 p-text-bold">
            Company Name
          </label>
          <div className="p-col-12 p-md-9">
            <InputText
              className={
                formik.errors.companyName && formik.touched.companyName
                  ? 'p-invalid'
                  : null
              }
              id="companyName"
              type="text"
              onBlur={formik.handleBlur}
              value={formik.values.companyName}
              onChange={formik.handleChange}
              aria-describedby="companyName-invalid"
            />
            {formik.errors.companyName && formik.touched.companyName ? (
              <small id="companyName-invalid" className="p-error p-d-block">
                {formik.errors.companyName}
              </small>
            ) : null}
          </div>
        </div>
        <div className="p-field p-grid p-jc-center">
          <label htmlFor="phoneNumber" className="p-col-12 p-md-3 p-text-bold">
            Phone Number
          </label>
          <div className="p-col-12 p-md-9">
            <InputMask
              className={
                formik.errors.phoneNumber && formik.touched.phoneNumber
                  ? 'p-invalid'
                  : null
              }
              id="phoneNumber"
              mask="(999) 999-9999"
              placeholder="(999) 999-9999"
              onBlur={formik.handleBlur}
              value={formik.values?.phoneNumber}
              onChange={formik.handleChange}
              aria-describedby="phone-invalid"
            />
            {formik.errors.phoneNumber && formik.touched.phoneNumber ? (
              <small id="phone-invalid" className="p-error p-d-block">
                {formik.errors.phoneNumber}
              </small>
            ) : null}
          </div>
        </div>
        <div className="p-field p-grid p-jc-center">
          <label htmlFor="country" className="p-col-12 p-md-3 p-text-bold">
            Country
          </label>
          <div className="p-col-12 p-md-9">
            <Dropdown
              id="country"
              onChange={onCountryChange}
              onBlur={() => {
                formik.handleBlur({ target: { name: 'country' } });
              }}
              value={formik.values.country}
              aria-describedby="type-invalid"
              className={`${
                (props.error ||
                  (formik.errors.country && formik.touched.country)) &&
                'p-invalid'
              }`}
              options={countryList}
              optionLabel="name"
              filter
            />
            {formik.touched.country && formik.errors.country && (
              <small id="firstName-invalid" className="p-error p-d-block">
                {formik.errors.country.name}
              </small>
            )}
          </div>
        </div>
      </div>
      <div
        className="p-grid p-col-12 p-justify-between pad-r-0"
        style={{ marginLeft: '0', padding: '30px 0 10px' }}
      >
        <div>
          By signing up, you agree to our{' '}
          <Link
            style={{ textDecoration: 'inherit' }}
            to="/user-agreement"
            target="_blank"
          >
            User Agreement
          </Link>{' '}
          and{' '}
          <Link
            style={{ textDecoration: 'inherit' }}
            to="/privacy-policy"
            target="_blank"
          >
            Privacy Policy
          </Link>
        </div>
        <Button
          className={styles.continue}
          label="Continue"
          type="submit"
          disabled={Boolean(
            !formik.dirty ||
              !formik.values.firstName ||
              !formik.values.lastName ||
              !formik.values.email ||
              !formik.values.phoneNumber ||
              !formik.values.companyName ||
              !formik.values.country ||
              formik.errors.firstName ||
              formik.errors.lastName ||
              formik.errors.email ||
              formik.errors.phoneNumber ||
              formik.errors.companyName ||
              formik.isSubmitting
          )}
        />
      </div>
    </form>
  );

  useEffect(() => {
    setFilteredCountries(getFilteredCountries(countries));
  }, []);

  useEffect(() => {
    formik.errors?.employee?.email === 'Invalid format.' &&
      responseFailed('Invalid email format');
  }, [formik.errors]);

  return (
    <div className={styles.signUpForm}>
      <PromptIfDirty dirty={formik.dirty} />
      <h1>Account Sign-Up</h1>
      <Divider />
      <Toast ref={toast} />
      {formContent}
    </div>
  );
};

export default SignUpPage;
