import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { useFormik } from 'formik';
import {
  initialProductFormSchema,
  productFormSchema,
} from './newProductFormValidation';
import PromptIfDirty from '../../Common/PromptIfDirty';
import {
  initialProductInfo,
  errorFields,
  errorMessages,
  refTableHeaders,
} from '../constants';
import ReferenceTable from '../../Common/ReferenceTable/ReferenceTable';
import { urls, useRequest } from '../../Common/ApiServices';
import styles from './NewProductForm.module.scss';

const NewProductForm = ({
  displayProductModal,
  setDisplayProductModal,
  setProductIsCreated,
  categories,
  classifications,
  manufacturers,
  responseFailed,
}) => {
  const [currentRefTableName, setCurrentRefTableName] = useState('');
  const [displayRefTable, setDisplayRefTable] = useState(false);

  const { error, sendRequest } = useRequest({});

  const toast = useRef(null);

  const createProduct = async (data) => {
    const requestData = {
      url: urls.CREATE_PRODUCT,
      method: 'POST',
      data: data,
    };

    const response = await sendRequest(requestData);
    if (response) {
      setDisplayProductModal(false);
      setProductIsCreated(true);
      formik.resetForm();
    }
    return response;
  };

  const accept = () => {
    onHide();
  };

  const confirm = (dirty) => {
    if (dirty) {
      confirmDialog({
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        message: 'Are you sure you want to discard the changes?',
        accept,
      });
    } else {
      accept();
    }
  };

  const onHide = () => {
    setDisplayProductModal(false);
    formik.resetForm();
  };

  const refTableValues = {
    [urls.PRODUCT_CATEGORIES]: categories,
    [urls.PRODUCT_CLASSIFICATIONS]: classifications,
    [urls.PRODUCT_MANUFACTURERS]: manufacturers,
  };

  const getErrorMessage = (code, fieldName) => {
    if (code === 'NOT_UNIQUE') {
      return errorMessages[fieldName];
    }
  };

  const setFormikError = () => {
    error.response.data.errors.map((err) => {
      return formik.setFieldError(
        errorFields[err.fieldName],
        getErrorMessage(err.code, err.fieldName)
      );
    });
  };

  const refTableHeader = () => {
    return currentRefTableName && refTableHeaders[currentRefTableName];
  };

  const onOpenRefTable = (value) => {
    setCurrentRefTableName(value);
    setDisplayRefTable(true);
  };

  const formik = useFormik({
    initialValues: initialProductFormSchema(initialProductInfo),
    enableReinitialize: true,
    validationSchema: productFormSchema,
    onSubmit: (values) => {
      const newProductParams = {
        code: values.productCode,
        name: values.productName || null,
        tradeName: values.tradeName,
        categoryId: values?.category?.id || null,
        classificationId: values?.classification?.id || null,
        manufacturerId: values?.manufacturer?.id || null,
        note: values.note || null,
      };
      createProduct(newProductParams);
    },
  });

  useEffect(() => {
    if (error) {
      error.response.data.errors ? setFormikError() : responseFailed();
    }
  }, [error]);

  return (
    <>
      <Dialog
        className={styles.productFormDialog}
        header="Product Information"
        visible={displayProductModal}
        onHide={() => confirm(formik.dirty)}
      >
        <Toast ref={toast} />
        <PromptIfDirty dirty={formik.dirty} />
        <Divider />
        <form onSubmit={formik.handleSubmit} autoComplete={'off'}>
          <div className="p-fluid">
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="productCode"
                className="p-col-12 p-md-3 p-text-bold"
              >
                Product Code*
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  id="productCode"
                  type="text"
                  value={formik.values.productCode}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  aria-describedby="productCode-invalid"
                  className={
                    formik.errors.productCode &&
                    formik.touched.productCode &&
                    'p-invalid'
                  }
                />
                {formik.errors.productCode && formik.touched.productCode ? (
                  <small id="productCode-invalid" className="p-error p-d-block">
                    {formik.errors.productCode}
                  </small>
                ) : (
                  <small>Product code is required.</small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="tradeName"
                className="p-col-12 p-md-3 p-text-bold"
              >
                Trade Name*
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  id="tradeName"
                  type="text"
                  value={formik.values.tradeName}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  aria-describedby="tradeName-invalid"
                  className={
                    formik.errors.tradeName &&
                    formik.touched.tradeName &&
                    'p-invalid'
                  }
                />
                {formik.errors.tradeName && formik.touched.tradeName ? (
                  <small id="tradeName-invalid" className="p-error p-d-block">
                    {formik.errors.tradeName}
                  </small>
                ) : (
                  <small>Trade name is required.</small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="productName" className="p-col-12 p-md-3">
                Product Name
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  id="productName"
                  type="text"
                  value={formik.values.productName}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  aria-describedby="productName-invalid"
                  className={formik.errors.productName ? 'p-invalid' : null}
                />
                {formik.errors.productName ? (
                  <small id="productName-invalid" className="p-error p-d-block">
                    {formik.errors.productName}
                  </small>
                ) : null}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="category" className="p-col-12 p-md-3">
                Category
              </label>
              <div className="p-col-9 p-md-7">
                <Dropdown
                  id="category"
                  onChange={formik.handleChange}
                  value={formik.values.category}
                  options={categories}
                  optionLabel="name"
                  filter
                  showClear
                />
              </div>
              <div className="p-col-3 p-md-2">
                <Button
                  className="p-button-rounded p-button-info p-button-outlined"
                  type="button"
                  icon="pi pi-pencil"
                  onClick={() => onOpenRefTable(urls.PRODUCT_CATEGORIES)}
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="classification" className="p-col-12 p-md-3">
                Classification
              </label>
              <div className="p-col-9 p-md-7">
                <Dropdown
                  id="classification"
                  onChange={formik.handleChange}
                  value={formik.values.classification}
                  options={classifications}
                  optionLabel="name"
                  filter
                  showClear
                />
              </div>
              <div className="p-col-3 p-md-2">
                <Button
                  className="p-button-rounded p-button-info p-button-outlined"
                  type="button"
                  icon="pi pi-pencil"
                  onClick={() => onOpenRefTable(urls.PRODUCT_CLASSIFICATIONS)}
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="classification" className="p-col-12 p-md-3">
                Manufacturer
              </label>
              <div className="p-col-9 p-md-7">
                <Dropdown
                  id="manufacturer"
                  onChange={formik.handleChange}
                  value={formik.values.manufacturer}
                  options={manufacturers}
                  optionLabel="name"
                  filter
                  showClear
                />
              </div>
              <div className="p-col-3 p-md-2">
                <Button
                  className="p-button-rounded p-button-info p-button-outlined"
                  type="button"
                  icon="pi pi-pencil"
                  onClick={() => onOpenRefTable(urls.PRODUCT_MANUFACTURERS)}
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="note" className="p-col-12 p-md-3">
                Note
              </label>
              <div className="p-col-12 p-md-9">
                <InputTextarea
                  className={formik.errors.note ? 'p-invalid' : null}
                  id="note"
                  type="text"
                  rows="4"
                  onChange={formik.handleChange}
                  aria-describedby="note-invalid"
                  value={formik.values.note}
                />
                {formik.errors.note ? (
                  <small id="note-invalid" className="p-error p-d-block">
                    {formik.errors.note}
                  </small>
                ) : null}
              </div>
            </div>
          </div>
          <div className="p-grid p-col-12 p-justify-end pad-r-0 margin-l-0">
            <Button
              className={styles.button}
              label="Save"
              type="submit"
              icon="pi pi-check"
              disabled={!formik.dirty || !formik.isValid}
              autoFocus
            />
            <Button
              className={`p-button-secondary ${styles.button}`}
              label="Cancel"
              type="button"
              icon="pi pi-times"
              onClick={() => confirm(formik.dirty)}
            />
          </div>
        </form>
      </Dialog>
      <Dialog
        className="confirmDialog"
        header={refTableHeader()}
        visible={displayRefTable}
        onHide={() => setDisplayRefTable(false)}
      >
        <ReferenceTable
          referenceBook={true}
          values={refTableValues[currentRefTableName]}
          margin="20px"
          referenceName={currentRefTableName}
          editable={true}
        />
        <div />
      </Dialog>
    </>
  );
};

export default NewProductForm;
