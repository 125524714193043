import React, { useEffect, useState } from 'react';
import { urls, useRequest } from '../../Common/ApiServices';
import TrialMenu from '../Menu/TrialMenu';
import TrialForm from './TrialForm/TrialForm';
import LeftBar from '../../LeftBar/LeftBar';
import { useParams } from 'react-router-dom';

const TrialInfo = () => {
  const { id } = useParams();
  const trialParams = {
    query: {
      trial: {
        id: {
          is: id,
        },
      },
    },
  };

  const initialTrialInfo = {
    name: '',
    location: '',
    trialManager: '',
    startDate: null,
    closeDate: null,
    description: '',
    contract: false,
    logFrequency: null,
    note: '',
  };
  const [trialInfo, setTrialInfo] = useState(initialTrialInfo);
  const [isUpdated, setIsUpdated] = useState(false);

  const { error, sendRequest } = useRequest({});

  const getTrialInfo = async () => {
    const requestData = {
      url: urls.EXTRACT_TRIAL,
      method: 'POST',
      data: trialParams,
    };

    const response = await sendRequest(requestData);
    if (response) {
      const data = response.data;
      let dataTrial = response.data.trial;
      dataTrial.cropTemplate = data.cropTemplate && {
        id: data.cropTemplate.id,
        name: data.cropTemplate.name,
      };
      dataTrial.location = {
        id: data.location.id,
        name: data.location.name,
      };
      dataTrial.trialManager = {
        name: `${data.trialManager.firstName} ${data.trialManager.lastName}`,
        id: data.trialManager.id,
      };
      dataTrial.products = data.products;
      dataTrial.cropTemplate = data.cropTemplate;

      setTrialInfo(dataTrial);
      setIsUpdated(false);
      localStorage.setItem('trialId', dataTrial.id);
      localStorage.setItem('trialName', dataTrial.name);
    }
    return response;
  };

  useEffect(() => {
    isUpdated && getTrialInfo();
  }, [isUpdated]);

  useEffect(() => {
    getTrialInfo();
  }, []);
  return (
    <>
      <LeftBar>
        <TrialMenu id={trialInfo.id} name={trialInfo.name} />
      </LeftBar>
      <TrialForm
        trialInfo={trialInfo}
        isUpdated={isUpdated}
        setIsUpdated={setIsUpdated}
        setTrialInfo={setTrialInfo}
        trialId={id}
        error={error}
      />
    </>
  );
};

export default TrialInfo;
