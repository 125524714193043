import React from 'react';
import { useSelector } from 'react-redux';
import Menu from '../../Common/Menu/Menu';
import { adminLinks, userLinks } from '../constants';
import { userRoles } from '../../Common/globalConstants';

const SettingsMenu = () => {
  const permissions = useSelector((state) => state.permissions.permissions);

  return (
    <Menu
      items={
        permissions?.includes(userRoles.PPT_MANAGE_COMPANY)
          ? adminLinks
          : userLinks
      }
    />
  );
};

export default SettingsMenu;
