import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Toast } from 'primereact/toast';
import ProductsList from '../ProductList/ProductList';
import LeftBar from '../../LeftBar/LeftBar';
import ProductFilters from '../ProductFilters/ProductFilters';
import { isEmpty } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import { getProductFilters } from '../../../reduxStore/productFilters/actions';
import { urls, useRequest } from '../../Common/ApiServices';
import { initialProductParams } from '../constants';
import { createFilterCategory, fillFilterParams } from '../../Common/utils';
import { filtersNames } from '../constants';
import { queryTypes } from '../../Common/globalConstants';
import { isAuth } from '../../../auth/auth-service';

const ProductsPage = () => {
  const [filters, setFilters] = useState(null);
  const [products, setProducts] = useState([]);
  const [productParams, setProductParams] = useState(initialProductParams);
  const [totalRecords, setTotalRecords] = useState(null);
  const [productIsCreated, setProductIsCreated] = useState(false);
  const [firstLoad, setFirstLoad] = useState();

  const dispatch = useDispatch();

  const categories = useSelector((state) => state.productFilters.categories);
  const classifications = useSelector(
    (state) => state.productFilters.classifications
  );
  const manufacturers = useSelector(
    (state) => state.productFilters.manufacturers
  );
  const productFiltersReady = useSelector(
    (state) => state.productFilters.productFiltersReady
  );

  const toast = useRef(null);
  const { isLoading, sendRequest } = useRequest({});

  const { logout, getAccessTokenSilently } = useAuth0();
  const actionData = {
    logout: logout,
    dispatch: dispatch,
    isAuthenticated: isAuth(),
    getAccessTokenSilently: getAccessTokenSilently,
  };

  const responseFailed = (message = 'Something went wrong.') => {
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail: message,
      life: 5000,
    });
  };

  const fetchProducts = async () => {
    const requestData = {
      url: urls.SEARCH_PRODUCTS,
      method: 'POST',
      data: productParams,
    };
    const response = await sendRequest(requestData);

    const products = response.data.results;
    setProducts(products);
    setTotalRecords(response?.data?.page?.total);
    setFirstLoad(true);

    return response;
  };

  const updateFilters = (newFilters) => {
    if (!newFilters) {
      return null;
    }
    const selectedFilters = createFilterCategory(newFilters);
    if (!isEmpty(selectedFilters)) {
      setProductParams((oldParams) => {
        return fillFilterParams(
          selectedFilters,
          oldParams,
          filtersNames,
          queryTypes.PRODUCT
        );
      });
    }
  };

  const onApplyNewFilters = (
    selectedCategories,
    selectedClassifications,
    selectedManufacturers
  ) => {
    setFilters({
      category:
        selectedCategories && selectedCategories.length > 0
          ? selectedCategories
          : null,
      classification:
        selectedClassifications && selectedClassifications.length > 0
          ? selectedClassifications
          : null,
      manufacturer:
        selectedManufacturers && selectedManufacturers.length > 0
          ? selectedManufacturers
          : null,
    });
  };

  useLayoutEffect(() => {
    return () => {
      setFirstLoad(false);
    };
  }, []);

  useEffect(() => {
    if (productIsCreated) {
      toast.current.show({
        severity: 'success',
        summary: 'Successful',
        detail: 'New product was created.',
        life: 5000,
      });
      fetchProducts();
      setProductIsCreated(false);
    }
  }, [productIsCreated]);

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    firstLoad && fetchProducts();
  }, [productParams]);

  useEffect(() => {
    !productFiltersReady && dispatch(getProductFilters(actionData));
  }, [productFiltersReady]);

  useEffect(() => {
    updateFilters(filters);
  }, [filters]);

  return (
    <>
      <Toast ref={toast} />
      <LeftBar>
        <ProductFilters
          categories={categories}
          classifications={classifications}
          manufacturers={manufacturers}
          applyNewFilters={onApplyNewFilters}
        />
      </LeftBar>
      <ProductsList
        loading={isLoading}
        products={products}
        productParams={productParams}
        totalRecords={totalRecords}
        categories={categories}
        classifications={classifications}
        manufacturers={manufacturers}
        responseFailed={responseFailed}
        fetchProducts={fetchProducts}
        setProductParams={setProductParams}
        setProductIsCreated={setProductIsCreated}
      />
    </>
  );
};

export default ProductsPage;
