import React from 'react';
import { useSelector } from 'react-redux';
import Menu from '../../Common/Menu/Menu';

const TrialMenu = ({ name, id }) => {
  const trialId = id || localStorage.getItem('trialId');
  const trialName = name || localStorage.getItem('trialName');
  const trialLink = `/trial/${trialId}`;
  const isShared = useSelector((state) => state.isShared);
  const notShared = [
    { link: trialLink, value: 'Information' },
    { link: `${trialLink}/fields`, value: 'Trial Fields' },
    { link: `${trialLink}/plants`, value: 'Trial Plants' },
    { link: `${trialLink}/crops`, value: 'Crops' },
    { link: `${trialLink}/logs`, value: 'Trial Logs' },
    { link: `${trialLink}/reports`, value: 'Reports' },
    { link: `${trialLink}/history`, value: 'Application History' },
    { link: `${trialLink}/shared-trials`, value: 'Shared With' },
  ];

  const shared = [
    { link: trialLink, value: 'Information' },
    { link: `${trialLink}/logs`, value: 'Logs' },
    { link: `${trialLink}/reports`, value: 'Reports' },
  ];

  return <Menu items={isShared ? shared : notShared} name={trialName} />;
};

export default TrialMenu;
