import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import merge from 'deepmerge';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { Toast } from 'primereact/toast';
import { useAuth0 } from '@auth0/auth0-react';
import classNames from 'classnames';
import { trialInfo } from '../../../../reduxStore/trial/actions';
import { getAuthConfig, checkBlocked } from '../../../../auth/auth-service';
import BreadCrumb from '../../../BreadCrumb/BreadCrumb';
import PromptIfDirty from '../../../Common/PromptIfDirty';
import { checkSubscription } from '../../../Common/utils';
import styles from './PlantSelectionList.module.scss';

const PlantSelectionList = (props) => {
  const trialId = localStorage.getItem('trialId');
  const initialPlantParams = {
    query: {
      trial: {
        id: {
          is: trialId,
        },
      },
    },
    navigation: {
      sort: [
        {
          key: 'name',
          order: 'ASC',
        },
      ],
      page: {
        from: 0,
        size: 20,
      },
    },
  };

  const [plants, setPlants] = useState([]);
  const [loading, setLoading] = useState(false);
  const [plantParams, setPlantParams] = useState(initialPlantParams);
  const [selectedPlants, setSelectedPlants] = useState([]);
  const [globalSearch, setGlobalSearch] = useState(null);
  const [sort, setSort] = useState({ field: 'name', order: 1 });
  const [pagination, setPagination] = useState({ first: 0, rows: 20 });
  const [totalRecords, setTotalRecords] = useState(null);
  const [cancelSelectionDialog, setCancelSelectionDialog] = useState(false);

  const toast = useRef(null);
  const isMountedRef = useRef(null);
  const isInitialMount = useRef(true);
  const tableRef = useRef(null);

  const { logout, getAccessTokenSilently } = useAuth0();

  const dispatch = useDispatch();

  const history = useHistory();

  const fetchPlants = async () => {
    try {
      const config = await getAuthConfig(true, logout, getAccessTokenSilently);
      const response = await axios.post(
        `${config.apiUrl}/search-plants`,
        plantParams,
        config
      );
      const plants = await response.data.results.map((plant) => ({
        id: plant.plant.id,
        name: plant.plant.name,
        code: plant.plant.code,
        program: plant.program ? plant.program.name : null,
        class: plant.class ? plant.class.name : null,
        series: plant.series ? plant.series.name : null,
        breeder: plant.breeder ? plant.breeder.name : null,
      }));
      if (isMountedRef.current) {
        setPlants(plants);
        setTotalRecords(response.data.page.total);
      }
    } catch (error) {
      checkBlocked(error, logout);
      checkSubscription(error, dispatch);
      console.log('error -> ', error);
    } finally {
      setLoading(false);
    }
  };

  const updateFilters = (newFilters) => {
    if (!newFilters) return null;
    const { classes, programs, series, breeders } = newFilters;
    const newClasses = classes ? classes.map((class_) => class_.id) : null;
    const newPrograms = programs ? programs.map((program) => program.id) : null;
    const newSeries = series ? series.map((series) => series.id) : null;
    const newBreeders = breeders ? breeders.map((breeder) => breeder.id) : null;

    setPlantParams((oldParams) => {
      let params = cloneDeep(oldParams);
      if (newClasses) {
        const classParams = { query: { class: { id: { in: null } } } };
        params = merge(params, classParams);
        params.query.class.id.in = newClasses;
      } else if (!newClasses && params.query.class) {
        delete params.query.class;
      }
      if (newPrograms) {
        const programParams = { query: { program: { id: { in: null } } } };
        params = merge(params, programParams);
        params.query.program.id.in = newPrograms;
      } else if (!newPrograms && params.query.program) {
        delete params.query.program;
      }
      if (newBreeders) {
        const breederParams = { query: { breeder: { id: { in: null } } } };
        params = merge(params, breederParams);
        params.query.breeder.id.in = newBreeders;
      } else if (!newBreeders && params.query.breeder) {
        delete params.query.breeder;
      }
      if (newSeries) {
        const seriesParams = { query: { series: { id: { in: null } } } };
        params = merge(params, seriesParams);
        params.query.series.id.in = newSeries;
      } else if (!newSeries && params.query.series) {
        delete params.query.series;
      }
      return params;
    });
  };

  useEffect(() => {
    if (!isInitialMount.current) {
      updateFilters(props.newFilters);
    }
  }, [props.newFilters]);

  useEffect(() => {
    if (sort && !isInitialMount.current) {
      setPlantParams((oldParams) => {
        let params = cloneDeep(oldParams);
        params.navigation.sort[0].key = sort.field;
        params.navigation.sort[0].order = sort.order === 1 ? 'ASC' : 'DESC';
        return params;
      });
    }
  }, [sort]);

  useEffect(() => {
    if (!isInitialMount.current) {
      setPlantParams((oldParams) => {
        let params = cloneDeep(oldParams);
        params.navigation.page.from = pagination.first;
        params.navigation.page.size = pagination.rows;
        return params;
      });
    }
  }, [pagination]);

  useEffect(() => {
    if (!isInitialMount.current) {
      setPlantParams((oldParams) => {
        let params = cloneDeep(oldParams);
        if (globalSearch) {
          const searchParams = { query: { search: { is: globalSearch } } };
          params = merge(params, searchParams);
        } else if (!globalSearch && params.query.search) {
          delete params.query.search;
        }
        return params;
      });
    }
  }, [globalSearch]);

  useEffect(() => {
    isMountedRef.current = true;
    setLoading(true);
    fetchPlants();
    isInitialMount.current = false;
    return () => {
      return (isMountedRef.current = false), (isInitialMount.current = false);
    };
  }, [plantParams]);

  const toTrialPlantList = async () => {
    await setSelectedPlants([]);
    history.push(`/trial/${trialId}/plants`);
  };

  const onSort = ({ sortField, sortOrder }) => {
    setSort({ field: sortField, order: sortOrder });
  };

  const onPageSelect = ({ first, rows }) => {
    setPagination({ first, rows });
  };

  const debouncedGlobalSearch = useRef(
    debounce((value) => setGlobalSearch(value), 500)
  ).current;

  const onGlobalSearch = (event) => {
    debouncedGlobalSearch(event.target.value);
  };

  const onSave = () => {
    const selectedPlantIds = selectedPlants.map((plant) => plant.id);
    const plantParams = {
      trialId: trialId,
      plantId: {
        in: selectedPlantIds,
      },
    };
    getAuthConfig(true, logout, getAccessTokenSilently).then((res) => {
      axios
        .post(`${res.apiUrl}/add-trial-plants`, plantParams, res)
        .then((response) => toTrialPlantList())
        .catch((error) => {
          checkBlocked(error, logout);
          checkSubscription(error, dispatch);
          if (error.response.data.errors) {
            onPlantsAddedError();
          }
          console.log('error -> ', error);
        });
    });
  };

  const onCancel = () => {
    if (selectedPlants.length > 0) {
      setCancelSelectionDialog(true);
    } else {
      toTrialPlantList();
    }
  };

  const hideCancelSelectionDialog = () => {
    setCancelSelectionDialog(false);
  };

  const onPlantsAddedError = () => {
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail: 'Selected plants already exist in the trial',
      life: 5000,
    });
  };

  const sortFunc = () => {
    return tableRef?.current?.props.value || plants;
  };

  const header = (
    <div className={styles.tableHeader}>
      <div className={styles.tableLabel}>
        <Button
          className="p-button-raised"
          disabled={selectedPlants.length === 0}
          label="Save"
          onClick={() => onSave()}
        />
        <Button
          className=" p-button-secondary p-button-raised"
          label="Cancel"
          onClick={() => onCancel()}
        />
      </div>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => onGlobalSearch(e)}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  const footer = () => {
    if (!totalRecords) {
      let emptyMessage = '';
      if (totalRecords === 0) {
        emptyMessage = 'No plants conform to filters.';
      }
      return (
        <div className="generic-list-message">
          <h3 className="p-text-center">{emptyMessage}</h3>
        </div>
      );
    } else {
      return (
        <Paginator
          rows={pagination.rows}
          totalRecords={totalRecords}
          className="tabPaginator"
          first={pagination.first}
          rowsPerPageOptions={[20, 50, 100]}
          template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          onPageChange={onPageSelect}
        />
      );
    }
  };

  const cancelSelectionDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideCancelSelectionDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={toTrialPlantList}
      />
    </React.Fragment>
  );

  const breadCrumbItems = [
    {
      label: 'Trials',
      command: () => {
        history.push('/trials');
      },
    },
    {
      label: localStorage.getItem('trialName'),
      command: () => {
        history.push(`/trial/${trialId}`);
      },
    },
    {
      label: 'Trial Plants',
      command: () => {
        history.push(`/trial/${trialId}/plants`);
      },
    },
    { label: 'Select Plants' },
  ];

  return (
    <div className={`${styles.plantSelectionList} list-generic`}>
      <BreadCrumb items={breadCrumbItems} />
      <Toast ref={toast} />
      <PromptIfDirty dirty={selectedPlants.length > 0} />
      <DataTable
        ref={tableRef}
        className={classNames(
          'table-generic p-datatable-sm',
          styles.customTableStyle
        )}
        value={plants}
        header={header}
        resizableColumns
        columnResizeMode="expand"
        selection={selectedPlants}
        onSelectionChange={(e) => setSelectedPlants(e.value)}
        dataKey="id"
        sortField={sort.field}
        sortOrder={sort.order}
        onSort={onSort}
        loading={loading}
        reorderableColumns
        frozenWidth="0px"
        scrollable
      >
        <Column
          selectionMode="multiple"
          headerStyle={{ width: '50px', height: '48px', padding: '0 7px' }}
        />
        <Column
          className="p-text-nowrap p-text-truncate"
          field="name"
          sortField="name"
          columnKey="name"
          header="Name"
          headerStyle={{ width: '270px' }}
          bodyStyle={{ height: '48px' }}
          sortable
          sortFunction={sortFunc}
        />
        <Column
          field="code"
          sortField="code"
          columnKey="code"
          header="Code"
          headerStyle={{ width: '80px' }}
          sortable
          sortFunction={sortFunc}
        />
        <Column
          field="breeder"
          sortField="breederName"
          reorderable
          columnKey="breeder"
          header="Breeder"
          headerStyle={{ width: '270px' }}
          sortable
          sortFunction={sortFunc}
        />
        <Column
          field="program"
          sortField="programName"
          columnKey="program"
          header="Program"
          headerStyle={{ width: '270px' }}
          sortable
          sortFunction={sortFunc}
        />
        <Column
          field="class"
          sortField="className"
          columnKey="class"
          header="Class"
          headerStyle={{ width: '270px' }}
          sortable
          sortFunction={sortFunc}
        />
        <Column
          field="series"
          sortField="seriesName"
          columnKey="series"
          header="Series"
          headerStyle={{ width: '270px' }}
          sortable
          sortFunction={sortFunc}
        />
      </DataTable>
      {footer()}
      <Dialog
        visible={cancelSelectionDialog}
        className="confirmDialog"
        header="Confirm"
        modal
        footer={cancelSelectionDialogFooter}
        onHide={hideCancelSelectionDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle p-mr-3"
            style={{ fontSize: '2rem' }}
          />
          <span>Are you sure you want to cancel plants selection?</span>
        </div>
      </Dialog>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    trialInfo: (name) => dispatch(trialInfo(name)),
  };
};

export default connect(null, mapDispatchToProps)(PlantSelectionList);
