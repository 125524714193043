import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Toast } from 'primereact/toast';
import { useRequest, urls } from '../../Common/ApiServices';
import SubscriptionCard from './SubscriptionCard/SubscriptionCard';
import { colors } from '../constants';
import { errorCodes } from '../../Common/globalConstants';
import styles from './SubscriptionPage.module.scss';

const SubscriptionPage = () => {
  const [subscriptions, setSubscriptions] = useState([]);

  const toast = useRef(null);
  const permissions = useSelector((state) => state.permissions.permissions);

  const { error, sendRequest } = useRequest({});

  const responseFailed = (message = errorCodes.DEFAULT_MESSAGE.text) => {
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail: message,
      life: 5000,
    });
  };

  const toCustomerPortal = async (id) => {
    const requestData = {
      url: urls.CREATE_SUBSCRIPTION_PORTAL,
      method: 'POST',
      data: { priceId: id },
    };

    const response = await sendRequest(requestData);
    response && window.location.assign(response.data.url);
    return response;
  };

  const getSubscriptions = async () => {
    const requestData = {
      url: urls.SUBSCRIPTIONS,
      method: 'POST',
      data: {
        query: {
          product: 'PPT',
        },
      },
    };

    const response = await sendRequest(requestData);
    setSubscriptions(response.data.subscriptions);
    return response;
  };

  const cards = subscriptions.map((subscription, index) => {
    return (
      <SubscriptionCard
        key={index}
        color={colors[index]}
        name={subscription.name}
        description={subscription.description}
        prices={subscription.prices[0]}
        permissions={permissions}
        toCustomerPortal={toCustomerPortal}
      />
    );
  });

  useEffect(() => {
    getSubscriptions();
  }, []);

  useEffect(() => {
    error && responseFailed();
  }, [error]);

  return (
    <>
      <Toast ref={toast} />
      <div className={styles.subscriptionPage}>{cards}</div>
    </>
  );
};

export default SubscriptionPage;
