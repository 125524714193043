import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import classNames from 'classnames';
import BreadCrumb from '../../../BreadCrumb/BreadCrumb';
import { useRequest, urls } from '../../../Common/ApiServices';
import { initialSettingsSchema, settingsSchema } from './systemFormValidation';
import { errorCodes, links } from '../../../Common/globalConstants';
import { destinationInstruction } from '../../constants';
import PromptIfDirty from '../../../Common/PromptIfDirty';
import styles from './SystemForm.module.scss';

function SystemForm() {
  const [settingsInfo, setSettingsInfo] = useState(null);
  const [isReplication, setIsReplication] = useState(false);
  const [replicationIsCreated, setReplicationIsCreated] = useState(false);
  const [replicationIsUpdated, setReplicationIsUpdated] = useState(false);
  const [replicationIsDeleted, setReplicationIsDeleted] = useState(false);

  const history = useHistory();

  const toast = useRef(null);

  const requestFailed = (message) => {
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail: message,
      life: 5000,
    });
  };

  const breadCrumbItems = [
    {
      label: 'Settings',
      command: () => {
        history.push(links.REPLICATION);
      },
    },
    { label: 'Replication' },
  ];

  const { error, sendRequest } = useRequest({ checkTokenExp: false });

  const getSettings = async () => {
    const requestData = {
      url: urls.EXTRACT_COMPANY_SETTING,
      method: 'POST',
      data: {},
    };

    const response = await sendRequest(requestData);
    if (response) {
      setSettingsInfo(response.data.replication);
      setIsReplication(
        !!(
          response.data?.replication?.accountId &&
          response.data?.replication?.bucket
        )
      );
    }
    return response;
  };

  const createSettings = async (settings) => {
    const requestData = {
      url: urls.CREATE_REPLICATION_SETTINGS,
      method: 'POST',
      data: settings,
    };
    const response = await sendRequest(requestData);
    if (response) {
      setReplicationIsCreated(true);
      formik.resetForm();
    }
  };

  const updateSettings = async (settings) => {
    const requestData = {
      url: urls.UPDATE_REPLICATION_SETTING,
      method: 'POST',
      data: settings,
    };
    const response = await sendRequest(requestData);
    if (response) {
      setReplicationIsUpdated(true);
      formik.resetForm();
    }
  };

  const deleteSettings = async () => {
    const requestData = {
      url: urls.UPDATE_REPLICATION_SETTING,
      method: 'POST',
      data: {
        accountId: null,
        bucket: null,
      },
    };
    const response = await sendRequest(requestData);
    if (response) {
      setReplicationIsDeleted(true);
      formik.resetForm();
    }
  };

  const formik = useFormik({
    initialValues: initialSettingsSchema(settingsInfo),
    enableReinitialize: true,
    validationSchema: settingsSchema,
    onSubmit: (values) => {
      const newSettings = {
        accountId: values.accountId || null,
        bucket: values.bucket || null,
      };

      if (isReplication) {
        updateSettings(newSettings);
      } else {
        createSettings(newSettings);
      }
    },
  });

  useEffect(() => {
    getSettings();
  }, []);

  useEffect(() => {
    let message = errorCodes.DEFAULT_MESSAGE.text;
    if (
      error?.response?.data?.error?.code === errorCodes.BUCKET_NOT_EXIST.code
    ) {
      message = errorCodes.BUCKET_NOT_EXIST.text;
    } else if (
      error?.response?.data?.error?.code ===
      errorCodes.NOT_UNIQUE_BUCKET_NAME.code
    ) {
      message = errorCodes.NOT_UNIQUE_BUCKET_NAME.text;
    }
    error && (requestFailed(message), formik.resetForm());
  }, [error]);

  useEffect(() => {
    if (replicationIsCreated) {
      toast.current.show({
        severity: 'success',
        summary: 'Successful',
        detail: 'New replication was created.',
        life: 10000,
      });
      getSettings();
      setReplicationIsCreated(false);
    } else if (replicationIsUpdated) {
      toast.current.show({
        severity: 'success',
        summary: 'Successful',
        detail: `Replication was updated.`,
        life: 10000,
      });
      getSettings();
      setReplicationIsUpdated(false);
    } else if (replicationIsDeleted) {
      toast.current.show({
        severity: 'success',
        summary: 'Successful',
        detail: `Replication was deleted.`,
        life: 10000,
      });
      getSettings();
      setReplicationIsDeleted(false);
    }
  }, [replicationIsCreated, replicationIsUpdated, replicationIsDeleted]);

  return (
    <div className={styles.systemForm}>
      <BreadCrumb items={breadCrumbItems} />
      <PromptIfDirty dirty={formik.dirty} />
      <div className={styles.inner}>
        <Toast ref={toast} />
        <h3>Replication settings</h3>
        <Divider />
        <form onSubmit={formik.handleSubmit}>
          <div className="p-fluid">
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="name" className="p-col-12 p-md-2 p-text-bold">
                Account Id*
              </label>
              <div className="p-col-12 p-md-5">
                <InputText
                  id="accountId"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.accountId}
                  aria-describedby="accountId-invalid"
                  className={
                    formik.touched.accountId &&
                    formik.errors.accountId &&
                    'p-invalid'
                  }
                />
                {formik.touched.accountId && formik.errors.accountId ? (
                  <small id="accountId-invalid" className="p-error p-d-block">
                    {formik.errors.accountId}
                  </small>
                ) : (
                  <small>Account Id is required.</small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="bucket" className="p-col-12 p-md-2 p-text-bold">
                Bucket Name*
              </label>
              <div className="p-col-12 p-md-5">
                <InputText
                  id="bucket"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.bucket}
                  aria-describedby="bucket-invalid"
                  className={
                    formik.touched.bucket && formik.errors.bucket && 'p-invalid'
                  }
                />
                {formik.touched.bucket && formik.errors.bucket ? (
                  <small id="name-invalid" className="p-error p-d-block">
                    {formik.errors.bucket}
                  </small>
                ) : (
                  <small>Bucket Name is required.</small>
                )}
              </div>
            </div>
          </div>
          <div
            className={classNames(
              'p-grid p-col-12 p-md-7',
              styles.buttonsGroup
            )}
          >
            <Button
              className={classNames(
                styles.button,
                isReplication && styles.hideElement
              )}
              label={'Create Replication'}
              type="submit"
              icon="pi pi-check"
              autoFocus
              disabled={
                !formik.dirty || formik.errors.accountId || formik.errors.bucket
              }
            />
            <Button
              className={classNames(
                styles.button,
                !isReplication && styles.hideElement
              )}
              label={'Update Replication'}
              type="submit"
              icon="pi pi-check"
              autoFocus
              disabled={
                !formik.dirty || formik.errors.accountId || formik.errors.bucket
              }
            />
            <Button
              className={`p-button-secondary ${styles.button}`}
              label="Delete Replication"
              type="button"
              icon="pi pi-times"
              onClick={deleteSettings}
              disabled={!isReplication}
            />
          </div>
        </form>
        <div className="instruction">
          <h3>Destination bucket actions:</h3>
          <ul>
            <ol>
              1. Enable &quot;Bucket Versioning&quot; in destination bucket.
            </ol>
            <ol>
              2. Add &quot;Destination Policy&quot; (see below) to destination
              bucket policy (Permissions &gt; Bucket policy).{' '}
              <a href="https://docs.aws.amazon.com/AmazonS3/latest/userguide/add-bucket-policy.html">
                AWS Bucket Policy Guide
              </a>
            </ol>
            <ol>
              3. In Destination Policy, replace
              &quot;destination-bucket-name&quot; name to real bucket name.
            </ol>
          </ul>
        </div>
        <Accordion>
          <AccordionTab header="Destination Policy">
            <pre>
              <code>{JSON.stringify(destinationInstruction, null, 4)}</code>
            </pre>
          </AccordionTab>
        </Accordion>
      </div>
    </div>
  );
}

export default SystemForm;
