import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { Toast } from 'primereact/toast';
import { urls, useRequest } from '../../../Common/ApiServices';
import { getTrialFilters } from '../../../../reduxStore/trialFilters/actions';
import LogFilters from '../../Logs/LogFilters/LogFilters';
import LogList from '../../Logs/LogList/LogList';
import LeftBar from '../../../LeftBar/LeftBar';
import { isAuth } from '../../../../auth/auth-service';
import { getTrialFieldParams } from '../../Logs/LogList/constants';

const CropLogPage = (props) => {
  const trialId = localStorage.getItem('trialId');

  const [filters, setFilters] = useState(null);
  const [firstDay, setFirstDay] = useState(null);
  const [dateFormat, setDateFormat] = useState(null);
  const [trialFields, setTrialFields] = useState([]);
  const [fieldsTypes, setFieldTypes] = useState({});

  const { error, sendRequest } = useRequest({});

  const toast = useRef(null);

  const { logout, getAccessTokenSilently } = useAuth0();

  const dispatch = useDispatch();

  const responseFailed = () => {
    let errorDetail = 'Something went wrong.';
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail: errorDetail,
      life: 5000,
    });
  };

  const extractCrop = async () => {
    const cropParams = {
      query: {
        crop: {
          id: {
            is: props.match.params.id,
          },
        },
      },
    };

    const requestData = {
      url: urls.EXTRACT_CROP,
      method: 'POST',
      data: cropParams,
    };
    const response = await sendRequest(requestData);

    setFirstDay(response?.data?.location.firstDayOfWeek?.day);
    setDateFormat(response?.data?.location.dateFormat?.datePattern);

    return response;
  };

  const fetchTrialFields = async () => {
    const requestData = {
      url: urls.SEARCH_TRIAL_FIELDS,
      method: 'POST',
      data: getTrialFieldParams(trialId),
    };
    const response = await sendRequest(requestData);
    if (response) {
      const fields = response.data.results.map((rawField) => ({
        id: rawField.trialField.id,
        name: rawField.trialField.name,
        type: rawField.trialField.type,
        values: rawField.trialField.values,
        multiselect: rawField.trialField.multiselect,
      }));

      let fieldTypes = {};
      fields.forEach((field) => {
        fieldTypes[field.name] = field.type;
      });

      setFieldTypes(fieldTypes);
      setTrialFields(fields);
    }
    return response;
  };

  const onApplyNewFilters = (
    plantDate,
    logDate,
    sections,
    programs,
    classes,
    series
  ) => {
    setFilters({
      plantDate: plantDate,
      logDate: logDate,
      sections: sections && sections.length > 0 ? sections : null,
      programs: programs && programs.length > 0 ? programs : null,
      classes: classes && classes.length > 0 ? classes : null,
      series: series && series.length > 0 ? series : null,
    });
  };
  const actionData = {
    logout: logout,
    dispatch: dispatch,
    isAuthenticated: isAuth(),
    getAccessTokenSilently: getAccessTokenSilently,
  };

  useEffect(() => {
    !props.trialFiltersReady && props.getTrialFilters(actionData);
  }, [props.trialFiltersReady]);

  useEffect(() => {
    !props.trialFiltersReady && props.getTrialFilters(actionData);
  }, [props.trialFiltersReady]);

  useEffect(() => {
    extractCrop();
    fetchTrialFields();
  }, []);

  useEffect(() => {
    error && responseFailed();
  }, [error]);

  return (
    <React.Fragment>
      <Toast ref={toast} />
      <LeftBar>
        <LogFilters
          firstDay={firstDay}
          dateFormat={dateFormat}
          sections={props.sections}
          programs={props.programs}
          classes={props.classes}
          series={props.series}
          applyNewFilters={onApplyNewFilters}
        />
      </LeftBar>
      <LogList
        isCropLogs={true}
        newFilters={filters}
        fieldsTypes={fieldsTypes}
        trialFields={trialFields}
        trialId={trialId}
      />
    </React.Fragment>
  );
};

// ToDo: Should be replaced with hooks
const mapStateToProps = (state) => {
  return {
    loading: state.trialFilters.loading,
    token: state.trialFilters.token,
    sections: state.trialFilters.sections,
    programs: state.trialFilters.programs,
    classes: state.trialFilters.classes,
    series: state.trialFilters.series,
    trialFiltersReady: state.trialFilters.trialFiltersReady,
  };
};

// ToDo: Should be replaced with hooks
const mapDispatchToProps = (dispatch) => {
  return {
    getTrialFilters: (actionData) => dispatch(getTrialFilters(actionData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CropLogPage);
