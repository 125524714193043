import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import { Toast } from 'primereact/toast';
import BreadCrumb from '../../../BreadCrumb/BreadCrumb';
import LabelTemplateEditor from '../LabelsEditor/LabelTemplateEditor';
import { urls, useRequest } from '../../../Common/ApiServices';
import { sourceType } from '../../constants';
import { links } from '../../../Common/globalConstants';
import { getTemplateData } from '../LabelsEditor/utils';
import styles from './LabelTemplateInfo.module.scss';

function LabelTemplateInfo(props) {
  const [originTemplate, setOriginTemplate] = useState({});
  const [fullTemplate, setFullTemplate] = useState({});
  const [initialFullTemplate, setInitialFullTemplate] = useState({});
  const [customFields, setCustomFields] = useState([]);

  const history = useHistory();
  const toast = useRef(null);

  const { error, sendRequest } = useRequest({});

  const responseFailed = (message = 'Something went wrong.') => {
    toast?.current?.show({
      severity: 'error',
      summary: 'Error',
      detail: message,
      life: 5000,
    });
  };

  const templateUpdatedSuccessfully = (message) => {
    return toast?.current?.show({
      severity: 'success',
      summary: 'Successful',
      detail: message,
      life: 3000,
    });
  };

  const extractCropTemplate = useCallback(
    async (id) => {
      const extractCropParams = {
        template: {
          id: {
            is: id,
          },
        },
      };
      const requestData = {
        url: urls.EXTRACT_CROP_TEMPLATE,
        method: 'POST',
        data: extractCropParams,
      };
      const response = await sendRequest(requestData);

      const result = response.data.template;
      setFullTemplate(result);
      setInitialFullTemplate(cloneDeep(result));

      return response;
    },
    [sendRequest]
  );

  const updateTemplate = async (templateInfo, availableOptions) => {
    const requestData = {
      url: urls.UPDATE_CROP_TEMPLATE,
      method: 'POST',
      data: getTemplateData(
        templateInfo,
        availableOptions,
        fullTemplate,
        fullTemplate.copyFrom.id,
        fullTemplate.id
      ),
    };
    const response = await sendRequest(requestData);
    if (response) {
      templateUpdatedSuccessfully(`Template ${fullTemplate.name} was updated.`);
      await extractCropTemplate(props.location?.state?.id);
    }
    return response;
  };

  const breadCrumbItems = [
    {
      label: 'Settings',
      command: () => {
        history.push(links.REPLICATION);
      },
    },
    {
      label: 'Labels',
      command: () => {
        history.push(links.LABELS);
      },
    },
    { label: originTemplate.name },
  ];

  useEffect(() => {
    setOriginTemplate(props.location.state);
    extractCropTemplate(props.location.state.id);
  }, []);

  useEffect(() => {
    error && responseFailed();
  }, [error]);

  return (
    <div className={styles.labelInfo}>
      <Toast ref={toast} />
      <BreadCrumb items={breadCrumbItems} />
      <LabelTemplateEditor
        showSystemTemplates={false}
        isSystem={originTemplate.source === sourceType.SYSTEM}
        name={props.location.state.name}
        description={props.location.state.description}
        fullTemplate={fullTemplate}
        updateTemplate={updateTemplate}
        setCustomFields={setCustomFields}
        customFields={customFields}
        originTemplate={originTemplate}
        setFullTemplate={setFullTemplate}
        currentTemplateId={fullTemplate.id}
        initialFullTemplate={initialFullTemplate}
      />
    </div>
  );
}

export default withRouter(LabelTemplateInfo);
