import React from 'react';
import { Link } from 'react-router-dom';

const GenericErrorPage = (props) => {
  return (
    <div style={{ textAlign: 'center' }}>
      <h1 style={{ fontSize: 'xxx-large' }}>{props.errorCode}</h1>
      <h2>{props.errorText}</h2>
      <Link to={props.linkTo}>{props.linkText} </Link>
    </div>
  );
};

export default GenericErrorPage;
